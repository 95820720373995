import React from "react";
import FormLabel from "./FormLabel";

//In the following code we have list of diffrent parameters in this component
//the data conatins dataStr contains data if there are static values and passed as string
// if data is an array then passed in the data
// error is for mandatory fiels and will display the boxes red if made mandatory
export default function RadioBox({
  dataStr,
  data,
  dependentOn,
  disabled,
  idField,
  error,
  label,
  multiple,
  name,
  id,
  value,
  valueField,
  onChange,
  onClick,
  onBlur,
}) {
  // following code will assign a value to all the parameters
  // which are null or undefined, to handle any null exceptions.

  // if (!error) {
  //   error = "";
  // }

  if (!value) {
    value = "";
  }

  if (!id) {
    id = name;
  }

  // console.log("Name: " + name);
  // console.log("Erroe: " + error);
  // console.log("Identifier " + id);
  // console.log("Value: " + value);
  if (dataStr != "") {
    const dataArr = dataStr.split(",");
    data = [];
    dataArr.forEach((dt) => {
      let tempArray = dt.split("|");
      let dataObj = {};
      dataObj.idField = tempArray[0];
      dataObj.valueField = tempArray[1];
      data.push(dataObj);
    });
  }

  idField = idField || "idField";
  valueField = valueField || "valueField";

  // Follwing are the GUI elements for SelectBox(or dropdown Menu)
  return (
    <div
      className={"col-span-12 sm:col-span-6" + (!dependentOn ? " hidden" : "")}
    >
      <FormLabel key={label + 1} text={label} color={error ? "red" : "black"} />
      <div className="grid grid-cols-2 gap-3">
        {data &&
          data.map((rData) => (
            <div className="flex items-center">
              <input
                id={name}
                name={name}
                type="radio"
                checked={JSON.parse(rData.idField) == value}
                onChange={onChange}
                value={rData.idField}
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
              />
              <label htmlFor={name} className="ml-3">
                <span className="block text-sm font-medium text-gray-700">
                  {rData.valueField}
                </span>
              </label>

              {/* <FormLabel
                        key={label + 1}
                        name={name}
                        text={label}
                        color={error ? "red" : "black"}
                    /> */}
            </div>
          ))}
      </div>
    </div>
  );
}
