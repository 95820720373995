import React from "react";
import FormLabel from "./FormLabel";

//In the following code we have list of diffrent parameters in this component
//the data conatins data to be displayed in the selectField, idField will
//define the selectField id,label is the lable for the selectField
// error is for mandatory fiels and will display the boxes red if made mandatory
export default function SelectBox({
  data,
  dependentOn,
  disabled,
  idField,
  error,
  label,
  multiple,
  name,
  id,
  value,
  valueField,
  onChange,
  onClick,
  onBlur,
}) {
  // following code will assign a value to all the parameters
  // which are null or undefined, to handle any null exceptions.

  // if (!error) {
  //   error = "";
  // }

  if (!value) {
    value = "";
  }

  if (!id) {
    id = name;
  }

  // console.log("Name: " + name);
  // console.log("Erroe: " + error);
  // console.log("Identifier " + id);
  // console.log("Value: " + value);

  idField = idField || "idField";
  valueField = valueField || "valueField";
  // Follwing are the GUI elements for SelectBox(or dropdown Menu)
  return (
    <div
      className={"col-span-6 sm:col-span-2" + (!dependentOn ? " hidden" : "")}
    >
      <FormLabel
        key={label + 1}
        name={name}
        text={label}
        color={error ? "red" : "black"}
      />
      <div className="mt-1 relative rounded-md shadow-sm">
        <select
          id={id}
          key={id}
          name={name}
          value={value}
          onClick={onClick}
          onChange={onChange}
          disabled={disabled}
          className={
            "form-input block w-full py-2 px-3 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5  " +
            (error
              ? "border-0  text-red-900 rounded-md ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500"
              : "border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300") +
            " transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          }
        >
          <option key={0} value={""}>
            {data ? "Select" : "Loading...."}
          </option>
          {data &&
            data.map((dataItem) => (
              <option key={dataItem[idField]} value={dataItem[idField]}>
                {dataItem[valueField]}
              </option>
            ))}
        </select>
        {error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <svg
              className="h-5 w-5 text-red-500"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {label} must be selected.
        </p>
      )}
    </div>
  );
}
