import React from "react";
import FormLabel from './FormLabel';

export default function CheckBox({ align, dependentOn, hintText, name, label, onChange, value, error }) {
  return (
    <div className={"col-span-6 sm:col-span-2" + (!dependentOn ? " hidden" : "")}>
      <div className="mt-8">
        <div className={"relative flex items-start " + align}>
          <div className="flex items-center h-5">
            <input onChange={onChange} name={name} id={name} checked={value} className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" type="checkbox" />
          </div>
          <div className="ml-3 text-sm leading-5">
            <FormLabel text={label} name={name} color={(error ? "red" : "black")} />
            <p className="text-gray-500">{hintText}</p>
          </div>
        </div>
      </div>
    </div >
  );
}
