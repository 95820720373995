import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import * as constants from "../../constants";
import Axios from "axios";
import FormLabel from "../controls/FormLabel";

// props: endpoint, name, label, error, onSelect, displayFields
const AutoComplete = (props) => {

  const [state, setState] = useState({ inputValue: "", isLoading: false, selectedOption: {} });

  const handleInputChange = (inputValue) => {
    // const inputValue = newValue.replace(/\W/g, '')
    setState((prevState) => ({...prevState, inputValue: inputValue }));
    return inputValue;
  };

  const loadOptions = (inputValue, cb) => {
    const { endPoint, name, onChange, multiple } = props;
    let url = "";
    if (name == "NewProgram") {
      url = constants.BASEURL + endPoint + "&searchText=" + inputValue;
    } else {
      url =
        constants.BASEURL +
        (endPoint || "Suburb/getSuburb") +
        "?searchText=" +
        inputValue;
    }
    if (inputValue.length >= 3) {
      Axios.get(url).then((response) => {
        // suburbs.map(s => s.label = s.name)
        const data = response.data;
        if (name == "AgentId") {
          data.map((d) => (d.value = d.agentID));
        } else if (name == "NewProgram") {
          data.map((d) => (d.value = d.programCampusID));
        } else {
          data.map((d) => (d.value = d.suburbID));
        }
        cb(data);
        //console.log(data);
      });
    } else {
      const selectedOption = {};
      setState((prevState) => ({...prevState, selectedOption: selectedOption}));
      onChange(name, selectedOption);
      cb();
    }
  };

  const checkInput = (inputValue) => {
    if (inputValue.length < 3) setState((prevState) => ({...prevState, isLoading: false }));
    else setState((prevState) => ({...prevState, isLoading: true }));
  }

  const setOption = (selectedOption) => {
    const { onChange, name } = props;
    setState((prevState) => ({...prevState, selectedOption: selectedOption }));
    onChange(name, selectedOption);
    // return `${suburb.label}: ${suburb.postCode}`
    // console.log(suburb.label)
  }

  const setLabel = (option) => {
    if (props.name == "AgentId")
      return `${option.agentCode} - ${option.agentName}`;
    else if (props.name == "NewProgram") {
      return `${option.courseCode} - ${option.courseName}`;
    } else return `${option.name}: ${option.postCode}`;
  }

  
    const { selectedOption } = state;
    const { name, label, error, multiple, dependentOn = true } = props;
    return (
      <div
        className={
          "col-span-6 sm:col-span-2 z-10" + (!dependentOn ? " hidden" : "")
        }
      >
        <FormLabel name={name} text={label} color={error ? "red" : "black"} />
        <div className="mt-1 relative rounded-md shadow-sm">
          {/* <pre>inputValue: "{this.state.inputValue}"</pre> */}
          <AsyncSelect
            menuPortalTarget={document.body}
            maxMenuHeight={100}
            loadOptions={loadOptions}
            onInputChange={handleInputChange}
            getOptionLabel={(option) => setLabel(option)}
            isMulti={multiple}
            // formatOptionLabel
            onChange={(option) => setOption(option)}
            // isLoading={false}
            //value={selectedOption.name}
            // openMenuOnClick={this.setSuburb}
            // getOptionValue={name => `${name}`}
            // openMenuOnClick={this.setSuburb}
          />
          {error && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <svg
                className="h-5 w-5 text-red-500"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          )}
        </div>
        {error && (
          <p className="mt-2 text-sm text-red-600" id="email-error">
            {label} must be entered or selected.
          </p>
        )}
      </div>
      //     <TextBox name="State" label="State" value={selectedSuburb.state} />
      //     <TextBox name="PostCode" label="PostCode" value={selectedSuburb.postCode} />
      //     <TextBox name="Country" label="Country" value={selectedSuburb.country} />
      // </div >
    );
  
}

export default AutoComplete;

// function sleep(delay = 0) {
//     return new Promise((resolve) => {
//         setTimeout(resolve, delay);
//     });
// }

// export default function Asynchronous() {
//     const [open, setOpen] = React.useState(false);
//     const [options, setOptions] = React.useState([]);
//     const loading = open && options.length === 0;
//     // const classes = useStyles();
//     // check react-select.com
//     React.useEffect(() => {
//         let active = true;
//         // console.log(loading)
//         if (!loading) {
//             return undefined;
//         }

//         // (async () => {
//         Axios.get(constants.BASEURL + "Suburb/getSuburb")
//             .then((response) => {
//                 const suburb = response.data;
//                 if (active) {
//                     setOptions(response.data);
//                 }
//             })
//         // })();

//         return () => {
//             active = false;
//         };
//     }, [loading]);

//     React.useEffect(() => {
//         if (!open) {
//             setOptions([]);
//         }
//     }, [open]);

//     return (
//         <div>

//             <Autocomplete
//                 id="multiple-limit-tags"
//                 style={{ width: 300 }}
//                 open={open}
//                 // multiple
//                 limitTags={2}
//                 onOpen={(event) => {
//                     console.log(event.target.value.length)
//                     setOpen(true)
//                 }}
//                 onClose={() => {
//                     setOpen(false);
//                 }}
//                 getOptionSelected={(option, value) => option.name === value.name}
//                 getOptionLabel={(option) => option.name + " - " + option.state + " - " + option.postCode}
//                 options={options}
//                 loading={loading}
//                 renderInput={(params) => (
//                     <TextField {...params} variant="outlined" label="Suburb" placeholder="Suburb" />
//                     // <TextField
//                     //     {...params}
//                     //     label="Asynchronous"
//                     //     variant="outlined"
//                     //     InputProps={{
//                     //         ...params.InputProps,
//                     //         endAdornment: (
//                     //             <React.Fragment>
//                     //                 {loading ? <CircularProgress color="inherit" size={20} /> : null}
//                     //                 {params.InputProps.endAdornment}
//                     //             </React.Fragment>
//                     //         ),
//                     //     }}
//                     // />
//                 )}
//             />
//             <TextBox name="State" label="State" />
//             <TextBox name="PostCode" label="PostCode" />
//             <TextBox name="Country" label="Country" />
//         </div>

//     );
// }
