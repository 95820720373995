import FormSection from "../controls/FormSection";
import Uploader from "../controls/Uploader";
import parse from "html-react-parser";
import PreferencesSection from "../common/PreferencesSection";
import ReferenceSection from "../common/ReferenceSection";
import ContactSection from "../common/ContactSection";
import SignaturePad from "../controls/SignaturePad";
import CourseSection from "../common/CourseSection";
import RadioBox from "../controls/RadioBox";
import CheckBox from "../controls/CheckBox";

const RenderCustomSections = ({
  content,
  formSectionID,
  formSectionTitle,
  formFields,
  expanded,
  state,
  updateCourse,
  setFiles,
  handleChange,
  updatePreference,
  updateReference,
  updateContact,
  clearSignature,
  trim,
  sigPadRef,
}) => {
  const {
    applicationFor,
    programId,
    applicationObjects,
    programIntakes,
    intakes,
    webFormCampus,
    uploadDocuments,
    allowOneProgramOnly,
  } = state.webForm;
  const { webFormFields, DropDowns, webForm } = state;
  const { courses, preferences, references, contacts } = webFormFields;

  if (!webForm || !webForm.webFormDetail) return null;

  const { formSections } = webForm.webFormDetail;
  const section = formSections.filter(
    (fs) => fs.formSectionID === formSectionID
  )[0];

  switch (formSectionID) {
    case 1:
      return (
        <CourseSection
          label={formSectionTitle}
          allowMultiplePrograms={!allowOneProgramOnly}
          onChange={updateCourse}
          course={courses}
          courseType={applicationFor}
          program={programId}
          campuses={webFormCampus}
          campusObjects={applicationObjects}
          intakes={programIntakes}
        />
      );
      break;
    case 2:
      if (uploadDocuments?.length > 0) {
        return (
          <FormSection
            expanded={expanded}
            headerClick={() => {
              expanded = !expanded;
            }}
            label={formSectionTitle}
          >
            {uploadDocuments?.map((upd) => (
              <Uploader
                error={upd.error}
                onUpload={setFiles}
                docTypeId={upd.docTypeID}
                label={upd.docType}
                key={upd.docTypeID}
              />
            ))}
          </FormSection>
        );
      }
      break;
    case 3:
    case 4:
    case 5:
    case 40:
      let fieldName = "DeclarationChecked";
      if (formSectionID === 3) fieldName = "TermsChecked";
      if (formSectionID === 5) fieldName = "AgentTermsChecked";
      if (formSectionID === 40) fieldName = "PersonalConsent";

      return (
        <FormSection
          expanded={expanded}
          headerClick={() => {
            expanded = !expanded;
          }}
          label={formSectionTitle}
        >
          <div
            className={
              "sm:col-span-6 overflow-auto " +
              (section.error ? " border border-red-400" : "")
            }
          >
            {parse(content)}
          </div>
          <div className="border-t border-gray-400 sm:col-span-6">
            <div className="max-w-full mx-auto justify-center text-center">
              {formSectionID === 40 ? (
                <div className="flex flex-col justify-center items-center mx-auto mt-10">
                  <RadioBox
                    dependentOn={true}
                    value={webFormFields[fieldName]}
                    name={fieldName}
                    align="justify-center"
                    onChange={handleChange}
                    dataStr="1|I Agree,2|I do not Agree"
                  />
                </div>
              ) : (
                <CheckBox
                  label="I Agree"
                  dependentOn={true}
                  name={fieldName}
                  value={webFormFields[fieldName]}
                  align="justify-center"
                  onChange={handleChange}
                />
              )}
            </div>
          </div>
        </FormSection>
      );
    case 22:
      return (
        <PreferencesSection
          label={formSectionTitle}
          onChange={updatePreference}
          preference={preferences}
          campuses={webFormCampus}
          programs={applicationObjects}
        />
      );
    case 24:
      return (
        <ReferenceSection
          label={formSectionTitle}
          onChange={updateReference}
          references={references}
        />
      );
    case 29:
      return (
        <ContactSection
          dropDowns={DropDowns}
          label={formSectionTitle}
          onChange={updateContact}
          contactFields={formFields}
          contacts={contacts}
        />
      );
    case 34:
      return (
        <SignaturePad
          error={section.error}
          label={formSectionTitle}
          reference={sigPadRef}
          clearFn={clearSignature}
          onEnd={trim}
        />
      );

    default:
      return null;
  }
};

export default RenderCustomSections;
