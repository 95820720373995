import React, { useEffect, useState } from "react";
import MenuTile from "./common/MenuTile";
import Axios from "axios";
import moment from "moment";
import * as constants from "../constants";
import { DateFormatter } from "./common/DateFormatter";
import DateRangePickerDropdown from "./DateRangePickerDropdown";
import { AgentsRequests } from "../apiRequests/AgentsRequests";
import { currencyFormat } from "./common/commonFns";
import Loader from "./common/Loader";
import useEffectWithCondition from "./hookes/useCustomEffect";

const dateFormatter = new DateFormatter();
const agentsRequests = new AgentsRequests();

function AgentDashboard(props) {

  const [tileIndexPermission, setTileIndexPermission] = useState(null);

  const [state, setState] = useState({
    r1: false, //r = http Request, to check response and same for following.
    r2: false,
    r3: false,
    r4: false,
    r5: false,
    r6: false,
    r7: false,
    r8: false,
    isLoaded: false,
    notPaidCommissionsData: [],
    hidden: "hidden",
    feesPaidEnrolments: [],
    agentId: localStorage.getItem("agentId"),
    invoiceDetailsData: [],
    communicationsCount: "",
    enrolmentsCommission: [],
    DateRnBkgrd: "hidden",
    tilesData: constants.defaultDataTiles,
    t1StartDate: moment().subtract(6, "month").toDate(),
    t1EndDate: new Date(),
    t2StartDate: moment().subtract(6, "month").toDate(),
    t2EndDate: new Date(),
    t3StartDate: moment().subtract(6, "month").toDate(),
    t3EndDate: new Date(),
    t4StartDate: moment().subtract(6, "month").toDate(),
    t4EndDate: new Date(),
    t5StartDate: moment().subtract(6, "month").toDate(),
    t5EndDate: new Date(),
    t6StartDate: moment().subtract(6, "month").toDate(),
    t6EndDate: new Date(),
    t7StartDate: moment().subtract(6, "month").toDate(),
    t7EndDate: new Date(),
    t8StartDate: moment().subtract(6, "month").toDate(),
    t8EndDate: new Date(),
    dateRangeLabel1: localStorage.getItem("dateRangeLabel1"),
    dateRangeLabel2: localStorage.getItem("dateRangeLabel2"),
    dateRangeLabel3: localStorage.getItem("dateRangeLabel3"),
    dateRangeLabel4: localStorage.getItem("dateRangeLabel4"),
    dateRangeLabel5: localStorage.getItem("dateRangeLabel5"),
    dateRangeLabel6: localStorage.getItem("dateRangeLabel6"),
    dateRangeLabel7: localStorage.getItem("dateRangeLabel7"),
    dateRangeLabel8: localStorage.getItem("dateRangeLabel8"),
  });

  useEffect(() => {
    localStorage.setItem("firstRowIndex", 0);
    localStorage.setItem("lastRowIndex", 9);

    setState((prevState) => ({
      ...prevState,
      hidden: "",
      isLoaded: true,
    }));

    const fetchData = async (index) => {
      const startDate = localStorage.getItem(constants.startDateKeys[index]);
      const endDate = localStorage.getItem(constants.endDateKeys[index]);

      if (startDate && endDate) {
        setState((prevState) => ({
          ...prevState,
          [constants.startDateKeys[index]]: startDate,
          [constants.endDateKeys[index]]: endDate,
        }));
      }

      const agentsRequests = new AgentsRequests();
      const res = await agentsRequests.getTilePermission();
      
      let agentPermissions = null;

      if(res != "error"){
        agentPermissions = res;
      }

      if (agentPermissions !== null) {
        
        const agentPermissionsArr = agentPermissions.agentPortalTilePermissions.split("");
        if(agentPermissionsArr !== null && agentPermissionsArr.length > 0){

          let tilePermission = {};

          for (let i = 0; i < 8; i++) {
            tilePermission[i] = agentPermissionsArr[i] !== undefined ? agentPermissionsArr[i] === "1" : null;
          }

          setTileIndexPermission(tilePermission)

        }
      }

      switch (index) {
        case 0:
          await pendingPFn();
          break;
        case 1:
          await overDuesFn();
          break;
        case 2:
          await communicationsCountFn();
          break;
        case 3:
          await documentsCountFn();
          break;
        case 4:
          await getEnrolmentsCommission();
          break;
        case 5:
          await paidEnrolmentsFn();
          break;
        case 6:
          await notPaidCommissionsFn();
          break;
        case 7:
          await paymentRecordFn();
          break;
      }
    };

    const fetchDataPromises = constants.startDateKeys.map((_, index) =>
      fetchData(index)
    );
    Promise.all(fetchDataPromises).then(() => {
      futureCommissionFn();
    });
  }, []);

  const checkAllResponse = () => {
    if (
      state.r1 &&
      state.r2 &&
      state.r3 &&
      state.r4 &&
      state.r5 &&
      state.r6 &&
      state.r7 &&
      state.r8
    ) {
      setState((pre) => ({ ...pre, hidden: "hidden", isLoaded: false }));
    }
  };
  useEffect(() => {
    checkAllResponse();
  }, [
    state.tilesData,
    state.r2,
    state.r3,
    state.r4,
    state.r5,
    state.r6,
    state.r7,
    state.r8,
  ]);
  const dateRangeString = (startDate, endDate, tileName) => {
    const labelStr = state[`dateRangeLabel${tileName.slice(-1)}`] || "";
    const sDate = moment(startDate).format("DD-MMM-YYYY");
    const eDate = moment(endDate).format("DD-MMM-YYYY");

    if (labelStr === "Custom Range") {
      return `${sDate} to ${eDate}`;
    } else {
      return labelStr;
    }
  };

  const handleEvent = (e, p, event) => {
    // console.log(e + " " + p + " " + event);
    if (event) {
      setState((pre) => ({ ...pre, DateRnBkgrd: "" }));
    } else {
      setState((pre) => ({ ...pre, DateRnBkgrd: "hidden" }));
    }
  };
  const tileDateRangeBtn = (opens, drops, startDate, endDate, tileName) => {
    return (
      <div className="flex   p-1">
        <div className=" ">
          <p className="text-xxs text-gray-600 pt-1">
            {dateRangeString(startDate, endDate, tileName)}
          </p>
        </div>

        <div className="ml-2 flex justify-center">
          <DateRangePickerDropdown
            startDate={startDate}
            endDate={endDate}
            opens={opens}
            drops={drops}
            onShow={(event, picker) => {
              handleEvent(event, picker, true);
            }}
            onHide={(event, picker) => {
              handleEvent(event, picker, false);
            }}
            handleCallback={(start, end, label) =>
              handleCallback(start, end, label, tileName)
            }
          />
        </div>
      </div>
    );
  };

  const handleCallback = (start, end, label, tileName) => {
    const propertyName = `t${tileName.slice(-1)}StartDate`;
    const endDatePropertyName = `t${tileName.slice(-1)}EndDate`;
    const dateRangeLabelPropertyName = `dateRangeLabel${tileName.slice(-1)}`;

    localStorage.setItem(`dateRangeLabel${tileName.slice(-1)}`, label);

    setState((prevState) => ({
      ...prevState,
      [propertyName]: start,
      [endDatePropertyName]: end,
      [dateRangeLabelPropertyName]: label,
    }));
  };

  //Tile 1
  // following function will make a https request for pending Proposals Data
  const pendingPFn = async () => {
    let tData = [...state.tilesData];
    tData[0].value = "0";
    tData[0].description = "Loading....";
    setState((prevState) => ({ ...prevState, tilesData: tData }));

    const dateFormatter = new DateFormatter();
    let startDate = dateFormatter.formatterTwo(state.t1StartDate);
    let endDate = dateFormatter.formatterTwo(state.t1EndDate);
    localStorage.setItem("t1StartDate", startDate);
    localStorage.setItem("t1EndDate", endDate);

    try {
      const result = await agentsRequests?.pendingProposalRequest(
        startDate,
        endDate
      );
      let ppData = result;

      if (ppData !== "error") {
        let count = ppData.length;
        let totalComission = 0;
        ppData.map((object, index) => {
          totalComission = totalComission + parseFloat(object.commission);
        });

        tData[0].dateRangeBtn = tileDateRangeBtn(
          "right",
          "down",
          state.t1StartDate,
          state.t1EndDate,
          "tile1"
        );
        tData[0].startDate = startDate;
        tData[0].endDate = endDate;
        tData[0].data = ppData;
        tData[0].label = "Pending Applications";
        tData[0].value = count;
        tData[0].description =
          "Projected commissions: $" + currencyFormat(totalComission);
        setState((prevState) => ({ ...prevState, tilesData: tData, r1: true }));
        tData[0].extraEvent = newApplication();
      }
    } catch (error) {
      // Handle error if needed
      console.log("error", error);
    }
  };

  //Tile 2
  // following function will make a http request for student overdues Data
  const overDuesFn = async () => {
    const startDate = dateFormatter.formatterTwo(state.t2StartDate);
    const endDate = dateFormatter.formatterTwo(state.t2EndDate);
    localStorage.setItem("t2StartDate", startDate);
    localStorage.setItem("t2EndDate", endDate);

    let tData = [...state.tilesData];
    tData[1].value = "0";
    tData[1].description = "Loading....";
    setState((prevState) => ({ ...prevState, tilesData: tData }));

    try {
      const result = await agentsRequests?.overDueInvoices(startDate, endDate);
      let overDuesData = result;
      let totalOverDueAmt = 0;

      overDuesData.map((object) => {
        totalOverDueAmt = totalOverDueAmt + parseFloat(object.balanceAmt);
      });

      tData[1].startDate = startDate;
      tData[1].endDate = endDate;
      tData[1].data = overDuesData;
      tData[1].label = "Overdue Invoices";
      tData[1].value = overDuesData.length;
      tData[1].description =
        "Overdue Amount: $" + currencyFormat(totalOverDueAmt);
      tData[1].dateRangeBtn = tileDateRangeBtn(
        "right",
        "down",
        state.t2StartDate,
        state.t2EndDate,
        "tile2"
      );
      setState((prevState) => ({ ...prevState, tilesData: tData, r2: true }));
    } catch (error) {
      // Handle error if needed
      console.log("error", error);
    }
  };

  //Tile 3
  const communicationsCountFn = async () => {
    let tData = [...state.tilesData];
    tData[2].value = "0";
    tData[2].description = "Loading....";
    setState((prevState) => ({ ...prevState, tilesData: tData }));

    const startDate = dateFormatter.formatterTwo(state.t3StartDate);
    const endDate = dateFormatter.formatterTwo(state.t3EndDate);
    localStorage.setItem("t3StartDate", startDate);
    localStorage.setItem("t3EndDate", endDate);

    try {
      const response = await Axios.get(
        constants.BASEURL +
          "Agents/CommunicationsCount/" +
          state.agentId +
          "/" +
          startDate +
          "/" +
          endDate
      );
      let commCount = response.data;
      let cCount = commCount[0].totalCommunicationsCount;

      tData[2].value = cCount;
      tData[2].description = "Till " + moment(endDate).format("DD-MMM-YYYY");
      tData[2].dateRangeBtn = tileDateRangeBtn(
        "left",
        "down",
        state.t3StartDate,
        state.t3EndDate,
        "tile3"
      );

      setState((prevState) => ({ ...prevState, tilesData: tData, r3: true }));
    } catch (error) {
      console.log(error);
    }
  };

  //Tile 4
  const documentsCountFn = async () => {
    let tData = [...state.tilesData];
    tData[3].value = "0";
    tData[3].description = "Loading....";
    setState((prevState) => ({ ...prevState, tilesData: tData }));

    const startDate = dateFormatter.formatterTwo(state.t4StartDate);
    const endDate = dateFormatter.formatterTwo(state.t4EndDate);
    localStorage.setItem("t4StartDate", startDate);
    localStorage.setItem("t4EndDate", endDate);

    try {
      const response = await Axios.get(
        constants.BASEURL +
          "Agents/DocumentsCount/" +
          state.agentId +
          "/" +
          startDate +
          "/" +
          endDate
      );
      let docCount = response.data;
      let dCount = docCount[0].totalDocumentsCount;

      tData[3].dateRangeBtn = tileDateRangeBtn(
        "left",
        "down",
        state.t4StartDate,
        state.t4EndDate,
        "tile4"
      );
      tData[3].value = dCount;
      tData[3].description = "Till " + moment(endDate).format("DD-MMM-YYYY");

      setState((prevState) => ({ ...prevState, tilesData: tData, r4: true }));
    } catch (error) {
      console.log(error);
    }
  };

  //Tile 5
  const allActiveEnrolmentsFn = async (startDate, endDate) => {
    let tData = [...state.tilesData];
    tData[4].value = "0";
    tData[4].description = "Loading....";
    setState((prevState) => ({ ...prevState, tilesData: tData }));

    try {
      const response = await agentsRequests?.allActiveEnrolments(
        startDate,
        endDate
      );
      let allEnrolments = response;

      if (allEnrolments !== "error") {
        let enrolmentsCommission = state.enrolmentsCommission;
        let structure = {
          enrolmentID: "",
          commission: "",
          invoiceAvailable: "",
        };
        let filteredData = [];

        if (enrolmentsCommission) {
          allEnrolments.map((object) => {
            let a = enrolmentsCommission.find(
              (element) => element.enrolmentID == object.enrolmentID
            );

            if (a) {
              structure.enrolmentID = a.enrolmentID;
              structure.commission = a.totalCommission;
              filteredData.push({ ...structure });
            } else {
              structure.enrolmentID = object.enrolmentID;
              structure.commission = 0;
              filteredData.push({ ...structure });
            }
          });
        }

        let eCount = allEnrolments.length;
        let totalCommission = 0;
        tData[4].dateRangeBtn = tileDateRangeBtn(
          "right",
          "up",
          state.t5StartDate,
          state.t5EndDate,
          "tile5"
        );

        filteredData.map((object) => {
          totalCommission = totalCommission + parseFloat(object.commission);
        });

        tData[4].value = eCount;
        tData[4].description =
          "Total Commission: $" + currencyFormat(totalCommission);

        setState((prevState) => ({ ...prevState, tilesData: tData, r5: true }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Tile 6
  const commissionDueFn = async (startDate, endDate) => {
    let tData = [...state.tilesData];
    tData[5].value = "0";
    tData[5].description = "Loading....";
    setState((prevState) => ({ ...prevState, tilesData: tData }));

    try {
      const response = await agentsRequests?.agentCommissionDue(
        startDate,
        endDate
      );
      let notPaidCommissionsData = response;
      let feesPaidEnrolments = state.feesPaidEnrolments;
      let totalCommission = 0;
      let structure = {
        enrolmentID: "",
        commission: "",
      };
      let filteredData = [];

      if (notPaidCommissionsData !== "error") {
        notPaidCommissionsData.map((object) => {
          // let a = feesPaidEnrolments.find(
          //   (element) => element.enrolmentID == object.enrolmentID
          // );

          // if (a) {
          totalCommission = totalCommission + object.totalCommission;
          structure.enrolmentID = object.enrolmentID;
          structure.commission = object.totalCommission;
          filteredData.push({ ...structure });
          // }
        });
      }

      let tData = [...state.tilesData];
      tData[5].dateRangeBtn = tileDateRangeBtn(
        "right",
        "up",
        state.t6StartDate,
        state.t6EndDate,
        "tile6"
      );

      let value = parseFloat(totalCommission).toFixed(2);
      tData[5].value = filteredData.length;
      tData[5].description =
        "Amount: $" + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      setState((prevState) => ({ ...prevState, tilesData: tData, r6: true }));
    } catch (error) {
      console.log(error);
    }
  };

  //Tile 7
  const futureCommissionFn = () => {
    let notPaidCommissionsData = state.notPaidCommissionsData;
    let tData = [...state.tilesData];
    let totalCommission = 0;
    tData[6].dateRangeBtn = tileDateRangeBtn(
      "left",
      "up",
      state.t7StartDate,
      state.t7EndDate,
      "tile7"
    );

    notPaidCommissionsData.map((object) => {
      totalCommission = totalCommission + object.totalCommission;
    });

    tData[6].value = notPaidCommissionsData.length;
    let value = parseFloat(totalCommission).toFixed(2);
    tData[6].description =
      "Amount: $" + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    setState((prevState) => ({ ...prevState, tilesData: tData, r7: true }));
  };

  //Tile 8
  const paymentRecordFn = () => {
    let tData = [...state.tilesData];
    tData[7].value = "0";
    tData[7].description = "Loading....";
    setState((prevState) => ({ ...prevState, tilesData: tData }));

    let startDate = dateFormatter.formatterTwo(state.t8StartDate);
    let endDate = dateFormatter.formatterTwo(state.t8EndDate);
    localStorage.setItem("t8StartDate", startDate);
    localStorage.setItem("t8EndDate", endDate);

    agentsRequests?.paymentRecord(startDate, endDate).then((result) => {
      let allPayments = result;
      if (result != "error") {
        let totalPayment = 0;
        let tData = [...state.tilesData];
        allPayments.map((object) => {
          totalPayment = totalPayment + parseFloat(object.paidAmount);
        });

        tData[7].value = allPayments.length;
        tData[7].description = "Amount Paid: $" + currencyFormat(totalPayment);
        tData[7].dateRangeBtn = tileDateRangeBtn(
          "left",
          "up",
          state.t8StartDate,
          state.t8EndDate,
          "tile8"
        );

        setState((prevState) => ({ ...prevState, tilesData: tData, r8: true }));
      }
    });
  };

  const paidEnrolmentsFn = async () => {
    const dateFormatter = new DateFormatter();
    let startDate = dateFormatter.formatterTwo(state.t6StartDate);
    let endDate = dateFormatter.formatterTwo(state.t6EndDate);
    localStorage.setItem("t6StartDate", startDate);
    localStorage.setItem("t6EndDate", endDate);

    try {
      const response = await agentsRequests.feesPaidEnrolments(
        startDate,
        endDate
      );
      let ennrolments = response;

      setState((prevState) => ({
        ...prevState,
        feesPaidEnrolments: ennrolments,
      }));
      commissionDueFn(startDate, endDate);
    } catch (error) {
      console.log(error);
    }
  };

  const getEnrolmentsCommission = async () => {
    let startDate = dateFormatter.formatterTwo(state.t5StartDate);
    let endDate = dateFormatter.formatterTwo(state.t5EndDate);
    localStorage.setItem("t5StartDate", startDate);
    localStorage.setItem("t5EndDate", endDate);

    try {
      const result = await agentsRequests.enrolmentCommission(
        startDate,
        endDate
      );
      let enrolmentsCommissionData = result;

      if (enrolmentsCommissionData !== "error") {
        setState((prevState) => ({
          ...prevState,
          enrolmentsCommission: enrolmentsCommissionData,
        }));

        allActiveEnrolmentsFn(startDate, endDate);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const newApplication = () => {
    return "/webForm/" + localStorage.getItem("defaultWebForm");
  };

  const notPaidCommissionsFn = async () => {
    let startDate = dateFormatter.formatterTwo(state.t7StartDate);
    let endDate = dateFormatter.formatterTwo(state.t7EndDate);
    localStorage.setItem("t7StartDate", startDate);
    localStorage.setItem("t7EndDate", endDate);

    try {
      const response = await agentsRequests.agentCommissionDue(
        startDate,
        endDate
      );
      let notPaidCommissionsData = response;

      setState((prevState) => ({
        ...prevState,
        notPaidCommissionsData: notPaidCommissionsData,
      }));

      futureCommissionFn();
    } catch (error) {
      console.log(error);
    }
  };
  useEffectWithCondition(futureCommissionFn, [state.notPaidCommissionsData]);
  useEffectWithCondition(pendingPFn, [
    state.t1StartDate,
    state.t1EndDate,
    state.dateRangeLabel1,
  ]);
  useEffectWithCondition(overDuesFn, [
    state.t2StartDate,
    state.t2EndDate,
    state.dateRangeLabel2,
  ]);
  useEffectWithCondition(communicationsCountFn, [
    state.t3StartDate,
    state.t3EndDate,
    state.dateRangeLabel3,
  ]);
  useEffectWithCondition(documentsCountFn, [
    state.t4StartDate,
    state.t4EndDate,
    state.dateRangeLabel4,
  ]);
  useEffectWithCondition(getEnrolmentsCommission, [
    state.t5StartDate,
    state.t5EndDate,
    state.dateRangeLabel5,
  ]);
  useEffectWithCondition(paidEnrolmentsFn, [
    state.t6StartDate,
    state.t6EndDate,
    state.dateRangeLabel6,
  ]);
  useEffectWithCondition(notPaidCommissionsFn, [
    state.t7StartDate,
    state.t7EndDate,
    state.dateRangeLabel7,
  ]);
  useEffectWithCondition(paymentRecordFn, [
    state.t8StartDate,
    state.t8EndDate,
    state.dateRangeLabel8,
  ]);
  return (
    <div className="lg:mt-10">
      <Loader id="l1" loaded={state.isLoaded} hidden={state.hidden} />

      <div className="my-4 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
        {/* if you want to disable any tile number then you must remove startDateKeys and endDateKeys
         from constant file. both date key remove is  compulsary for error free rendering*/}
        {constants.startDateKeys.map((_, index) => (
            tileIndexPermission !== null && tileIndexPermission?.[index] !== null && (
              tileIndexPermission[index] ? <RenderTile tile={index} state={state} /> : null
            )   
        ))}
      </div>

      <span
        onClick={() => {
          handleEvent(false);
        }}
        className={
          state.DateRnBkgrd +
          " z-30 fixed top-0 left-0 right-0 bottom-0 h-full w-full bg-black opacityBackground"
        }
      />
    </div>
  );
}

export default AgentDashboard;

const RenderTile = ({ tile, state }) => {
  let tData = state.tilesData;
  return (
    <MenuTile
      label={tData[tile].label}
      value={tData[tile].value}
      description={tData[tile].description}
      viewBox={tData[tile].viewBox}
      svg={tData[tile].svg}
      link={tData[tile].link}
      dateRangeBtn={tData[tile].dateRangeBtn}
      extraEvent={tData[tile].extraEvent}
    />
  );
};
