import React, { useState, useEffect } from 'react';
import FormSection from '../controls/FormSection';
import TextBox from '../controls/TextBox';

const blankReference = {
    error: {}
};

const ReferenceSection = ({ label, references, onChange }) => {
    const [hideActionButton] = useState(false);
    const [updatedCurrentReference, setupdatedCurrentReference] = useState({});
    const [referenceList, setReferenceList] = useState([]);

    useEffect(() => {
        if (references.length === 0) {
            addReference();
        }
    }, []);

    const addReference = () => {
        const cReference = { ...blankReference };
        cReference.error = {};
        const updatedReferences = [...referenceList, cReference];
        setReferenceList(updatedReferences);
        onChange(updatedReferences);
    };

    const removeReference = (index) => {
        const updatedReferences = [...referenceList];
        updatedReferences.splice(index, 1);
        setReferenceList(updatedReferences);
        onChange(updatedReferences);
    };

    const renderActionButtons = (index) => {
        return (
            <div className="ml-4 mt-2 flex-shrink-0">
                <span className="inline-flex rounded-md shadow-sm mr-2">
                    {index === 0 ? (
                        <button
                            type="button"
                            onClick={() => addReference()}
                            className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700"
                        >
                            +
                        </button>
                    ) : (
                        <button
                            type="button"
                            onClick={() => removeReference(index)}
                            className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700"
                        >
                            -
                        </button>
                    )}
                </span>
            </div>
        );
    };


    const handleChange = (e, name) => {
        let updatedupdatedCurrentReference = updatedCurrentReference;

        let controlName = ""
        if (name) {
            updatedupdatedCurrentReference[name] = e
            controlName = name;
        }
        else {
            updatedupdatedCurrentReference[e.currentTarget.name] = e.currentTarget.value
            controlName = e.currentTarget.name;
        }


        let cReference = { ...updatedCurrentReference }
        const keys = Object.keys(cReference)
        keys.map((key) => {
            // if (!updatedupdatedCurrentReference) {
            //    const {updatedCurrentReference} = 
            // }
            if (key !== "error") {
                if ((updatedCurrentReference[key] == null || updatedCurrentReference[key] == "") && updatedCurrentReference.error[key] == undefined) return
                if ((updatedCurrentReference[key] == null || updatedCurrentReference[key] == "") && updatedCurrentReference.error[key]) {
                    updatedCurrentReference.error[key] = true
                    return
                }

            }
        },)
        setReferenceList(updatedupdatedCurrentReference);
        onChange(referenceList);
    }


    const renderReference = (reference, index) => {
        const { error } = reference;
        return (
            <>
                <div className={"col-span-6 sm:col-span-6 " + (hideActionButton ? "hidden" : "")}>
                    <div className="flex justify-between items-center flex-wrap sm:flex-no-wrap">
                        <>
                            <h3 className="text-base leading-6 font-medium text-gray-900">Reference #{index + 1}</h3>
                            {renderActionButtons(index)}
                        </>
                    </div>
                </div>
                <TextBox
                    name="InstitutionName"
                    dependentOn={true}
                    error={error?.InstituitionName}
                    label="Institution Name"
                    onClick={() => setupdatedCurrentReference(reference)}
                    value={reference["InstitutionName"]}
                    onChange={(e) => handleChange(e, "InstitutionName")}
                />
                <TextBox
                    name="ContactName"
                    dependentOn={true}
                    error={error?.ContactName}
                    label="Contact Name"
                    onClick={() => setupdatedCurrentReference(reference)}
                    value={reference["ContactName"]}
                    onChange={(e) => handleChange(e, "ContactName")}
                />
                <TextBox
                    name="Position"
                    dependentOn={true}
                    error={error?.Position}
                    label="Position"
                    onClick={() => setupdatedCurrentReference(reference)}
                    value={reference["Position"]}
                    onChange={(e) => handleChange(e, "Position")}
                />
                <TextBox
                    name="ContactNumber"
                    dependentOn={true}
                    error={error?.ContactNumber}
                    label="Contact Number"
                    onClick={() => setupdatedCurrentReference(reference)}
                    value={reference["ContactNumber"]}
                    onChange={(e) => handleChange(e, "ContactNumber")}
                />
                <TextBox
                    name="Email"
                    dependentOn={true}
                    error={error?.Email}
                    label="Email"
                    onClick={() => setupdatedCurrentReference(reference)}
                    value={reference["Email"]}
                    onChange={(e) => handleChange(e, "Email")}
                />
            </>
        );
    };

    return (
        <FormSection label={label}>
            {references.length === 0 ? renderReference({}, 0) : references.map((reference, index) => renderReference(reference, index))}
        </FormSection>
    );
};

export default ReferenceSection;
