import { HashLoader } from "react-spinners";

function Loader({ loaded, id, hidden = "" }) {
  if (loaded) {
    return (
      <div
        id={id}
        className={
          hidden +
          "fixed top-10 left-0 right-0 bottom-0  w-full bg-white opacity-50"
        }
      >
        <HashLoader
          cssOverride={{
            height: "100%",
            display: "absolute",
            top: "50%",
            left: "50%",
          }}
          size={150}
          color={"#123abc"}
          loading={loaded}
          speedMultiplier={1.5}
        />
      </div>
    );
  }
}
export default Loader;
