import FormSection from "../controls/FormSection";
import RenderCustomSections from "./RenderCustomSections";
import RenderField from "./RenderField";

const RenderWebForm = ({ state, ...restProps }) => {
  const { webForm } = state;

  if (!webForm || !webForm.webFormDetail) return null;

  const { formSections } = webForm.webFormDetail;

  return formSections?.map(
    (
      { content, formSectionID, formSectionTitle, formFields, expanded },
      index
    ) => {
      if (
        formSectionID < 6 ||
        formSectionID === 22 ||
        formSectionID === 24 ||
        formSectionID === 29 ||
        formSectionID === 34 ||
        formSectionID === 40
      ) {
        return (
          <RenderCustomSections
            key={`render-custom-sections-${formSectionID}-${index}`}
            content={content}
            formSectionID={formSectionID}
            formSectionTitle={formSectionTitle}
            formFields={formFields}
            expanded={expanded}
            state={state}
            {...restProps}
          />
        );
      } else {
        return (
          <FormSection
            key={`form-section-render-field-${formSectionID}-${index}`}
            expanded={expanded}
            // headerClick={() => {
            //   const updatedExpanded = !expanded;
            //   // Perform any other necessary actions related to headerClick here
            // }}
            label={formSectionTitle}
          >
            {formFields.map((formField, indx) => (
              <RenderField
                key={`render-field-${formSectionID}-${indx}`}
                formField={formField}
                state={state}
                {...restProps}
              />
            ))}
          </FormSection>
        );
      }
    }
  );
};

export default RenderWebForm;
