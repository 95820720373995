// export const BASEURL = "https://api-test.rtosoftware.com.au/api/";
//export const BASEURL = "https://lmsapi.rtosoftware.com.au/api/";
// export const BASEURL = "https://localhost:5003/api/";
//export const BASEURL = "https://testapi.rtosoftware.com.au/api/";
//export const BASEURL = "http://192.168.18.22/api/";
//export const COLLEGEID = 20260;
export const BASEURL = window.configs.collegeName == "localhost" ? "https://localhost:5003/api/" : "https://api-" + window.configs.collegeName + ".rtoteams.com.au/api/";
export const COLLEGEID = 301;

// Program Types
export const CURRENTPROGRAM = 1;
export const PASTPROGRAM = 2;

// Table Constants
export const NUMBEROFROWS = 50;

export const defaultDataTiles = [
  {
    label: "Pending Applications",
    value: "",
    description: "Loading...",
    svg: (
      <svg
        className="w-6 h-6 fill-current text-gray-500"
        fill="gray"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
          clipRule="evenodd"
        ></path>
      </svg>
    ),
    link: "/dashboard/PendingApplications",
    data: "",
  },
  {
    label: "Overdue Fees",
    value: "",
    description: "Loading...",
    svg: (
      <svg
        className="w-6 h-6 fill-current text-gray-500"
        fill="gray"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
          clipRule="evenodd"
        ></path>
      </svg>
    ),
    link: "/dashboard/overDues",
  },
  {
    label: "Communications",
    value: "",
    description: "Loading...",
    svg: (
      <svg
        className="w-6 h-6 fill-current text-gray-500"
        fill="gray"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z"
          clipRule="evenodd"
        ></path>
      </svg>
    ),
    link: "/dashboard/communications",
  },
  {
    label: "Documents",
    value: "",
    description: "Loading...",
    svg: (
      <svg
        className="w-6 h-6 fill-current text-gray-500"
        fill="gray"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M9 2a2 2 0 00-2 2v8a2 2 0 002 2h6a2 2 0 002-2V6.414A2 2 0 0016.414 5L14 2.586A2 2 0 0012.586 2H9z"></path>
        <path d="M3 8a2 2 0 012-2v10h8a2 2 0 01-2 2H5a2 2 0 01-2-2V8z"></path>
      </svg>
    ),
    link: "/dashboard/documents",
    viewBox: "0 0 24 24",
  },
  {
    label: "Active Enrolments",
    value: "",
    description: "Loading...",
    svg: (
      <svg
        className="w-6 h-6 fill-current text-gray-500"
        fill="gray"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10.394 2.08a1 1 0 00-.788 0l-7 3a1 1 0 000 1.84L5.25 8.051a.999.999 0 01.356-.257l4-1.714a1 1 0 11.788 1.838L7.667 9.088l1.94.831a1 1 0 00.787 0l7-3a1 1 0 000-1.838l-7-3zM3.31 9.397L5 10.12v4.102a8.969 8.969 0 00-1.05-.174 1 1 0 01-.89-.89 11.115 11.115 0 01.25-3.762zM9.3 16.573A9.026 9.026 0 007 14.935v-3.957l1.818.78a3 3 0 002.364 0l5.508-2.361a11.026 11.026 0 01.25 3.762 1 1 0 01-.89.89 8.968 8.968 0 00-5.35 2.524 1 1 0 01-1.4 0zM6 18a1 1 0 001-1v-2.065a8.935 8.935 0 00-2-.712V17a1 1 0 001 1z"></path>
      </svg>
    ),
    link: "/dashboard/ActiveEnrolments",
    viewBox: "0 0 24 24",
  },
  {
    label: "Commissions Due",
    value: "",
    description: "Loading...",
    svg: (
      <svg
        className="w-6 h-6 fill-current text-gray-500"
        fill="gray"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z"></path>
        <path
          fillRule="evenodd"
          d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
          clipRule="evenodd"
        ></path>
      </svg>
    ),
    link: "/dashboard/commissionDue",
    viewBox: "0 0 24 24",
  },
  {
    label: "Future Commissions",
    value: "",
    description: "Loading...",
    svg: (
      <svg
        className="w-6 h-6 fill-current text-gray-500"
        fill="gray"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z"></path>
        <path
          fillRule="evenodd"
          d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
          clipRule="evenodd"
        ></path>
      </svg>
    ),
    link: "/dashboard/FutureCommission",
    viewBox: "0 0 24 24",
  },
  {
    label: "Paid Commissions",
    value: "",
    description: "Loading...",
    svg: (
      <svg
        className="w-6 h-6 fill-current text-gray-500"
        fill="gray"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z"></path>
        <path
          fillRule="evenodd"
          d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
          clipRule="evenodd"
        ></path>
      </svg>
    ),
    link: "/dashboard/paidCommissionsView",
    viewBox: "0 0 24 24",
  },
];

export const startDateKeys = [
  "t1StartDate",
  "t2StartDate",
  "t3StartDate",
  "t4StartDate",
  "t5StartDate",
  "t6StartDate",
  "t7StartDate",
  "t8StartDate",
];
export const endDateKeys = [
  "t1EndDate",
  "t2EndDate",
  "t3EndDate",
  "t4EndDate",
  "t5EndDate",
  "t6EndDate",
  "t7EndDate",
  "t8EndDate",
];
