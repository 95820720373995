import React, { useState, Fragment } from 'react';
import Dropzone from 'react-dropzone';
import FormLabel from './FormLabel';

const Uploader = ({ error, label, name, value, onChange, onClick, onBlur, readOnly, onUpload, docTypeId }) => {
    const [files, setFiles] = useState([]);

    const onDrop = (uploadedFiles) => {
        setFiles(uploadedFiles);

        if (onUpload) {
            onUpload(uploadedFiles, docTypeId);
        }
    };

    const onDelete = (e, file) => {
        e.stopPropagation();
        const updatedFiles = files.filter((f) => f !== file);
        setFiles(updatedFiles);
        if (onUpload) {
            onUpload(updatedFiles, docTypeId);
        }
    };

    const filesList = files.map((file) => (
        <li key={file.name}>
            {file.name} - {file.size} bytes
            <span onClick={(event) => onDelete(event, file)}>
                <svg className="h-5 w-5 text-red-500 inline-block cursor-pointer" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                </svg>
            </span>
        </li>
    ));

    return (
        <div className="sm:col-span-6">
            <FormLabel text={label} name={name} color={error ? "red" : "black"} />

            <Dropzone onDrop={onDrop}>
                {({ getRootProps, getInputProps }) => {
                    return (

                        < div className={"mt-2 flex justify-center px-6 pt-5 pb-6 border-2 " + (error ? "border-red-400" : "border-gray-300") + " border-dashed rounded-md"} {...getRootProps()}>
                            <div className="text-center" >
                                <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48">
                                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <p className="mt-1 text-sm text-gray-600">
                                    <input {...getInputProps()} />
                                    <button type="button" className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition duration-150 ease-in-out">
                                        Upload a file
                                    </button>
                                    <span className='pl-1'>or drag and drop</span>
                                </p>
                                <Fragment>
                                    <ul>{filesList}</ul>
                                </Fragment>

                            </div>
                        </div>
                    )

                }}
            </Dropzone>


        </div >




    );
}

export default Uploader;