import React from 'react'
import { Link } from 'react-router-dom'

const MenuTile = (props) => {
  return (
    <div className="mx-2 bg-gray-100 flex flex-col overflow-hidden shadow rounded-lg">        
        <div className="px-5 py-5 flex-0 content-middle">
          <div className="flex break-words items-center">
            <div className="flex-shrink-0">
              {/* Heroicon name: scale */}
              {props.svg}
            </div>
            <div className="ml-5 mr-2 w-0 flex-auto">
              <dl>
                <dt className="text-sm font-medium text-gray-500">
                  {props.label}
                </dt>
                <dd>
                  <div className="xl:text-2xl text-2xl lg:text-lg font-medium text-gray-800">
                    {props.value}
                  </div>
                </dd>
                <dd>
                  <div className="text-xs font-medium text-gray-500">
                    {props.description}
                  </div>
                </dd>
              </dl>
            </div>
            <div className= {"content-end cursor-pointer " + (!props.extraEvent ? " invisible " : " ")} title="New Application">
              <Link to={props.extraEvent} target="_blank" >
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/></svg>
              </Link>
            </div>                                              
          </div>

        </div>
        <div
          style={{ display: "flex", justifyContent: "space-between" }}
          className="bg-gray-200 px-2 py-2 grid grid-cols-4"
        >
          <div className="col-span-3">{props.dateRangeBtn}</div>
          <div className="text-sm col-span-1 h-6">
            <div className={props.value == 0 ? "hidden" : ""}>
              <Link
                to={{
                  pathname: props.link,
                  // state: this.props.data,
                }}
                className=""
              >
                <svg
                  className="w-8 h-6 fill-current text-gray-600 hover:text-blue-700"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>
  )
}

export default MenuTile