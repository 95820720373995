import React, { useEffect, useRef, useState } from "react";
import Axios from "axios";
import moment from "moment";
import * as constants from "../../constants";
import AnswerQuiz from "../AnswerQuiz";
import { useNavigate, useParams } from "react-router-dom";
import RenderWebForm from "./RenderWebForm";
import Modal from "../common/Modal";

function WebFormComponent(props) {
  const [state, setState] = useState({
    modalState: false,
    modalMessage: "",
    buttonList: [
      {
        seq: 2,
        buttonClass:
          "inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5",
        clickFunction: () => redirectToThankyou(),
        buttonText: "Ok",
      },
    ],
    webFormCompleted: false,
    completedQuizes: [],
    DropDowns: {},
    webForm: null,
    webFormFields: {
      FirstName: "",
      OSHCArranged: false,
      courses: [],
      documents: [],
      preferences: [],
      references: [],
      contacts: [],
      formSignature: null,
      AlreadyHaveUSI: 2,
      QualificationNotCompleted: 2,
      ApplyForRPL: false,
      ApplyForCreditTransfer: false,
    },
  });
  const navigate = useNavigate();
  const { id } = useParams();
  const { quizId } = useParams();
  const sigPadRef = useRef(null);

  const clearSignature = () => {
    if (sigPadRef.current) {
      const { webFormFields } = state;
      webFormFields.formSignature = null;
      setState((pre) => ({ ...pre, webFormFields: webFormFields }));
      sigPadRef.current.clear();
    }
  };

  const trim = () => {
    if (sigPadRef.current && sigPadRef.current.getTrimmedCanvas) {
      const { webFormFields } = state;
      webFormFields.formSignature = sigPadRef.current
        .getTrimmedCanvas()
        .toDataURL("image/png");

      setState((pre) => ({ ...pre, webFormFields: webFormFields }));
    }
  };

  const join = (indexName, ...arrays) => {
    const map = new Map();
    arrays.forEach((array) => {
      array.forEach((item) => {
        map.set(item[indexName], Object.assign(item, map.get(item[indexName])));
      });
    });
    return [...map.values()];
  };
  useEffect(() => {
    fetchData();
    async function fetchData() {
      try {
        const response = await Axios.get(constants.BASEURL + "WebForms/" + id, {
          CollegeID: constants.COLLEGEID,
        });
        if (response) {
          let webForm = response.data;
          let { webFormFields } = state;
          let addressCounter = 0,
            secondAddressId = 0;
          webFormFields.TLWebFormID = webForm.tlWebFormID;
          webFormFields.documents = webForm.uploadDocuments;
          webFormFields.guid = id;
          webFormFields.applicationType = webForm.formType;
          const { programIntakes } = webForm;
          let { webFormCampus: webFormCampusTemp, intakes: intakesTemp } =
            webForm;

          // New code to pick webformconfigs (initiated for vce asked by sandeep sir)
          if (
            window.webformconfigs &&
            window.webformconfigs.defaultWebFormCampus > 0
          ) {
            // webFormCampus = webFormCampus.filter(c => c.camusId === window.webformconfigs.defaultWebFormCampus)
            webFormCampusTemp = webFormCampusTemp.filter(
              (c) => c.campusId === window.webformconfigs.defaultWebFormCampus
            );
          } else {
            webFormCampusTemp = webFormCampusTemp;
          }
          if (
            window.webformconfigs &&
            window.webformconfigs.defaultRollingIntake > 0
          ) {
            // webFormCampus = webFormCampus.filter(c => c.camusId === window.webformconfigs.defaultWebFormCampus)
            intakesTemp = intakesTemp.filter(
              (c) => c.intakeId === window.webformconfigs.defaultRollingIntake
            );
          } else {
            intakesTemp = intakesTemp;
          }

          webForm.webFormCampus = webFormCampusTemp;
          webForm.intakes = intakesTemp;
          const webFormCampus = webFormCampusTemp;
          const intakes = intakesTemp;

          webFormCampus.map((c) => {
            c.campusDetail = c.campusCode + " - " + c.campusName;
          });

          if (webForm.formType < 3)
            webForm.programIntakes = programIntakes.map((pi) => {
              return {
                ...pi,
                ...intakes.filter((intk) => intk.intakeId === pi.intakeId)[0],
              };
            });

          const addressSections = webForm.webFormDetail.formSections.filter(
            (n, i) => {
              if (n.formSectionID === 8 || n.formSectionID === 9) {
                addressCounter++;
                if (addressCounter === 2 && n.formSectionID === 8) {
                  secondAddressId = n.formSectionID;
                  const copyAddressObject = [
                    {
                      formFieldID: -1,
                      fieldName:
                        n.formSectionID === 8
                          ? "CopyResidentialAddress"
                          : "CopyMailingAddress",
                      fieldLabel:
                        n.formSectionID === 8
                          ? "Same as residential address"
                          : "Copy Mailing Address",
                      isMandatory: false,
                      isFlexible: false,
                      fieldType: 8,
                      codeType: 0,
                      avetCodeType: 0,
                      fCodeType: 0,
                      extraLabel: null,
                      staticData: null,
                    },
                  ];
                  n.formFields = copyAddressObject.concat(n.formFields);
                }

                return n;
              }
            }
          );
          webForm["totalAddresses"] = addressCounter;
          webForm["targetAddressSectionId"] = secondAddressId;

          setState((pre) => ({ ...pre, webForm, webFormFields }));
          fetchDropDowns(webForm);
        }
      } catch {
        console.log("error");
      }
    }
  }, [id]);

  const redirectToThankyou = () => {
    const { webForm } = state;
    if (webForm?.thankYouURL) {
      navigate(webForm?.thankYouURL);
    }
  };

  const fetchDropDowns = (webForm) => {
    const { DropDowns } = state;

    const { formSections } = webForm?.webFormDetail;
    let codeType = 0;
    let apiUrl = "";

    formSections?.forEach(({ formFields }) => {
      // if (!controlFound) {

      formFields
        .filter(({ fieldType }) => fieldType === 12)
        ?.map((formField) => {
          if (formField.fieldName === "CitizenshipStatus") {
            if (
              window.webformconfigs &&
              window.webformconfigs.citizenshipStatusSelectionCount !==
                undefined
            ) {
              if (window.webformconfigs.citizenshipStatusSelectionCount > 0) {
                formField.minSelectionCount =
                  window.webformconfigs.citizenshipStatusSelectionCount;
                formField.maxSelectionCount =
                  window.webformconfigs.citizenshipStatusSelectionCount;
              }
            } else {
              formField.minSelectionCount = 2;
              formField.maxSelectionCount = 2;
            }
          }
        });
      formFields
        .filter(
          ({ fieldType }) =>
            fieldType === 2 ||
            fieldType === 6 ||
            fieldType === 7 ||
            fieldType === 10
        )
        ?.map(async (formfield) => {
          codeType = formfield.codeType;
          apiUrl = "UserCode/getUserCode?codetype=";
          if (formfield.avetCodeType > 0) {
            apiUrl = "AvetCode/getAvetCode?codeType=";
            codeType = formfield.avetCodeType;
          } else if (formfield.fCodeType > 0) {
            apiUrl = "FeeHelpCode/getFeeHelpCode?codeType=";
            codeType = formfield.fCodeType;
          }
          if (codeType > 0) {
            // console.log("Fetching " + formfield.fieldLabel);
            await Axios.get(constants.BASEURL + apiUrl + codeType, {
              CollegeID: constants.COLLEGEID,
            })
              .then((response) => {
                let type =
                  formfield.codeType > 0
                    ? 1
                    : formfield.avetCodeType > 0
                    ? 2
                    : formfield.fCodeType > 0
                    ? 3
                    : 0;
                parseCodes(type, response.data);
                if (
                  parseInt(type) === 2 &&
                  parseInt(formfield.avetCodeType) === 2
                )
                  DropDowns[formfield.fieldName] = response.data.filter(
                    (item) => item.avetCode !== "@"
                  );
                else DropDowns[formfield.fieldName] = response.data;

                setState((pre) => ({ ...pre, DropDowns: DropDowns }));
              })
              .catch((error) =>
                console.log(
                  "Error while fetching " + formfield.fieldName,
                  error
                )
              );
          }
        });
      // }
    });
  };

  const parseCodes = (type, data) => {
    switch (type) {
      case 1:
        if (data[0].value > 0) {
          data.map((d) => {
            d.idField = d.value;
            d.valueField = d.codeName;
          });
        } else {
          data.map((d) => {
            d.value = d.userCodeId;
            d.idField = d.userCodeId;
            d.valueField = d.codeName;
          });
        }
        break;
      case 2:
        if (data[0].avetCodeType === 2)
          data = data.filter((item) => item.avetCode !== "@");

        data.map((d) => {
          d.value = d.avetCodeID;
          d.idField = d.avetCodeID;
          d.valueField = d.avetDescription;
          // d.valueField = d.avetCode + '- ' + d.avetDescription
        });
        break;
      case 3:
        data.map((d) => {
          d.value = d.feeHelpCodeId;
          d.idField = d.feeHelpCodeId;
          d.valueField = d.feeHelpCode + " - " + d.feeHelpCodeName;
        });
        break;
    }
  };

  const resetField = (controlName) => {
    const { webForm, webFormFields } = state;
    const { formSections } = webForm?.webFormDetail;
    let controlFound = 0;
    formSections.map(({ formFields }) => {
      if (!controlFound) {
        formFields.map((formfield) => {
          if (
            formfield.fieldName === controlName &&
            webFormFields[controlName] &&
            webFormFields[controlName].trim() !== "" &&
            formfield.error
          ) {
            formfield.error = false;
          }
        });
      }
    });
    setState((pre) => ({ ...pre, webForm: webForm }));
  };

  const resetMandatory = (e, name) => {
    const { webForm, webFormFields } = state;
    const { formSections } = webForm?.webFormDetail;
    let controlFound = false;
    formSections.map(({ formFields }) => {
      if (!controlFound) {
        formFields.map((formfield) => {
          if (formfield.fieldName === name) {
            formfield.isMandatory = e.currentTarget.checked;
          }
        });
      }
    });
  };
  const handleAchievementsChange = (e, dataRow) => {
    const { DropDowns, webFormFields } = state;
    dataRow[e.currentTarget.name] =
      e.currentTarget.type === "checkbox"
        ? e.currentTarget.checked
        : e.currentTarget.type === "select-one"
        ? e.currentTarget.value
        : e.currentTarget.value;
    if (webFormFields.HasPriorAchievments && DropDowns["PriorAchievments"]) {
      const achievements = DropDowns["PriorAchievments"];
      webFormFields.PriorAchievments = "";
      achievements.forEach((ach) => {
        if (ach.chkachievement) {
          if (webFormFields.PriorAchievments !== "")
            webFormFields.PriorAchievments += ",";
          webFormFields.PriorAchievments += ach.avetCodeID;
          if (ach.selachievement)
            webFormFields.PriorAchievments += "|" + ach.selachievement;
        }
      });
    }
    setState((pre) => ({ ...pre, DropDowns, webFormFields }));
  };
  const handleCheckBoxGroup = (e, dataRow) => {
    const { webFormFields } = state;
    let currentSelection = webFormFields[e.currentTarget.name] || "";

    //  = e.currentTarget.type === "checkbox" ? e.currentTarget.checked : (e.currentTarget.type === "select-one" ? e.currentTarget.value : e.currentTarget.value)
    if (e.currentTarget.checked) {
      if (currentSelection !== "") currentSelection += ",";
      currentSelection += e.currentTarget.value;
    } else {
      // if(currentSelection.indexOf(",") >= 0){
      var values = currentSelection.split(",");
      currentSelection = values
        .filter((val) => {
          return val !== e.currentTarget.value;
        })
        .join(",");
    }

    webFormFields[e.currentTarget.name] = currentSelection;
    setState((pre) => ({ ...pre, webFormFields: webFormFields }));
  };
  const handleChange = (e, name) => {
    const { webForm, webFormFields } = state;
    const { formSections } = webForm?.webFormDetail;
    // debugger
    let controlFound = false;

    let controlName = "";
    if (name) {
      let orgName = name.action ? name.name : name;
      if (e.constructor === new Date().constructor) {
        if (window.event.target.className.indexOf("react-datepicker") >= 0)
          webFormFields[orgName] = e;
        else {
          let tmpDate = window.event.target.value;
          if (tmpDate.length === 11) {
            // let tmpDate = moment(window.event.target.value,"dd/MMM/yyyy", true);

            if (moment(tmpDate, "DD/MMM/YYYY", true).isValid())
              webFormFields[orgName] = e;
          }
        }
      } else webFormFields[orgName] = e;
      controlName = name;
    } else {
      webFormFields[e.currentTarget.name] =
        e.currentTarget.type === "checkbox"
          ? e.currentTarget.checked
          : e.currentTarget.type === "select-one"
          ? parseInt(e.currentTarget.value)
          : e.currentTarget.type === "radio"
          ? JSON.parse(e.currentTarget.value)
          : e.currentTarget.value;
      controlName = e.currentTarget.name;
      if (controlName === "ReferredByAgent") {
        resetMandatory(e, "AgentId");
      } else if (controlName === "USIPermission") {
        resetMandatory(e, "UniqueStudentIdentifier");
      } else if (controlName === "CopyResidentialAddress") {
        copyAddress(e, 2);
      } else if (controlName === "AlreadyHaveUSI") {
        if (webFormFields["AlreadyHaveUSI"]) {
          if (JSON.parse(webFormFields["AlreadyHaveUSI"]) === 1) {
            webFormFields["CollegeSetUpUSI"] = undefined;
            webFormFields["TownOfBirth"] = undefined;
            webFormFields["BirthCountry"] = undefined;
          } else {
            webFormFields["USIPermission"] = undefined;
            resetMandatory(e, "UniqueStudentIdentifier");
            webFormFields["UniqueStudentIdentifier"] = undefined;
          }
        }
      }
    }
    formSections.map(({ formFields }) => {
      if (!controlFound) {
        formFields.map((formfield) => {
          if (
            formfield.fieldName === controlName &&
            webFormFields[controlName]
          ) {
            if (
              webFormFields[controlName].constructor === String().constructor &&
              webFormFields[controlName].trim() !== "" &&
              formfield.error
            ) {
              formfield.error = false;
            }

            if (
              webFormFields[controlName].constructor ===
                new Date().constructor &&
              formfield.error
            ) {
              formfield.error = false;
            }

            if (
              webFormFields[controlName].constructor ===
                new Number().constructor &&
              formfield.error
            ) {
              formfield.error = false;
            }
          }
        });
      }
    });
    setState((pre) => ({
      ...pre,
      webFormFields: webFormFields,
      webForm: webForm,
    }));
  };

  const copyAddress = (e, type) => {
    const { webFormFields } = state;
    if (e.currentTarget.checked) {
      let PropertyName = webFormFields["PropertyName"] ?? "";
      let StreetNo = webFormFields["StreetNo"] ?? "";
      var localaddress2 = "";
      if (PropertyName && PropertyName.trim() !== "") {
        webFormFields["MailingAddressLine1"] =
          webFormFields["PropertyName"] ?? "";
        localaddress2 = webFormFields["FlatDetail"] ?? "";

        if (StreetNo.trim() !== "") {
          if (localaddress2.trim() !== "") localaddress2 += "/";
          localaddress2 += StreetNo.trim();
        }

        if (localaddress2.trim() !== "") localaddress2 += " ";

        localaddress2 += webFormFields["StreetName"] ?? "";

        webFormFields["MailingAddressLine2"] = localaddress2;
      } else {
        localaddress2 = webFormFields["FlatDetail"] ?? "";
        if (StreetNo.trim() !== "") {
          if (localaddress2 !== "") localaddress2 += "/";

          localaddress2 += StreetNo.trim();

          if (localaddress2.trim() !== "") localaddress2 += " ";

          localaddress2 += webFormFields["StreetName"] ?? "";
          webFormFields["MailingAddressLine1"] = localaddress2;
        }
      }
      setState((pre) => ({
        ...pre,
        selectedMailingSuburbID: webFormFields["selectedAvetmissSuburbID"],
      }));
      webFormFields["MailingSuburbID"] =
        webFormFields["AvetmissSuburbID"] ?? "";
      webFormFields["MailingSuburbIDState"] =
        webFormFields["AvetmissSuburbIDState"] ?? "";
      webFormFields["MailingSuburbIDPostCode"] =
        webFormFields["AvetmissSuburbIDPostCode"] ?? "";
      webFormFields["MailingSuburbIDCountry"] =
        webFormFields["AvetmissSuburbIDCountry"] ?? "";

      setState((pre) => ({ ...pre, webFormFields: webFormFields }));
    }
  };

  const handleSuburb = (fieldName, selectedSuburb) => {
    const { webFormFields } = state;
    if (window.webformconfigs && window.webformconfigs.showStateAbbr)
      webFormFields[fieldName + "State"] = selectedSuburb.stateAbbr || "";
    else webFormFields[fieldName + "State"] = selectedSuburb.state || "";
    webFormFields[fieldName + "PostCode"] = selectedSuburb.postCode || "";
    webFormFields[fieldName + "Country"] = selectedSuburb.country || "";
    webFormFields[fieldName] = selectedSuburb.suburbID;
    webFormFields["selected" + fieldName] = selectedSuburb;
    setState((pre) => ({ ...pre, webFormFields: webFormFields }));
  };
  const handleAgent = (fieldName, selectedAgent) => {
    const { webFormFields } = state;
    webFormFields[fieldName] = selectedAgent.agentID;
    setState((pre) => ({ ...pre, webFormFields: webFormFields }));
  };

  const updateCourse = (courses) => {
    // debugger
    const { webFormFields } = state;
    webFormFields.courses = courses;
    setState((pre) => ({ ...pre, webFormFields: webFormFields }));
  };
  const updatePreference = (preferences) => {
    const { webFormFields } = state;
    webFormFields.preferences = preferences;
    setState((pre) => ({ ...pre, webFormFields: webFormFields }));
  };
  const updateReference = (references) => {
    const { webFormFields } = state;
    webFormFields.references = references;
    setState((pre) => ({ ...pre, webFormFields: webFormFields }));
  };
  const updateContact = (contacts) => {
    const { webFormFields } = state;
    webFormFields.contacts = contacts;
    setState((pre) => ({ ...pre, webFormFields: webFormFields }));
  };

  const setFiles = (files, doctype) => {
    const { webFormFields } = state;
    const currentDocumentObj = webFormFields.documents.filter(
      (doc) => doc.docTypeID === doctype
    )[0];
    currentDocumentObj.files = files;
    // console.log(doctype)
    // console.log(files)
    setState((pre) => ({ ...pre, webFormFields }));
  };

  const submitForm = () => {
    const { webForm, webFormFields, DropDowns } = state;
    const { formSections } = webForm?.webFormDetail;
    const { courses, preferences, references, contacts } = webFormFields;
    debugger;
    let hasErrors = false;
    let hasSignatureSection = false;
    if (webForm.formType === 4) {
      preferences.map((preference) => {
        const keys = Object.keys(preference);
        const { error } = preference;
        keys.map(function (key) {
          if (key !== "error") {
            if (preference[key] === null || preference[key] === undefined) {
              error[key] = true;
              return;
            }

            if (
              preference[key].constructor === String().constructor &&
              preference[key].trim() !== "" &&
              preference[key].trim() !== "0"
            ) {
              error[key] = false;
              return;
            }
            error[key] = true;
          }
        }, preference);
      });
    } else if (webForm.formType === 5) {
      references.map((reference) => {
        const keys = Object.keys(reference);
        const { error } = reference;
        keys.map(function (key) {
          if (key !== "error") {
            if (reference[key] === null || reference[key] === undefined) {
              error[key] = true;
              return;
            }

            if (
              reference[key].constructor === String().constructor &&
              reference[key].trim() !== "" &&
              reference[key].trim() !== "0"
            ) {
              error[key] = false;
              return;
            }
            error[key] = true;
          }
        }, reference);
      });
      contacts.map((contact) => {
        const keys = Object.keys(contact);
        const { error } = contact;
        keys.map(function (key) {
          if (key !== "error") {
            if (contact[key] === null || contact[key] === undefined) {
              error[key] = true;
              return;
            }

            if (
              contact[key].constructor === String().constructor &&
              contact[key].trim() !== "" &&
              contact[key].trim() !== "0"
            ) {
              error[key] = false;
              return;
            }
            error[key] = true;
          }
        }, contact);
      });
    } else if (webForm.formType <= 3) {
      courses.map((course) => {
        const keys = Object.keys(course);
        const { error } = course;
        const { courseType } = { webForm };
        keys.map(function (key) {
          if (key !== "error" && key !== "HolidayWeeks") {
            if (
              courseType === 9 &&
              (course[key] === "UOS" || course[key] === "Subject")
            ) {
              error[key] = false;
              return;
            }
            if (courseType === 22 && course[key] === "UOS") {
              error[key] = false;
              return;
            }

            if (courseType === 23 && course[key] === "Subject") {
              error[key] = false;
              return;
            }

            if (course[key] === null || course[key] === undefined) {
              error[key] = true;
              return;
            }
            if (
              course[key].constructor === Number().constructor &&
              course[key] > 0
            ) {
              error[key] = false;
              return;
            }
            if (
              course[key].constructor === String().constructor &&
              course[key].trim() !== "" &&
              course[key].trim() !== "0"
            ) {
              error[key] = false;
              return;
            }

            if (course[key].constructor === new Date().constructor) {
              error[key] = false;
              return;
            }
            error[key] = true;
          }
        }, course);
      });
    }
    try {
      if (!hasErrors) {
        if (webForm.uploadDocuments) {
          webForm.uploadDocuments.map((dt) => {
            if (dt.mandatory) {
              if (dt.files && dt.files.length > 0) {
                dt.error = false;
              } else {
                dt.error = true;
                hasErrors = true;
              }
            }
          });
        }
        formSections?.map(({ formSectionID, formFields }) => {
          if (formSectionID !== 29 && formSectionID !== 34) {
            formFields.map((formField) => {
              formField.error = false;
              if (formField.isMandatory) {
                if (
                  formField.fieldName === "Email" &&
                  webFormFields[formField.fieldName] &&
                  webFormFields[formField.fieldName].trim() !== ""
                ) {
                  var pattern = new RegExp(
                    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
                  );

                  if (!pattern.test(webFormFields[formField.fieldName])) {
                    formField.error = true;
                    return;
                  }
                }
                if (
                  formField.fieldName === "Mobile" &&
                  webFormFields[formField.fieldName] &&
                  webFormFields[formField.fieldName].trim() !== ""
                ) {
                  var pattern = new RegExp(/^[0-9\b]+$/);
                  // pattern = new RegExp(/^(\+\d{1,3}[- ]?)?\d{10}$/);
                  pattern = new RegExp(
                    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
                  );
                  console.log(pattern.test(webFormFields[formField.fieldName]));
                  if (!pattern.test(webFormFields[formField.fieldName])) {
                    formField.error = true;
                    return;
                  }
                  // else if (webFormFields[formField.fieldName].length !== 10) {
                  //     formField.error = true
                  //     return
                  // }
                }
                if (
                  webFormFields[formField.fieldName] &&
                  (webFormFields[formField.fieldName].constructor ===
                    new Boolean().constructor ||
                    webFormFields[formField.fieldName].constructor ===
                      new Array().constructor)
                ) {
                  formField.error = false;
                  return;
                }
                if (!webFormFields[formField.fieldName]) {
                  formField.error = true;
                  hasErrors = true;
                } else if (
                  webFormFields[formField.fieldName].constructor !==
                    new Date().constructor &&
                  webFormFields[formField.fieldName].constructor !==
                    new Number().constructor &&
                  webFormFields[formField.fieldName].trim() === ""
                ) {
                  formField.error = true;
                  hasErrors = true;
                } else if (
                  webFormFields[formField.fieldName].constructor ===
                    new Number().constructor &&
                  !(parseInt(webFormFields[formField.fieldName]) > 0)
                ) {
                  formField.error = true;
                  hasErrors = true;
                } else if (formField.minSelectionCount > 0) {
                  let cVal = webFormFields[formField.fieldName];
                  if (cVal.split(",").length < formField.minSelectionCount) {
                    formField.error = true;
                    hasErrors = true;
                  }
                }
                if (formField.fieldName === "PriorAchievments") {
                  if (
                    webFormFields["PriorAchievments"] &&
                    webFormFields["PriorAchievments"] !== ""
                  ) {
                    let strPriorAchievements =
                      webFormFields["PriorAchievments"].split(",");
                    let errorFlag = false;
                    strPriorAchievements.forEach(function (item, index) {
                      if (item.indexOf("|") < 0) {
                        formField.error = true;
                        hasErrors = true;
                        return;
                      }
                    });
                  }
                }
              } else if (
                formField.fieldName === "CollegeSetUpUSI" ||
                formField.fieldName === "USIPermission"
              ) {
                if (
                  !webFormFields["CollegeSetUpUSI"] &&
                  !webFormFields["USIPermission"]
                ) {
                  formField.error = true;
                  hasErrors = true;
                }
              }
            });
          }
        });

        const customSections =
          formSections.filter(
            (fs) =>
              fs.formSectionID === 3 ||
              fs.formSectionID === 4 ||
              fs.formSectionID === 34 ||
              fs.formSectionID === 40
          ) || [];

        customSections.map((section) => {
          section.error = false;
          if (section.formSectionTitle === "Terms and Conditions") {
            if (!webFormFields["TermsChecked"]) {
              hasErrors = true;
              section.error = true;
            }
          }
          if (section.formSectionTitle === "Student Declaration") {
            if (!webFormFields["DeclarationChecked"]) {
              hasErrors = true;
              section.error = true;
            }
          }
          if (section.formSectionTitle === "Personal Consent") {
            if (!webFormFields["PersonalConsent"]) {
              hasErrors = true;
              section.error = true;
            }
          }
          if (section.formSectionTitle === "Signature") {
            hasSignatureSection = true;
            if (!sigPadRef.current) {
              hasErrors = true;
              section.error = true;
            } else {
              if (sigPadRef.current?.isEmpty()) {
                hasErrors = true;
                section.error = true;
              }
            }
          }
        });
      }
    } catch (e) {
      alert(
        "An error occured while validating form. Please try again after some time."
      );
    }
    // vaidation failed
    if (hasErrors) {
      alert(
        "Your application submitted has errors. Please review your answers above and fix any errors."
      );
      setState((pre) => ({ ...pre, webForm: webForm }));
    } else {
      setState((pre) => ({
        ...pre,
        modalState: true,
        modalMessage: "Saving Form please wait",
      }));

      webFormFields.contacts.map((c) => {
        c.Position = parseInt(c.Position);
      });
      webFormFields.courses.map((c) => {
        c.Campus = parseInt(c.Campus);
        c.Intake = parseInt(c.Intake);
        c.Program = parseInt(c.Program);
        c.AgentId = parseInt(localStorage.getItem("agentId"));
      });
      webFormFields.preferences.map((c) => {
        c.Campus = parseInt(c.Campus);
        c.Program = parseInt(c.Program);
      });
      if (webFormFields.LastSchoolYear)
        webFormFields.LastSchoolYear = parseInt(webFormFields.LastSchoolYear);
      if (webFormFields.NoOfStudentReferred)
        webFormFields.NoOfStudentReferred = parseInt(
          webFormFields.NoOfStudentReferred
        );
      if (webFormFields.PersonalConsent)
        webFormFields.PersonalConsent = parseInt(webFormFields.PersonalConsent);

      const webFormPost = { ...webFormFields };
      // if (webFormFields.HasPriorAchievments && DropDowns["PriorAchievments"]) {
      //     const achievements = DropDowns["PriorAchievments"]
      //     webFormPost.PriorAchievments = ""
      //     achievements.forEach(ach => {
      //         if (ach.chkachievement) {
      //             if (webFormPost.PriorAchievments !== "") webFormPost.PriorAchievments += ","
      //             webFormPost.PriorAchievments += ach.avetCodeID
      //             if (ach.selachievement) webFormPost.PriorAchievments += "|" + ach.selachievement
      //         }
      //     })
      // }
      if (webFormFields.Disability && webFormFields.Disability.length > 0) {
        webFormPost.Disability = webFormFields.Disability.map(
          (x) => x.avetCodeID
        ).toString();
        webFormFields.Disability = webFormPost.Disability;
      }

      Axios.post(constants.BASEURL + "WebForms/SaveWebForm", webFormFields)
        .then(async (result) => {
          let message = result.data;
          if (message.status === 200) {
          } else {
            throw result.data.message;
          }

          if (hasSignatureSection) {
            storeSignaturesSync(webFormFields, message.applicantId);
          } else {
            storeDocumentsSync(webFormFields, message.applicantId, 0);
          }
        })
        .catch((ex) => {
          alert("An Error occured while saving webform");
          setState((pre) => ({ ...pre, modalState: false }));
        });
    }
  };

  const storeSignaturesSync = (webFormFields, applicantId) => {
    let canvas = sigPadRef.current.getCanvas();
    canvas.toBlob((blob) => {
      const fd = new window.FormData();
      // fd.append('signature', blob, 'signature.png')
      // POST to server with your preferred requests library
      let form = new FormData();
      form.append(
        "file",
        blob,
        webFormFields.FirstName.trim() +
          webFormFields.LastName.trim() +
          "_signature.png"
      );
      form.append("applicantId", applicantId);
      form.append("docTypeId", 0);
      form.append("applicantType", webFormFields.applicationType);
      form.append("isSignature", 1);
      try {
        Axios.post(constants.BASEURL + "WebForms/SaveFormDocuments", form).then(
          (result) => {
            // debugger
            if ((result.data.success = false)) {
              setState((pre) => ({
                ...pre,
                modalMessage: " Unabe to upload signature. ",
              }));
            } else {
              setState((pre) => ({
                ...pre,
                modalMessage: " Signature uploaded. ",
              }));
              storeDocumentsSync(webFormFields, applicantId, 0);
            }
          }
        );
      } catch (e) {
        setState((pre) => ({
          ...pre,
          modalMessage:
            " An Error occured while uploading signature. Please try later.",
        }));
      }
    });
  };

  const storeDocumentsSync = (webFormFields, applicantId, iteration) => {
    const { documents } = webFormFields;
    if (iteration < documents.length) {
      let form = new FormData();
      if (documents[iteration].files) {
        for (
          var index = 0;
          index < documents[iteration].files.length;
          index++
        ) {
          var element = documents[iteration].files[index];
          form.append("file", element);
        }
        form.append("applicantId", applicantId);
        form.append("docTypeId", documents[iteration].docTypeID);
        form.append("applicantType", webFormFields.applicationType);
        try {
          Axios.post(
            constants.BASEURL + "WebForms/SaveFormDocuments",
            form
          ).then((result) => {
            if ((result.data.success = false)) {
              setState((pre) => ({
                ...pre,
                modalMessage: documents[iteration].docType + " failed. ",
              }));
            } else {
              setState((pre) => ({
                ...pre,
                modalMessage: documents[iteration].docType + " uploaded. ",
              }));
              storeDocumentsSync(webFormFields, applicantId, iteration + 1);
            }
          });
        } catch (e) {
          setState((pre) => ({
            ...pre,
            modalMessage:
              " An Error occured while uploading documents. Please try later.",
          }));
        }
      } else {
        storeDocumentsSync(webFormFields, applicantId, iteration + 1);
      }
    } else {
      setTimeout(() => {
        setState((pre) => ({
          ...pre,
          modalMessage: "Form Submitted Successfully.",
        }));
      }, 3000);
    }
  };

  const afterQuizCompleted = (quizId) => {
    const completedQuiz = [...state.completedQuizes, quizId];
    const allQuiz = state.webForm.quizIds.split(",");
    const pendingQuiz = allQuiz.filter((x) => !completedQuiz.includes(x));
    setState((pre) => ({ ...pre, completedQuiz: completedQuiz }));

    if (pendingQuiz.length > 0) {
      navigate(`/webform/${id}/application/1/quiz/${pendingQuiz[0]}`, {
        replace: true,
      });
      window.location.reload();
    }
  };

  const { webForm, modalState, modalMessage, buttonList, isPreview } = state;
  // commenting quiz work for now
  if (quizId > 0) {
    return (
      <AnswerQuiz
        afterUpdate={afterQuizCompleted}
        quizId={quizId}
        objectType={1}
        objectId={1}
      />
    );
  } else {
    return (
      <>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="mb-4">
            <form name="webform">
              <RenderWebForm
                state={state}
                updateCourse={updateCourse}
                setFiles={setFiles}
                handleChange={handleChange}
                updatePreference={updatePreference}
                updateReference={updateReference}
                updateContact={updateContact}
                sigPadRef={sigPadRef}
                clearSignature={clearSignature}
                trim={trim}
                handleAgent={handleAgent}
                handleCheckBoxGroup={handleCheckBoxGroup}
                handleAchievementsChange={handleAchievementsChange}
                handleSuburb={handleSuburb}
              />
              <div className="text-right sm:col-span-2">
                <span className="inline-flex rounded-md shadow-sm">
                  <button
                    type="button"
                    onClick={submitForm}
                    className="inline-flex justify-center py-3 px-6 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                  >
                    {webForm && webForm?.quizIds !== "" ? "Next >" : "Submit"}
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
        <Modal
          modalState={modalState}
          buttonsList={
            modalMessage === "Form Submitted Successfully." ? buttonList : []
          }
          headingText="Form Status"
        >
          <div className="text-center">{modalMessage}</div>
        </Modal>
      </>
    );
  }
}

export default WebFormComponent;
