import React from 'react';

function FormLabel({ color = "gray", name, text }) {
    return (
        <label
            htmlFor={name}
            className={"block text-sm font-medium leading-5 text-" + (color) + "-700"}
        >
            {text}
        </label>
    )
}

export default FormLabel