import moment from "moment";
import TextBox from "../controls/TextBox";
import SelectBox from "../controls/SelectBox";
import DateBox from "../controls/DateBox";
import AutoComplete from "../common/AutoComplete";
import MultiSelectBox from "../controls/MultiSelectBox";
import CheckBox from "../controls/CheckBox";
import RadioBox from "../controls/RadioBox";
import FormLabel from "../controls/FormLabel";
import TextAreaBox from "../controls/TextAreaBox";
import CheckBoxGroup from "../controls/CheckBoxGroup";
const RenderField = ({
  formField,
  state,
  handleChange,
  handleAgent,
  handleCheckBoxGroup,
  handleAchievementsChange,
  handleSuburb,
}) => {
  const { webFormFields, DropDowns, webForm } = state;
  const {
    fieldType,
    fieldLabel,
    fieldName,
    error,
    isMandatory,
    extraLabel,
    staticData,
  } = formField;

  let dependentOn = "";
  // if (webFormFields[fieldName] && webFormFields[fieldName].trim() != "") formField.error = false
  dependentOn = true;
  if (
    fieldName == "IELTSTestLocation" ||
    fieldName == "IELTSTestScore" ||
    fieldName == "IELTSTestDate"
  ) {
    dependentOn = webFormFields["IELTSTestCurrent"]; //&& webFormFields["EnglishFirstLanguage"]
  } else if (
    fieldName == "EnglishPriorCourseProvider" ||
    fieldName == "EnglishPriorCourseName"
  ) {
    dependentOn = webFormFields["EnglishPriorCourse"];
  } else if (
    fieldName == "EnglishAssessmentTestMethod" ||
    fieldName == "EnglishAssessmentTestScore" ||
    fieldName == "EnglishAssessmentTestDate"
  ) {
    dependentOn = webFormFields["EnglishAssessmentTestCompleted"];
  } else if (
    fieldName == "HomeStayRequestType" ||
    fieldName == "HomeStayRequestDuration"
  ) {
    dependentOn = webFormFields["HomeStayRequested"];
  } else if (fieldName == "NameOSHCInsurer" || fieldName == "MemberNumber") {
    dependentOn = webFormFields["OSHCArranged"];
  } else if (
    fieldName == "NameOSHCCoverDates" ||
    fieldName == "NameOSHCCoverType"
  ) {
    dependentOn = !webFormFields["OSHCArranged"];
  }

  switch (fieldType) {
    case 1:
      if (fieldName == "UniqueStudentIdentifier") {
        if (window.webformconfigs && window.webformconfigs.showOldUSISection) {
          dependentOn = webFormFields["USIPermission"];
        } else {
          dependentOn =
            webFormFields["USIPermission"] &&
            webFormFields["AlreadyHaveUSI"] &&
            JSON.parse(webFormFields["USIPermission"]) == 1 &&
            JSON.parse(webFormFields["AlreadyHaveUSI"]) == 1;
        }
        formField.isMandatory = dependentOn;
        formField.error = false;
      } else if (fieldName == "LastSchoolYear") {
        let schoolLevel = webFormFields["SchoolLevelCompleted"];
        const schoolLevelDD = DropDowns["SchoolLevelCompleted"];
        if (
          webFormFields["SchoolLevelCompleted"] &&
          webFormFields["SchoolLevelCompleted"] > 0
        ) {
          const selectedSLD = schoolLevelDD.filter(
            (sld) => sld.avetCodeID == webFormFields["SchoolLevelCompleted"]
          );
          if (
            selectedSLD[0].avetCode == "02" ||
            selectedSLD[0].avetCode == "@@"
          ) {
            formField.isMandatory = false;
            formField.error = false;
          } else {
            formField.isMandatory = true;
            formField.error = false;
          }
        } else {
          formField.isMandatory = false;
          formField.error = false;
        }
      } else if (fieldName == "DIBPCity" || fieldName == "DIBPCountry") {
        if (
          webFormFields["VisaType"] &&
          ((parseInt(webFormFields["VisaType"]) >= 1 &&
            parseInt(webFormFields["VisaType"]) <= 7) ||
            parseInt(webFormFields["VisaType"]) == 18 ||
            parseInt(webFormFields["VisaType"]) == 25)
        ) {
        } else {
          dependentOn = false;
        }
      } else if (fieldName == "PriorVisaRefusalNotes") {
        dependentOn = webFormFields["PriorVisaRefusal"];
      } else if (fieldName == "TownOfBirth") {
        if (window.webformconfigs && window.webformconfigs.showOldUSISection) {
          dependentOn = webFormFields["CollegeSetUpUSI"];
        } else {
          dependentOn =
            webFormFields["CollegeSetUpUSI"] &&
            webFormFields["AlreadyHaveUSI"] &&
            JSON.parse(webFormFields["CollegeSetUpUSI"]) == 1 &&
            JSON.parse(webFormFields["AlreadyHaveUSI"]) == 2;
        }
        formField.isMandatory = dependentOn;
        formField.error = false;
      } else if (fieldName == "EATCName") {
        dependentOn = webFormFields["IsCompletedEATC"];
        formField.isMandatory = dependentOn;
        formField.error = false;
      } else if (fieldName == "TaxFileNo") {
        // dependentOn = webFormFields["HasTaxFileNo"]
        dependentOn = false;
        formField.isMandatory = dependentOn;
        formField.error = false;
      }

      return (
        <TextBox
          dependentOn={dependentOn}
          name={fieldName}
          error={error}
          label={fieldLabel + (isMandatory ? "*" : "")}
          value={webFormFields[fieldName]}
          onChange={handleChange}
        />
      );
      break;
    case 2:
      if (fieldName == "TypeOfApprenticeship") {
        dependentOn = webFormFields["RegisterinApprenticeship"];
        formField.isMandatory = dependentOn;
        formField.error = false;
      } else if (fieldName == "AvetmissSchoolType") {
        dependentOn = webFormFields["AtSchool"];
        formField.isMandatory = dependentOn;
        formField.error = false;
      } else if (fieldName == "BirthCountry") {
        if (window.webformconfigs && window.webformconfigs.showOldUSISection) {
          dependentOn = webFormFields["CollegeSetUpUSI"];
        } else {
          dependentOn =
            webFormFields["CollegeSetUpUSI"] &&
            webFormFields["AlreadyHaveUSI"] &&
            JSON.parse(webFormFields["CollegeSetUpUSI"]) == 1 &&
            JSON.parse(webFormFields["AlreadyHaveUSI"]) == 2;
        }
        formField.isMandatory = dependentOn;
        formField.error = false;
      }

      return (
        <SelectBox
          dependentOn={dependentOn}
          name={fieldName}
          error={error}
          label={fieldLabel + (isMandatory ? "*" : "")}
          value={webFormFields[fieldName]}
          onChange={handleChange}
          data={DropDowns[fieldName]}
        />
      );
      break;
    case 3:
      return fieldName == "DateOfBirth" ? (
        <DateBox
          dependentOn={dependentOn}
          name={fieldName}
          error={error}
          label={fieldLabel + (isMandatory ? "*" : "")}
          maxDate={moment()
            .add(-1 * webForm.minAgeEligibility, "years")
            .toDate()}
          value={webFormFields[fieldName]}
          onChange={handleChange}
        />
      ) : (
        <DateBox
          dependentOn={dependentOn}
          name={fieldName}
          error={error}
          label={fieldLabel + (isMandatory ? "*" : "")}
          value={webFormFields[fieldName]}
          onChange={handleChange}
        />
      );
      break;
    case 4:
      return (
        <TextBox
          name={fieldName}
          dependentOn={dependentOn}
          error={error}
          label={fieldLabel + (isMandatory ? "*" : "")}
          value={webFormFields[fieldName]}
          onChange={handleChange}
        />
      );
      break;
    case 5:
      // console.log(fieldName)
      return (
        <TextBox
          name={fieldName}
          dependentOn={dependentOn}
          error={error}
          label={fieldLabel + (isMandatory ? "*" : "")}
          value={webFormFields[fieldName]}
          onChange={handleChange}
        />
      );
      break;
    case 6:
      if (fieldName == "Disability") {
        dependentOn =
          webFormFields["HasDisability"] &&
          JSON.parse(webFormFields["HasDisability"]) == 1;
        formField.isMandatory = dependentOn;
        formField.error = false;
      }

      return (
        <MultiSelectBox
          name={fieldName}
          dependentOn={dependentOn}
          error={error}
          label={fieldLabel + (isMandatory ? "*" : "")}
          value={webFormFields[fieldName]}
          onChange={handleChange}
          data={DropDowns[fieldName]}
        />
      );
      break;
    case 7:
      // console.log(fieldName.indexOf("Suburb"))
      if (fieldName.indexOf("Suburb") > 0) {
        if (fieldName == "IntrnationalSuburbID") {
        } else {
          return (
            <>
              <AutoComplete
                name={fieldName}
                error={error}
                label={fieldLabel + (isMandatory ? "*" : "")}
                value={webFormFields[fieldName]}
                onChange={handleSuburb}
                selectedItem={
                  state["selected" + fieldName] && state["selected" + fieldName]
                }
              />
              <TextBox
                dependentOn={dependentOn}
                readOnly={true}
                name={fieldName + "State"}
                error={error}
                label={"State"}
                value={webFormFields[fieldName + "State"]}
              />
              <TextBox
                dependentOn={dependentOn}
                readOnly={true}
                name={fieldName + "PostCode"}
                error={error}
                label={"Post Code"}
                value={webFormFields[fieldName + "PostCode"]}
              />
              <TextBox
                dependentOn={dependentOn}
                readOnly={true}
                name={fieldName + "Country"}
                error={error}
                label={"Country"}
                value={webFormFields[fieldName + "Country"]}
              />
            </>
          );
        }
      } else {
        if (fieldName == "AgentId") {
          dependentOn = webFormFields["ReferredByAgent"] || false;
        }
        return (
          <AutoComplete
            name={fieldName}
            error={error}
            endPoint="Agents/List"
            dependentOn={dependentOn}
            label={fieldLabel + (isMandatory ? "*" : "")}
            value={webFormFields[fieldName]}
            onChange={handleAgent}
          />
        );
      }
      break;
    case 8:
      var tempVal = false;
      // console.log(fieldName)
      if (fieldName == "PriorVisaRefusal") {
        if (
          webFormFields["VisaType"] &&
          ((parseInt(webFormFields["VisaType"]) >= 1 &&
            parseInt(webFormFields["VisaType"]) <= 7) ||
            parseInt(webFormFields["VisaType"]) == 18 ||
            parseInt(webFormFields["VisaType"]) == 25)
        ) {
        } else {
          dependentOn = false;
        }
      } else if (fieldName == "IELTSTestCurrent") {
        // dependentOn = webFormFields["EnglishFirstLanguage"]
      } else if (fieldName == "USIPermission") {
        if (window.webformconfigs && window.webformconfigs.showOldUSISection) {
          tempVal = true;
        } else {
          tempVal =
            webFormFields["AlreadyHaveUSI"] &&
            JSON.parse(webFormFields["AlreadyHaveUSI"]) == 1;
        }
        dependentOn = tempVal;
      } else if (fieldName == "CollegeSetUpUSI") {
        if (window.webformconfigs && window.webformconfigs.showOldUSISection) {
          tempVal = true;
          dependentOn = tempVal;
        } else {
          tempVal =
            webFormFields["AlreadyHaveUSI"] &&
            JSON.parse(webFormFields["AlreadyHaveUSI"]) == 2;
          dependentOn = tempVal;
        }
      }

      return (
        <CheckBox
          dependentOn={dependentOn}
          hintText={extraLabel}
          error={error}
          label={fieldLabel}
          name={fieldName}
          value={webFormFields[fieldName]}
          onChange={handleChange}
        />
      );
      break;
    case 9:
      if (fieldName == "OnshoreOffShoreStatus") {
        dependentOn = webFormFields["OverseasStudent"];
      } else if (fieldName == "Medical_Flag") {
        dependentOn =
          webFormFields["HasDisability"] &&
          JSON.parse(webFormFields["HasDisability"]) == 1;
      }

      return (
        <RadioBox
          dependentOn={dependentOn}
          error={error}
          value={webFormFields[fieldName]}
          name={fieldName}
          label={fieldLabel}
          onChange={handleChange}
          dataStr={formField.staticData}
        />
      );
      break;
    case 10:
      const data = DropDowns[fieldName];
      const staticData = [
        { idField: "A", valueField: "A - Australian" },
        { idField: "E", valueField: "E - Australian Equivalent" },
        { idField: "I", valueField: "I - International" },
      ];
      if (fieldName == "PriorAchievments") {
        dependentOn = webFormFields["HasPriorAchievments"];
        formField.isMandatory = dependentOn;
        formField.error = false;
      }

      return (
        <div
          className={
            "col-span-8 sm:col-span-4" + (!dependentOn ? " hidden" : "")
          }
        >
          <FormLabel
            key={fieldLabel + 1}
            text={fieldLabel}
            color={error ? "red" : "black"}
          />
          <div className=" overflow-auto h-32">
            <div className="grid grid-cols-3 gap-3">
              {data?.length > 0 &&
                data?.map((ach) => (
                  <>
                    <div className="col-span-2">
                      <CheckBox
                        dependentOn={true}
                        name="chkachievement"
                        error={error}
                        label={ach["valueField"]}
                        value={ach.chkachievement}
                        onChange={(event) =>
                          handleAchievementsChange(event, ach)
                        }
                      />
                    </div>
                    <div className="col-span-1">
                      <SelectBox
                        dependentOn={dependentOn}
                        name="selachievement"
                        label={" "}
                        value={ach.selachievement}
                        onChange={(event) =>
                          handleAchievementsChange(event, ach)
                        }
                        data={staticData}
                      />
                    </div>
                  </>
                ))}
            </div>
          </div>
        </div>
      );
      // return <GroupedControlBox dependentOn={dependentOn} label={fieldLabel} name={fieldName} value={webFormFields[fieldName]} onChange={handleChange} />
      break;
    case 11:
      return (
        <TextAreaBox
          rows="3"
          dependentOn={dependentOn}
          name={fieldName}
          error={error}
          label={fieldLabel + (isMandatory ? "*" : "")}
          value={webFormFields[fieldName]}
          onChange={handleChange}
        />
      );
      break;
    case 12:
      // if (fieldName == "OnshoreOffShoreStatus") {
      //     dependentOn = webFormFields["OverseasStudent"]

      // }
      let currentFieldValue = webFormFields[formField.fieldName];
      let chGroupData = [];
      const sData = formField.staticData;
      if (sData != "") {
        const dataArr = sData.split(",");
        dataArr.forEach((dt) => {
          let tempArray = dt.split("|");
          let dataObj = {};
          dataObj.idField = tempArray[0];
          dataObj.valueField = tempArray[1];
          dataObj.checked =
            ("," + webFormFields[fieldName] + ",").indexOf(
              "," + tempArray[0] + ","
            ) >= 0
              ? true
              : false;
          if (formField.maxSelectionCount > 0)
            dataObj.disabled =
              !dataObj.checked &&
              currentFieldValue &&
              currentFieldValue.split(",").length >= formField.maxSelectionCount
                ? true
                : false;
          else dataObj.disabled = false;
          chGroupData.push(dataObj);
        });
      }

      return (
        <CheckBoxGroup
          error={formField.error}
          dependentOn={dependentOn}
          name={fieldName}
          label={fieldLabel}
          onChange={(event) => handleCheckBoxGroup(event, chGroupData)}
          data={chGroupData}
        />
      );
      break;
    default:
      return "";
  }
};

export default RenderField;
