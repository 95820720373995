export function currencyFormat(v) {
  let value = parseFloat(v).toFixed(2);
  let formattedString = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return formattedString;
}

export function multiselectFilter(data, dataArray, keyword) {
  let filteredData = [];
  dataArray.map((element) => {
    let dataItem = data.filter(
      (object) => object[keyword].toUpperCase() === element.toUpperCase()
    );
    if (dataItem != "") {
      filteredData = filteredData.concat(dataItem);
    }
  });
  return filteredData;
}

export function SearchFilter(data, value, keyword) {
  let filteredData = data.filter((object) =>
    object[keyword].toUpperCase().includes(value.toUpperCase())
  );

  return filteredData;
}
