import React from "react";
import FormLabel from "./FormLabel";
import { Multiselect } from "multiselect-react-dropdown";

const MultiSelection = (props) => {
  // state = {};

  return (
    <div className="">
      <FormLabel name={"Ms1"} text={props.label} />
      <div className="mt-1 relative rounded-md shadow-sm ">
        <Multiselect
          id={props.id}
          options={props.data}
          isObject={false}
          showCheckbox={true}
          selectedValues={props.selectedValues}
          onSelect={props.onSelect}
          onRemove={props.onRemove}
          placeholder={props.placeholder}
          avoidHighlightFirstOption={true}
          style={{
            chips: {
              background: "#17a2b8",
              "white-space": "normal",
              "font-weight": "bold",
            },
            searchBox: {
              background: "#fff",
              "padding-top": "0.35rem",
              "padding-bottom": "0.35rem",
            },
            inputField: { width: "5rem" },
          }}
        />
      </div>
    </div>
  );
};

export default MultiSelection;
