export function DateFormatter() {
  this.formatter = function (date) {
    let oDate = new Date(date);
    let objectYear = oDate.getFullYear();
    let objectMonth = oDate.getMonth() + 1;
    let objectDate = oDate.getDate();

    if (objectDate < 10) {
      objectDate = "0" + objectDate;
    }
    if (objectMonth < 10) {
      objectMonth = "0" + objectMonth;
    }

    let formattedDate = objectDate + "/" + objectMonth + "/" + objectYear;
    return formattedDate;
  };

  this.formatterTwo = function (date) {
    let oDate = new Date(date);
    let objectYear = oDate.getFullYear();
    let objectMonth = oDate.getMonth() + 1;
    let objectDate = oDate.getDate();

    if (objectDate < 10) {
      objectDate = "0" + objectDate;
    }
    if (objectMonth < 10) {
      objectMonth = "0" + objectMonth;
    }

    let formattedDate = objectYear + "-" + objectMonth + "-" + objectDate;
    return formattedDate;
  };

  this.formatterThree = function (date) {
    let oDate = new Date(date);
    let objectYear = oDate.getFullYear();
    let objectMonth = oDate.getMonth() + 1;
    let objectDate = oDate.getDate();

    if (objectDate < 10) {
      objectDate = "0" + objectDate;
    }
    if (objectMonth < 10) {
      objectMonth = "0" + objectMonth;
    }

    let formattedDate =
      objectYear + "-" + objectMonth + "-" + objectDate + "T00:00:00";
    return formattedDate;
  };
}
