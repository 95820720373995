import React from "react";
import FormLabel from "./FormLabel";
import CardHeader from "../common/CardHeader";
import SignatureCanvas from "react-signature-canvas";
import styles from "../../App.css";

export default function SignaturePad({
  dependentOn,
  error,
  onEnd,
  reference,
  label,
  subTitle,
  clearFn,
}) {
  return (
    <div className="mb-4">
      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <div className="px-4 py-4 sm:px-6">
          <CardHeader
            settings={{ headingText: label, description: subTitle }}
          />

          <div className={"px-4 py-4 sm:px-6"}>
            <div
              className={
                "grid grid-cols-2 gap-6" +
                (error ? " border border-red-400" : "")
              }
            >
              <div className="col-start-2 ">
                <p className="text-sm leading-5 italic">
                  When signing this form, use your mouse or touchpad pen to sign
                  in the box below (with the best version of your signature)
                </p>
              </div>
              <div className="col-start-2 border">
                <SignatureCanvas
                  ref={reference}
                  canvasProps={{
                    willReadFrequently: true,
                    className: styles.sigPad,
                  }}
                  onEnd={onEnd}
                />
              </div>
              <div className="col-start-2 text-center">
                <button
                  type="button"
                  onClick={clearFn}
                  className="place-self-center py-3 px-6 border border-transparent text-base leading-6 font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
