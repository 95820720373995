import React, { useEffect, useState } from "react";

import TableComponent from "./common/TableComponent";
import moment from "moment";
import DateBox from "./controls/DateBox";
import Transition from "./common/Transition";
import * as constants from "../constants";
import { DateFormatter } from "./common/DateFormatter";
import { AgentsRequests } from "../apiRequests/AgentsRequests";
import MultiSelection from "./controls/MultiSelection";
import SearchBox from "./controls/SearchBox";
import { currencyFormat, multiselectFilter } from "./common/commonFns";
import Loader from "./common/Loader";

const dateFormatter = new DateFormatter();
const agentsRequests = new AgentsRequests();

function ActiveEnrolments(props) {
  const [state, setState] = useState({
    hidden: "hidden",
    allPrograms: [],
    allCampuses: [],
    allEnrolmentNo: [],
    programsSelectedList: [],
    campusSelectedList: [],
    enrolmentNoSelectedList: [],
    isLoaded: false,
    agentId: localStorage.getItem("agentId"),
    tableRows: [],
    tableEmpty: "hidden",
    firstRowIndex: 0,
    lastRowIndex: constants.NUMBEROFROWS - 1,
    filterOn: false,
    dataLength: 0,
    searchFieldValue: "",
    isOpen: false,
    expanded: "",
    background: "hidden",
    startDate: "",
    endDate: "",
    enrolmentsData: [],
  });

  useEffect(() => {
    const storedStartDate = localStorage.getItem("t5StartDate");
    const storedEndDate = localStorage.getItem("t5EndDate");
    setState((prevState) => ({
      ...prevState,
      hidden: "",
      isLoaded: true,
      startDate: storedStartDate,
      endDate: storedEndDate,
    }));
  }, []);

  const getEnrolmentCommission = (startDate, endDate) => {
    agentsRequests.enrolmentCommission(startDate, endDate).then((result) => {
      let commissionData = result;
      if (commissionData !== "error") {
        allActiveEnrolmentsFn(startDate, endDate, commissionData);
      }
    });
  };

  const allActiveEnrolmentsFn = async (startDate, endDate, commissionData) => {
    try {
      const response = await agentsRequests.allActiveEnrolments(
        startDate,
        endDate
      );
      const allEnrolments = response !== "error" ? response : [];
      const enrolmentsCommission = commissionData;

      const combinedData = allEnrolments.map((object) => {
        const a = enrolmentsCommission.find(
          (element) => element.enrolmentID === object.enrolmentID
        );
        return {
          enrolmentNo: object.enrolmentNo,
          studentNo: object.studentNo,
          programName: object.programName,
          studentName: object.studentName,
          startDate: object.enrolmentStartDate,
          endDate: object.enrolmentEndDate,
          campus: object.campus,
          commission: a ? a.totalCommission : 0,
        };
      });

      setState((prevState) => ({
        ...prevState,
        enrolmentsData: combinedData,
        dataLength: combinedData.length,
      }));

      // loadMultiSelectData();
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    loadMultiSelectData();
  }, [state.enrolmentsData, state.dataLength]);
  const loadMultiSelectData = () => {
    let allPrograms = [];
    let allCampuses = [];
    let allEnrolmentNo = [];
    let data = state?.enrolmentsData;
    data?.forEach((object) => {
      if (!allPrograms.includes(object.programName)) {
        allPrograms.push(object.programName);
      }
    });

    data?.forEach((object) => {
      if (!allCampuses.includes(object.campus)) {
        allCampuses.push(object.campus);
      }
    });

    data?.forEach((object) => {
      if (!allEnrolmentNo.includes(object.enrolmentNo)) {
        allEnrolmentNo.push(object.enrolmentNo);
      }
    });

    setState((prevState) => ({
      ...prevState,
      allPrograms: allPrograms,
      allCampuses: allCampuses,
      allEnrolmentNo: allEnrolmentNo,
    }));

    // loadData();
  };
  useEffect(() => {
    loadData();
  }, [
    state.isOpen,
    state.expanded,
    state.background,
    state.searchFieldValue,
    state.allPrograms,
    state.allCampuses,
    state.allEnrolmentNo,
    state.firstRowIndex,
    state.lastRowIndex,
  ]);

  const loadData = () => {
    let tableRows = [];
    let rowItem = {};
    let data = state.enrolmentsData;
    let firstRowIndex = state.firstRowIndex;
    let lastRowIndex = state.lastRowIndex;

    data = searchItems(data);

    data = dataProcessor(data);

    let dataLength = data?.length;
    if (dataLength < parseInt(firstRowIndex) + 1) {
      firstRowIndex = 0;
      lastRowIndex = constants.NUMBEROFROWS - 1;
    }

    if (data !== "" && data !== null) {
      data.forEach((object, index) => {
        if (index >= firstRowIndex && index <= lastRowIndex) {
          rowItem.ImgSrc =
            "https://ui-avatars.com/api/name=" +
            object.studentName +
            "/?background=random";
          rowItem.Label1 = object.studentName;
          rowItem.DescriptionClm1 = " Student Number: " + object.studentNo;
          rowItem.Label2 = object.programName;
          let startDate = moment(object.startDate, "DD/MM/YYYY").format(
            "DD-MM-YYYY"
          );
          let endDate = moment(object.endDate, "DD/MM/YYYY").format(
            "DD-MM-YYYY"
          );
          rowItem["Campus"] = object.campus;
          rowItem["Start Date"] = startDate;
          rowItem["End Date"] = endDate;
          rowItem["Enrolment Number"] = object.enrolmentNo;
          rowItem.Value = "$" + currencyFormat(object.commission);
          tableRows.push({ ...rowItem });
        }
      });
    } else {
      setState((prevState) => ({ ...prevState, tableEmpty: "" }));
    }

    setState((prevState) => ({
      ...prevState,
      tableRows: tableRows,
      hidden: "hidden",
      isLoaded: false,
    }));
  };

  const dataProcessor = (data) => {
    let originalData = data;

    let programsSelectedList = state.programsSelectedList;
    let campusSelectedList = state.campusSelectedList;
    let enrolmentNoSelectedList = state.enrolmentNoSelectedList;

    if (programsSelectedList != "") {
      data = multiselectFilter(data, programsSelectedList, "programName");
    }
    if (campusSelectedList != "") {
      data = multiselectFilter(data, campusSelectedList, "campus");
    }
    if (enrolmentNoSelectedList != "") {
      data = multiselectFilter(data, enrolmentNoSelectedList, "enrolmentNo");
    }

    // following line of code will set dataLength
    setState((prevState) => ({
      ...prevState,
      dataLength: data?.length,
    }));
    return data;
  };

  const renderTable = () => {
    let tableHeadings = [
      "Student Details",
      "Program",
      "Enrolment Number",
      "Campus",
      "Start Date",
      "End Date",
      "Commission",
      "",
    ];
    let tableRows = state.tableRows;

    if (tableRows?.length >= 1) {
      return (
        <TableComponent
          tableHeadings={tableHeadings}
          tableRows={tableRows}
          filterOn={state.filterOn}
          numberOfElementsPerPage={constants.NUMBEROFROWS}
          dataLength={state.dataLength}
          setPageIndexFn={setPageIndexFn}
          firstRowIndex={state.firstRowIndex}
          lastRowIndex={state.lastRowIndex}
          clmTwoWidth={"w-1/4"}
        />
      );
    } else {
      return (
        <div
          className={
            state.tableEmpty + " text-gray-600 w-full flex justify-center mt-20"
          }
        >
          <h1>
            There is No Data Available to Display, please reset filters if any
            applied.
          </h1>
        </div>
      );
    }
  };

  const setPageIndexFn = (firstRowIndex, lastRowIndex, btn) => {
    setState((prevState) => ({
      ...prevState,
      firstRowIndex: firstRowIndex,
      lastRowIndex: lastRowIndex,
    }));
  };

  const searchHandeler = () => {
    var searchValue = document.getElementById("search").value;
    setState((prevState) => ({
      ...prevState,
      searchFieldValue: searchValue,
    }));
  };

  const searchItems = (data) => {
    let originalData = data;
    let searchValue = state.searchFieldValue;
    if (searchValue != "") {
      data = data.filter((object) =>
        object.studentName.toUpperCase().includes(searchValue.toUpperCase())
      );
      setState((prevState) => ({
        ...prevState,
        filterOn: true,
      }));
      if (data?.length === originalData?.length) {
        setState((prevState) => ({
          ...prevState,
          filterOn: false,
        }));
      }
    }

    setState((prevState) => ({
      ...prevState,
      dataLength: data?.length,
    }));
    return data;
  };

  const handleDateBox = (e, name) => {
    let selectedDate = e;

    let fromDate = new Date(dateFormatter.formatterThree(state.startDate));
    let toDate = new Date(dateFormatter.formatterThree(state.endDate));
    let sDate = new Date(dateFormatter.formatterThree(selectedDate));
    localStorage.setItem("dateRangeLabel5", "Custom Range");
    if (name === "FromDate") {
      let startDate = dateFormatter.formatterTwo(sDate);
      let endDate = dateFormatter.formatterTwo(toDate);
      localStorage.setItem("t5StartDate", startDate);
      setState((prevState) => ({
        ...prevState,
        startDate: startDate,
        endDate: endDate,
        isOpen: false,
        expanded: "",
        background: "hidden",
      }));
    } else if (name === "ToDate") {
      let startDate = dateFormatter.formatterTwo(fromDate);
      let endDate = dateFormatter.formatterTwo(sDate);
      localStorage.setItem("t5EndDate", endDate);
      setState((prevState) => ({
        ...prevState,
        startDate: startDate,
        endDate: endDate,
        isOpen: false,
        expanded: "",
        background: "hidden",
      }));
    }
  };
  useEffect(() => {
    if (state.startDate && state.endDate) {
      getEnrolmentCommission(state.startDate, state.endDate);
    }
  }, [state.startDate, state.isOpen, state.expanded, state.endDate]);
  const onMultiSelectChange = (selectedList, item, id) => {
    if (id === "program") {
      setState((prevState) => ({
        ...prevState,
        programsSelectedList: selectedList,
      }));
    } else if (id === "campus") {
      setState((prevState) => ({
        ...prevState,
        campusSelectedList: selectedList,
      }));
    } else if (id === "enrolmentNo") {
      setState((prevState) => ({
        ...prevState,
        enrolmentNoSelectedList: selectedList,
      }));
    }
    setState((prevState) => ({
      ...prevState,
      isOpen: false,
      expanded: "",
      background: "hidden",
    }));
  };

  const filtersDropdown = () => {
    let expanded = state.expanded;
    if (expanded) {
      setState((prevState) => ({
        ...prevState,
        isOpen: false,
        expanded: "",
        background: "hidden",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        isOpen: true,
        expanded: "yes",
        background: "",
      }));
    }
  };

  const renderAdvancedSearch = () => {
    return (
      <div>
        <div className="md:mt-5 mt-1 flex xl:justify-end">
          <span
            onClick={() => filtersDropdown()}
            className="  p-2 h-10 rounded-md bg-white border border-gray-300 text-gray-700 hover:bg-gray-200 cursor-pointer"
          >
            Advanced Search
          </span>
        </div>
        <Transition
          show={state.isOpen}
          enter="transition ease-in duration-300 transform"
          enterFrom="opacity-25 scale-0"
          enterTo="opacity-100 scale-100"
          leave="transition ease-out duration-500 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-25 scale-0"
        >
          <div className="origin-top right-0 absolute z-10 mb-8 w-full bg-gray-200">
            <div className="px-2 pt-4 lg:mt-0 flex-shrink-0 bg-gray-100 sm:rounded-lg border-b border-gray-200">
              <div className={"mx-4 mb-4 bg-gray-100"}>
                <div className="pb-4">
                  <div
                    className={" text-gray-600 w-full flex justify-center mb-4"}
                  >
                    <h1 className="font-semibold">Filters</h1>
                  </div>
                  <div className=" grid grid-cols-6 gap-6">
                    <div className="md:py-3 col-span-6 md:col-span-3 ">
                      <MultiSelection
                        id="program"
                        label="Select Programs"
                        selectedValues={state.programsSelectedList}
                        data={state.allPrograms}
                        onSelect={(selectedList, selectedItem) =>
                          onMultiSelectChange(
                            selectedList,
                            selectedItem,
                            "program"
                          )
                        }
                        onRemove={(selectedList, removedItem) =>
                          onMultiSelectChange(
                            selectedList,
                            removedItem,
                            "program"
                          )
                        }
                      />
                    </div>
                    <div className="md:py-3 col-span-6 md:col-span-3 ">
                      <MultiSelection
                        id="campus"
                        label="Select Campuses"
                        selectedValues={state.campusSelectedList}
                        onSelect={(selectedList, selectedItem) =>
                          onMultiSelectChange(
                            selectedList,
                            selectedItem,
                            "campus"
                          )
                        }
                        onRemove={(selectedList, removedItem) =>
                          onMultiSelectChange(
                            selectedList,
                            removedItem,
                            "campus"
                          )
                        }
                        data={state.allCampuses}
                        placeholder="Select"
                      />
                    </div>
                    <div className="md:py-3 col-span-6 md:col-span-3 ">
                      <MultiSelection
                        id="enrolmentNo"
                        label="Select Enrolment Number"
                        selectedValues={state.enrolmentNoSelectedList}
                        onSelect={(selectedList, selectedItem) =>
                          onMultiSelectChange(
                            selectedList,
                            selectedItem,
                            "enrolmentNo"
                          )
                        }
                        onRemove={(selectedList, removedItem) =>
                          onMultiSelectChange(
                            selectedList,
                            removedItem,
                            "enrolmentNo"
                          )
                        }
                        data={state.allEnrolmentNo}
                        placeholder="Select"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition>
        <div className="">
          <span
            onClick={() => filtersDropdown()}
            className={
              state.background +
              " z-6 fixed top-10 left-0 right-0 bottom-0 h-full w-full bg-gray-400 opacity-75"
            }
          />
        </div>
      </div>
    );
  };

  const renderFiltersPanel = (id) => {
    const dateFormatter = new DateFormatter();
    let startDate = dateFormatter.formatter(state.fromDate);
    let endDate = dateFormatter.formatter(state.toDate);
    return (
      <div className="pb-4">
        <div className={" text-gray-600 w-full flex justify-center mb-4"}>
          <h1 className="font-semibold">Filters</h1>
        </div>
        <div className="pt-6 grid grid-cols-1 lg:grid-cols-4 gap-6">
          <div className=""></div>
          <div className=""></div>
        </div>
      </div>
    );
  };

  return (
    <div className="relative mt-6 align-middle min-h-full">
      {/* <span
          className={
            state.hidden +
            " fixed top-10 left-0 right-0 bottom-0 h-full w-full bg-white opacity-25"
          }
        /> */}
      <Loader loaded={state.isLoaded} hidden={state.hidden}></Loader>
      <div className="flex rounded-md p-4 mb-4 md:mb-8 w-full grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-6 bg-gray-100">
        <div className="xl:col-span-2">
          <SearchBox
            id={"search"}
            label={"Search For Student Name"}
            svgType={"student"}
            placeholder={"Name"}
            value={state.searchFieldValue}
            searchHandler={() => {
              searchHandeler();
            }}
          />
        </div>

        <div className="xl:col-span-1">
          <DateBox
            dependentOn={true}
            id={"Pd1"}
            label="Enrolment Start Date"
            onChange={handleDateBox}
            name={"FromDate"}
            value={state.startDate ? new Date(state.startDate) : ""}
          />
        </div>
        <div className="xl:col-span-1">
          <DateBox
            dependentOn={true}
            id={"Pd1"}
            label="Enrolment End Date"
            onChange={handleDateBox}
            name={"ToDate"}
            value={state.endDate ? new Date(state.endDate) : ""}
          />
        </div>
        <div className="xl:col-span-2">{renderAdvancedSearch()}</div>
      </div>

      <div>{renderTable()}</div>
    </div>
  );
}

export default ActiveEnrolments;
