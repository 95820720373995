import React, { useState, useEffect } from "react";
import Loader from "./common/Loader";
import TableComponent from "./common/TableComponent";
import moment from "moment";
import DateBox from "./controls/DateBox";
import Transition from "./common/Transition";
import SearchBox from "./controls/SearchBox";
import * as constants from "../constants";
import { DateFormatter } from "./common/DateFormatter";
import { currencyFormat, multiselectFilter } from "./common/commonFns";
import MultiSelection from "./controls/MultiSelection";
import { AgentsRequests } from "../apiRequests/AgentsRequests";

const dateFormatter = new DateFormatter();
const agentsRequests = new AgentsRequests();

const FutureCommission = () => {
  const [hidden, setHidden] = useState("hidden");
  const [isLoaded, setIsLoaded] = useState(false);
  const [agentId, setAgentId] = useState(localStorage.getItem("agentId"));
  const [tableRows, setTableRows] = useState([]);
  const [tableEmpty, setTableEmpty] = useState("hidden");
  const [firstRowIndex, setFirstRowIndex] = useState(0);
  const [lastRowIndex, setLastRowIndex] = useState(constants.NUMBEROFROWS - 1);
  const [filterOn, setFilterOn] = useState(false);
  const [dataLength, setDataLength] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [expanded, setExpanded] = useState("");
  const [background, setBackground] = useState("hidden");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [commissionsDetailsData, setCommissionsDetailsData] = useState([]);
  const [commissionsDetailsDataCopy, setCommissionsDetailsDataCopy] = useState(
    []
  );

  const [allPrograms, setAllPrograms] = useState([]);
  const [allCampuses, setAllCampuses] = useState([]);
  const [allInvoiceNo, setAllInvoiceNo] = useState([]);
  const [campusSelectedList, setCampusSelectedList] = useState([]);
  const [programsSelectedList, setProgramsSelectedList] = useState([]);
  const [invoiceNoSelectedList, setInvoiceNoSelectedList] = useState([]);
  const [value, setValue] = useState("");

  const futureCommissionFn = (startDate, endDate) => {
    agentsRequests.agentCommissionDue(startDate, endDate).then((result) => {
      if (result !== "error") {
        setCommissionsDetailsData(result);
        setCommissionsDetailsDataCopy(result);
        setDataLength(result.length);
      } else {
        setTableEmpty("");
      }
    });
  };

  const loadMultiSelectData = () => {
    let updatedallPrograms = [];
    let updatedallCampuses = [];
    let updatedallInvoiceNo = [];

    let data = commissionsDetailsDataCopy;

    data.forEach((object) => {
      if (!updatedallPrograms.some((item) => object.program === item)) {
        updatedallPrograms.push(object.program);
      }
    });

    data.forEach((object) => {
      if (!updatedallCampuses.some((item) => object.campus === item)) {
        updatedallCampuses.push(object.campus);
      }
    });

    data.forEach((object) => {
      if (!updatedallInvoiceNo.some((item) => object.agentInvoiceNo === item)) {
        updatedallInvoiceNo.push(object.agentInvoiceNo);
      }
    });

    setAllPrograms(updatedallPrograms);
    setAllCampuses(updatedallCampuses);
    setAllInvoiceNo(updatedallInvoiceNo);
  };

  const loadData = () => {
    let updatedTableRows = [];
    let formatedRowData = {};
    let data = commissionsDetailsDataCopy;
    let updatedFirstRowIndex = firstRowIndex;
    let updatedLastRowIndex = lastRowIndex;
    data = dataProcessor(data);
    let dataLength = data.length;
    if (dataLength < parseInt(updatedFirstRowIndex) + 1) {
      updatedFirstRowIndex = 0;
      updatedLastRowIndex = constants.NUMBEROFROWS - 1;
    }

    if (data != "" && data != null) {
      data.map((object, index) => {
        if (index >= updatedFirstRowIndex && index <= updatedLastRowIndex) {
          formatedRowData.ImgSrc =
            "https://ui-avatars.com/api/name=" +
            object.studentName +
            "/?background=random";
          formatedRowData.Label1 = object.studentName;
          formatedRowData.DescriptionClm1 =
            "Student No: " +
            object.studentNo +
            " Enrolment No: " +
            object.enrolmentNo;
          formatedRowData.Label2 = object.program;
          formatedRowData["Campus"] = object.campus;
          formatedRowData["Invoice Number"] = object.agentInvoiceNo;
          let invoiceDate = moment(object.invoiceDate, "DD/MM/YYYY").format(
            "DD-MM-YYYY"
          );
          formatedRowData["Invoice Date"] = invoiceDate;
          formatedRowData.Value = "$" + currencyFormat(object.totalCommission);

          updatedTableRows.push({ ...formatedRowData });
        }
      });
    } else {
      setTableEmpty("");
    }
    setTableRows(updatedTableRows);
    setHidden("hidden");
    setIsLoaded(false);
  };

  const renderTable = () => {
    let tableHeadings = [
      "Student Details",
      "Program",
      "Campus",
      "Invoice Number",
      "Invoice Date",
      "Commission",
      "",
    ];
    let updatedTableRows = tableRows;

    if (updatedTableRows.length >= 1) {
      return (
        <TableComponent
          tableHeadings={tableHeadings}
          tableRows={updatedTableRows}
          filterOn={filterOn}
          numberOfElementsPerPage={constants.NUMBEROFROWS}
          dataLength={dataLength}
          setPageIndexFn={setPageIndexFn}
          firstRowIndex={firstRowIndex}
          lastRowIndex={lastRowIndex}
          clmTwoWidth={"w-1/4"}
        />
      );
    } else {
      return (
        <div
          className={
            tableEmpty + " text-gray-600 w-full flex justify-center mt-20"
          }
        >
          <h1>
            There is No Data Available to Display, please reset filters if any
            applied.
          </h1>
        </div>
      );
    }
  };

  const setPageIndexFn = (firstRowIndex, lastRowIndex) => {
    setFirstRowIndex(firstRowIndex);
    setLastRowIndex(lastRowIndex);
    loadData();
  };

  const searchHandeler = () => {
    const updatedsearchValue = document.getElementById("search").value;
    let data = commissionsDetailsData;
    setValue(updatedsearchValue);

    if (updatedsearchValue.length >= 0) {
      data = data.filter((object) =>
        object.studentName
          .toUpperCase()
          .includes(updatedsearchValue.toUpperCase())
      );
    }
    if (data.length === 0) {
      setTableEmpty("");
    } else {
      setTableEmpty("hidden");
    }
    setCommissionsDetailsDataCopy(data);
    loadMultiSelectData();
  };

  const dataProcessor = (data) => {
    const updatedprogramsSelectedList = programsSelectedList;
    const updatedcampusSelectedList = campusSelectedList;
    const updatedinvoiceNoSelectedList = invoiceNoSelectedList;

    if (programsSelectedList.length > 0) {
      data = multiselectFilter(data, updatedprogramsSelectedList, "program");
    }
    if (campusSelectedList.length > 0) {
      data = multiselectFilter(data, updatedcampusSelectedList, "campus");
    }
    if (invoiceNoSelectedList.length > 0) {
      data = multiselectFilter(
        data,
        updatedinvoiceNoSelectedList,
        "agentInvoiceNo"
      );
    }

    setDataLength(data.length);
    return data;
  };

  const filtersDropdown = () => {
    const updatedexpanded = expanded;
    if (updatedexpanded) {
      setIsOpen(false);
      setExpanded("");
      setBackground("hidden");
    } else {
      setIsOpen(true);
      setExpanded("yes");
      setBackground("");
    }
  };

  const handleDateBox = (e, name) => {
    let selectedDate = e;

    let updatedfromDate = new Date(dateFormatter.formatterThree(fromDate));
    let updatedtoDate = new Date(dateFormatter.formatterThree(toDate));
    let updatedsDate = new Date(dateFormatter.formatterThree(selectedDate));
    localStorage.setItem("dateRangeLabel7", "Custom Range");
    if (name === "FromDate") {
      let startDate = dateFormatter.formatterTwo(updatedsDate);
      let endDate = dateFormatter.formatterTwo(toDate);
      localStorage.setItem("t7StartDate", startDate);
      setFromDate(selectedDate);
      setIsOpen(false);
      setExpanded("");
      setBackground("hidden");
      futureCommissionFn(startDate, endDate);
    } else if (name === "ToDate") {
      let startDate = dateFormatter.formatterTwo(updatedfromDate);
      let endDate = dateFormatter.formatterTwo(updatedtoDate);
      localStorage.setItem("t7EndDate", updatedsDate);
      setToDate(selectedDate);
      setIsOpen(false);
      setExpanded("");
      setBackground("hidden");
      futureCommissionFn(startDate, endDate);
    }
  };

  const onMultiSelectChange = (selectedList, item, id) => {
    if (id === "program") {
      setProgramsSelectedList(selectedList);
    } else if (id === "campus") {
      setCampusSelectedList(selectedList);
    } else if (id === "invoiceNo") {
      setInvoiceNoSelectedList(selectedList);
    }
    setIsOpen(false);
    setExpanded("");
    setBackground("hidden");
    loadData();
  };

  const renderAdvancedSearch = () => {
    return (
      <div>
        <div className="md:mt-5 mt-1 flex xl:justify-end">
          <span
            onClick={() => filtersDropdown()}
            className="p-2 rounded-md bg-white border border-gray-300 text-gray-700 hover:bg-gray-200 cursor-pointer"
          >
            Advanced Search
          </span>
        </div>
        <Transition
          show={isOpen}
          enter="transition ease-in duration-300 transform"
          enterFrom="opacity-25 scale-0"
          enterTo="opacity-100 scale-100"
          leave="transition ease-out duration-500 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-25 scale-0"
        >
          <div className="origin-top right-0 absolute z-10 mb-8 w-full bg-gray-200">
            <div className="px-2 pt-4 lg:mt-0 flex-shrink-0 bg-gray-100 sm:rounded-lg border-b border-gray-200">
              <div className={"mx-4 mb-4 bg-gray-100"}>
                <div className="pb-4">
                  <div
                    className={" text-gray-600 w-full flex justify-center mb-4"}
                  >
                    <h1 className="font-semibold">Filters</h1>
                  </div>
                  <div className=" grid grid-cols-6 gap-6">
                    <div className="md:py-3 col-span-6 md:col-span-3 ">
                      <MultiSelection
                        id="program"
                        label="Select Programs"
                        selectedValues={programsSelectedList}
                        data={allPrograms}
                        onSelect={(selectedList, selectedItem) =>
                          onMultiSelectChange(
                            selectedList,
                            selectedItem,
                            "program"
                          )
                        }
                        onRemove={(selectedList, removedItem) =>
                          onMultiSelectChange(
                            selectedList,
                            removedItem,
                            "program"
                          )
                        }
                      />
                    </div>
                    <div className="md:py-3 col-span-6 md:col-span-3 ">
                      <MultiSelection
                        id="campus"
                        label="Select Campuses"
                        selectedValues={campusSelectedList}
                        onSelect={(selectedList, selectedItem) =>
                          onMultiSelectChange(
                            selectedList,
                            selectedItem,
                            "campus"
                          )
                        }
                        onRemove={(selectedList, removedItem) =>
                          onMultiSelectChange(
                            selectedList,
                            removedItem,
                            "campus"
                          )
                        }
                        data={allCampuses}
                        placeholder="Select"
                      />
                    </div>
                    <div className="md:py-3 col-span-6 md:col-span-3 ">
                      <MultiSelection
                        id="invoiceNo"
                        label="Select Invoice Number"
                        selectedValues={invoiceNoSelectedList}
                        onSelect={(selectedList, selectedItem) =>
                          onMultiSelectChange(
                            selectedList,
                            selectedItem,
                            "invoiceNo"
                          )
                        }
                        onRemove={(selectedList, removedItem) =>
                          onMultiSelectChange(
                            selectedList,
                            removedItem,
                            "invoiceNo"
                          )
                        }
                        data={allInvoiceNo}
                        placeholder="Select"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition>
        <div className="">
          <span
            onClick={() => filtersDropdown()}
            className={
              background +
              " z-6 fixed top-10 left-0 right-0 bottom-0 h-full w-full bg-gray-400 opacity-75"
            }
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    loadMultiSelectData();
    setIsLoaded(true);
  }, [commissionsDetailsDataCopy, commissionsDetailsData, dataLength]);

  useEffect(() => {
    loadData();
  }, [allPrograms, allCampuses, allInvoiceNo]);

  useEffect(() => {
    setHidden("");
    setIsLoaded(true);
    let startDate = localStorage.getItem("t7StartDate");
    let endDate = localStorage.getItem("t7EndDate");
    setFromDate(startDate);
    setToDate(endDate);
    futureCommissionFn(startDate, endDate);
  }, []);

  return (
    <div className="relative mt-6 align-middle min-h-full">
      <span
        className={
          hidden +
          " fixed top-10 left-0 right-0 bottom-0 h-full w-full bg-white opacity-25"
        }
      />
      <Loader loaded={isLoaded} id={"futurecommission"} hidden={hidden} />

      <div className="flex rounded-md p-4 mb-4 md:mb-8 w-full grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-6 bg-gray-100">
        <div className="xl:col-span-2">
          <SearchBox
            id={"search"}
            label={"Search Student Name"}
            svgType={"student"}
            placeholder={"Name"}
            value={value}
            searchHandler={() => {
              searchHandeler();
            }}
          />
        </div>

        <div className="xl:col-span-1">
          <DateBox
            dependentOn={true}
            id={"Pd1"}
            label="Invoice Date From"
            onChange={handleDateBox}
            name={"FromDate"}
            value={fromDate ? new Date(fromDate) : ""}
          />
        </div>
        <div className="xl:col-span-1">
          <DateBox
            dependentOn={true}
            id={"Pd1"}
            label="Invoice Date Till"
            //minDate={moment().add(0, "days").toDate()}
            onChange={handleDateBox}
            name={"ToDate"}
            value={toDate ? new Date(toDate) : ""}
          />
        </div>
        <div className="xl:col-span-2">{renderAdvancedSearch()}</div>
      </div>

      <div>{renderTable()}</div>
    </div>
  );
};

export default FutureCommission;
