import React, { useState } from 'react'
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "../assets/css/daterangepicker.css";

const DateRangePickerDropdown = (props) => {
       
    const [state, setState] = useState({
        DateRnBkgrd: "hidden",
        startDate: "",
        endDate: "",
        focusedInput: null,
        allRanges: {
            "This Month": [
            moment().startOf("month").toDate(),
            moment().endOf("month").toDate(),
            ],
            "Last Month": [
            moment().subtract(1, "month").startOf("month").toDate(),
            moment().subtract(1, "month").endOf("month").toDate(),
            ],
            "Next Month": [
            moment().add(1, "month").startOf("month").toDate(),
            moment().add(1, "month").endOf("month").toDate(),
            ],
            "This Quarter": [
            moment().quarter(moment().quarter()).startOf("quarter"),
            moment().quarter(moment().quarter()).endOf("quarter"),
            ],
            "Last Quarter": [
            moment().subtract(1, "quarter").startOf("quarter"),
            moment().subtract(1, "quarter").endOf("quarter"),
            ],
            "Next Quarter": [
            moment().add(1, "quarter").startOf("quarter"),
            moment().add(1, "quarter").endOf("quarter"),
            ],
            "This Year": [
            moment().startOf("year").toDate(),
            moment().endOf("year").toDate(),
            ],
            "Last Year": [
            moment().subtract(1, "year").startOf("year").toDate(),
            moment().subtract(1, "year").endOf("year").toDate(),
            ],
            "Next Year": [
            moment().add(1, "year").startOf("year").toDate(),
            moment().add(1, "year").endOf("year").toDate(),
            ],
        },
    })

  return (
    <div>
        <div>
          <DateRangePicker
            initialSettings={{
              startDate: props.startDate,
              endDate: props.endDate,
              showDropdowns: true,
              showCustomRangeLabel: true,
              linkedCalendars: false,
              drops: "down",
              opens: "right",
              locale: { format: "DD/MMM/YYYY" },
              // opens: right,
              // drops: down,
              ranges: state.allRanges,
            }}
            onCallback={props.handleCallback}
            onShow={props.onShow}
            onHide={props.onHide}
          >
            <button className="text-sm items-center justify-center border-2 border-transparent hover:border-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
              <svg
                className="w-4 h-4 fill-current text-gray-600 hover:text-blue-700"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </DateRangePicker>
        </div>
      </div>
  )
}

export default DateRangePickerDropdown