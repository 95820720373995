import React, { Component } from "react";

function Avatar() {
  let name = localStorage.getItem("agentName");

  return (
    <React.Fragment>
      <img
        className="hidden lg:block h-10 w-10 rounded-full"
        src={"https://ui-avatars.com/api/name=" + name + "/?background=random"}
        alt=""
      />

      <img
        className="block lg:hidden h-8 w-8 rounded-full"
        src={"https://ui-avatars.com/api/name=" + name + "/?background=random"}
        alt=""
      />
    </React.Fragment>
  );
}

export default Avatar;
