// Here we have importer constants and axios for the http request handling.
// Then we have created a constant config  with request header and made
// a get request by passing this constant which will return a token and
// student id or error in response.

import axios from "axios";
import * as constants from "../constants";

export function PostData(type, userData) {
  //let BaseUrl = "https://reqres.in/api/"; //" https://my-json-server.typicode.com/vkkmehra/TLAPI/";
  //   return new Promise((resolve, reject) => {
  const config = {
    headers: { "Content-Type": "application/json" },
    params: userData,
  };
  return axios
    .get(constants.BASEURL + type, config)
    .then(function (responseJson) {
      return responseJson.data;
    })
    .catch(function (error) {
      console.log(error);
    });

  //   fetch(BaseUrl + type, {
  //   method: "POST",
  //   headers: { "Content-Type": "application/json" },
  //   body: JSON.stringify(userData)
  // })
  //   .then(response => response.json())
  //   .then(responseJson => {
  //     resolve(responseJson);
  //   })
  //   .catch(error => {
  //     console.error(error);
  //   });
  //   });
}
