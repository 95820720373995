import React, { useState } from "react";
import Avatar from "./Avatar";
import logo from "../../assets/images/clientlogo.png";
import Transition from "./Transition";
import { Link } from "react-router-dom";

const NavBar = ({ avatarurl }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hidden, setHidden] = useState(false);
  const aName = localStorage.getItem("agentName");

  const handleClick = () => {
    if (isOpen) {
      setIsOpen(false);
      setHidden(false);
    } else {
      setIsOpen(true);
      setHidden(true);
    }
  };

  const optionSelection = (option) => {
    if (option === "request") {
      setIsOpen(false);
      setHidden(false);
    }
  };
  return (
    <nav className="z-30 relative bg-white border-b border-gray-200">
      <div className=" mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center">
              <Link
                to="/dashboard"
                onClick={() => {
                  optionSelection("request");
                }}
                className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100"
              >
                <img
                  className="block lg:hidden h-8 w-auto"
                  src={logo}
                  alt="Workflow logo"
                />
                <img
                  className="hidden lg:block h-8 w-auto"
                  src={logo}
                  alt="Workflow logo"
                />
              </Link>
            </div>
          </div>
          <div className="flex">
            <div className="flex-shrink-0 flex items-center text-xl font-bold">
              {localStorage.getItem("agentName")}
            </div>
          </div>
          <div className="hidden sm:ml-6 sm:flex sm:items-center">
            <div className="ml-3 relative">
              <div>
                <button
                  className="flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition duration-150 ease-in-out hover:border-gray-300"
                  id="user-menu"
                  aria-label="User menu"
                  aria-haspopup="true"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  <Avatar
                    url={
                      avatarurl == ""
                        ? "https://ui-avatars.com/api/name=" +
                        aName +
                        "/?background=random"
                        : avatarurl
                    }
                  />
                </button>
              </div>
              <span
                onClick={() => {
                  optionSelection("request");
                }}
                className={
                  (!hidden ? "hidden" : "") +
                  "fixed top-10 left-0 right-0 bottom-0 h-full w-full bg-black opacity-50"
                }
              />
              <Transition
                show={isOpen}
                enter="transition ease-out duration-200 transform"
                enterFrom="opacity-0 scale-50"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-200 transform"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-50"
              >
                <div
                  id="dropDown"
                  className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu"
                >
                  <div className="py-2 pl-2 text-left bg-gray-100 sm:border-b sm:border-double sm:border-dashed">
                    <div className="text-base font-medium leading-6 text-gray-800">
                      {aName}
                    </div>
                    <div className="text-sm font-medium leading-5 text-gray-500"></div>
                  </div>

                  <div className={"space-y-6 sm:space-y-5 "}>
                    <div className="sm:border-t sm:border-dashed sm:border-gray-200 ">
                      <Link
                        to="/"
                        onClick={() => {
                          optionSelection("request");
                        }}
                        className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                      >
                        Sign out
                      </Link>
                    </div>
                  </div>
                </div>
              </Transition>
            </div>
          </div>
          <div className="-mr-2 flex items-center sm:hidden">
            {/* Mobile menu button */}
            <button
              onClick={() => {
                handleClick();
              }}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
            >
              <svg
                className="block h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              {/* Menu open: "block", Menu closed: "hidden" */}
              <svg
                className="hidden h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <Transition
        show={isOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="block sm:hidden">
          <div className="pt-4 pb-3 border-t border-gray-200">
            <div className="flex items-center px-4">
              <div className="flex-shrink-0"></div>
              <Avatar
                url={
                  avatarurl == ""
                    ? "https://ui-avatars.com/api/name=" +
                    aName +
                    "/?background=random"
                    : avatarurl
                }
              />
              <div className="ml-3">
                <div className="text-base font-medium leading-6 text-gray-800">
                  {aName}
                </div>
                <div className="text-sm font-medium leading-5 text-gray-500"></div>
              </div>
            </div>
            <div
              className="mt-3"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-menu"
            >
              <Link
                to="/"
                onClick={() => {
                  optionSelection("request");
                }}
                className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
              >
                Sign out
              </Link>
            </div>
          </div>
        </div>
      </Transition>
    </nav>
  );
};

export default NavBar;
