import React from "react";
import FormLabel from "./FormLabel";
import { useState } from "react";

const TextAreaBox = ({
    dependentOn,
    error,
    label,
    name,
    value,
    onChange,
    onClick,
    onBlur,
    readOnly,
    rows,
    type
}) => {
    return (
        <div
            className={"col-span-6 sm:col-span-2" + (!dependentOn ? " hidden" : "")}
        >
            <FormLabel text={label} name={name} color={error ? "red" : "black"} />
            <div className="mt-1 relative rounded-md shadow-sm">
                {/* border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red */}


                <textarea


                    className={
                        "mt-1 form-input block w-full py-2 px-3 " +
                        (error
                            ? "border-red-300 text-red-900 rounded-md shadow-sm placeholder-red-300 focus:outline-none focus:shadow-outline-red focus:border-red-300"
                            : "border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300") +
                        " transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    }
                    id={name}
                    rows={rows}
                    name={name}
                    onClick={onClick}
                    onChange={onChange}
                    onBlur={onBlur}
                    // value={value}
                    readOnly={readOnly}
                >
                    {value}
                </textarea>

                {error && (
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none hidden">
                        <svg
                            className="h-5 w-5 text-red-500"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                        >
                            <path
                                fillRule="evenodd"
                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </div>
                )}
            </div>
            {error && (
                <p className="mt-2 text-sm text-red-600" id="email-error">
                    {label} must be entered.
                </p>
            )}
        </div>
    );
};

export default TextAreaBox;
