import React from 'react';

import Select from 'react-select';
import FormLabel from './FormLabel';

export default function MultiSelectBox({ error, data, dependentOn, displayValue, name, label, selectedValue, onChange }) {
    return (
        <div className={"col-span-6 sm:col-span-2" + (!dependentOn ? " hidden" : "")}>
            <FormLabel text={label} name={name} color={(error ? "red" : "black")} />
            {/* <Multiselect options={data}
                showCheckbox={true}
                selectedValues={selectedValue}
                onSelect={(selectedArr, selectedObject) => onChange(selectedArr, name)}
                onRemove={(selectedArr, selectedObject) => onChange(selectedArr, name)}
                displayValue={displayValue}
                placeholder={label}
            /> */}
            <div className="mt-1 relative rounded-md shadow-sm">
                <Select
                    // defaultValue={[colourOptions[2], colourOptions[3]]}
                    menuPortalTarget={document.body}
                    selectedValue="avetCodeId"
                    getOptionLabel={option => `${option.avetCode} - ${option.avetDescription}`}
                    // getOptionValue={option => option.avetCodeId}
                    isMulti
                    name={name}
                    onChange={onChange}
                    options={data}
                    className={(error ? "border-red-300 text-red-900" : "")}

                // className="basic-multi-select"
                // classNamePrefix="select"
                />
                {error &&
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <svg className="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                        </svg>
                    </div>}
            </div>
            { error && <p className="mt-2 text-sm text-red-600" id="email-error">{label} must be selected.</p>}
        </div >
    )
}