import React, { useEffect, useState } from "react";
import moment from "moment";
import Axios from "axios";
import SelectBox from "../controls/SelectBox";
import DateBox from "../controls/DateBox";
import TextBox from "../controls/TextBox";
import FormSection from "../controls/FormSection";
import * as constants from "../../constants";
const blankCourse = {
  Campus: null,
  Intake: null,
  StartDate: null,
  EndDate: null,
  StudyWeeks: null,
  HolidayWeeks: null,
  Program: null,
  Subject: null,
  UOS: null,
  error: {},
};
let controlIndex = 0;

function CourseSection(props) {
  const [state, setState] = useState({
    hideActionButton: false,
    currentCourse: {},
    courses: [],
  });

  const addCourse = (index) => {
    const { courseType, program } = props;
    const cCourse = { ...blankCourse };
    cCourse.error = {};
    cCourse.Program = props.courseType !== 9 ? program : null;
    if (courseType === 22) cCourse.Subject = null;
    if (courseType === 23) cCourse.UOS = null;
    const courses = [...state.courses, cCourse];
    loadDefaults(cCourse, index);
    setState((prevState) => {
      return { ...prevState, courses: courses };
    });
    props.onChange(courses);
  };

  // Following code is to remove Course section from an index on pressin [-] button
  const removeCourse = (index) => {
    const courses = [...state.courses];
    courses.splice(index, 1);
    setState((prevState) => {
      return { ...prevState, courses: courses };
    });
    props.onChange(courses);
  };

  // following section will assign default values to the selection boxes
  const loadDefaults = (course, index) => {
    const { intakes, campuses, courseType, campusObjects } = props;
    const intakeData = [...intakes];

    if (campuses?.length === 1) {
      course["Campus"] = campuses[0].campusId;
    }

    let objects = [...campusObjects];
    if (course?.Campus && parseInt(course?.Campus) > 0) {
      // following code is filtering CampusIds
      objects = objects.filter((p) => {
        let campusIds = "," + p.campusIds + ",";
        if (
          campusIds.indexOf(",-1,") >= 0 ||
          campusIds.indexOf("," + course.Campus + ",") >= 0
        )
          return p;
      });
    } else {
      objects = [];
    }

    if (courseType === 9) {
      if (objects.length === 1) course.Program = objects[0].objectId;
    } else if (courseType === 22) {
      if (objects.length === 1) course.Subject = objects[0].objectId;
    } else if (courseType === 23) {
      if (objects.length === 1) course.UOS = objects[0].objectId; //UOS -> Unit of Study
    }

    // course.map((course) => {
    if (
      (!course.Intake || course.Intake === 0 || course.Intake === "") &&
      parseInt(course.Program) > 0 &&
      parseInt(course.Campus) > 0
    ) {
      let intData = intakeData.filter((i) => i.programId === +course.Program);

      if (intData.length === 1) {
        course.Intake = intData[0].intakeId;
        if (
          (campuses.length === 1 && courseType === 9 && objects.length === 1) ||
          (campuses.length === 1 && courseType !== 9)
        )
          setState((prevState) => ({ ...prevState, hideActionButton: true }));
        const currentIntake = intakeData.filter(
          (i) => i.intakeId === course.Intake
        )[0];

        const rollingIntake = currentIntake && currentIntake.rollingIntake;
        if (!rollingIntake) {
          if (currentIntake?.startDate) {
            course.StartDate = new Date(currentIntake?.startDate);
            calculateCourseEndDate(course, index);
          }
        } else {
          if (
            window.webformconfigs &&
            window.webformconfigs.makeCurrentDateStartDate
          ) {
            course.StartDate = new Date();
            calculateCourseEndDate(course, index);
          }
        }
      }
    }
    // });
  };

  // following code will be to  generate Program/Selection/UOS section and populate data
  const renderObject = (course, index) => {
    const { courseType, campusObjects } = props;
    const { error } = course;
    let objects = [...campusObjects];
    // objects will be used to filters Programs or Courses
    //accourding to selected campus and use it

    if (parseInt(course.Campus) > 0) {
      objects = objects.filter((p) => {
        let campusIds = "," + p.campusIds + ",";
        if (
          campusIds.indexOf(",-1,") >= 0 ||
          campusIds.indexOf("," + course.Campus + ",") >= 0
        )
          return p;
      });
    } else {
      objects = [];
    }

    objects.map(
      (o) => (o.objectDetail = o.objectCode.trim() + " - " + o.objectName)
    );

    if (courseType === 9) {
      if (objects.length === 1) course.Program = objects[0].objectId;

      return (
        <SelectBox
          key={"program" + index}
          id={index + "Program"}
          disabled={objects.length === 1}
          error={error.Program}
          name={"Program"}
          label="Program*"
          onChange={handleChange}
          data={objects}
          idField={"objectId"}
          valueField={"objectDetail"}
          multiple={index}
          value={course["Program"]}
          dependentOn={true}
        />
      );
    } else if (courseType === 22) {
      if (objects.length === 1) course.Subject = objects[0].objectId;
      return (
        <SelectBox
          key={"subjects" + index}
          id={index + "Subject"}
          multiple={index}
          disabled={objects.length === 1}
          error={error.Subject}
          name="Subject"
          label="Subjects"
          onChange={handleChange}
          data={objects}
          idField="objectId"
          valueField="objectDetail"
          value={course["Subject"]}
          dependentOn={true}
        />
      );
    } else if (courseType === 23) {
      if (objects.length === 1) course.UOS = objects[0].objectId;
      return (
        <SelectBox
          key={"UOS" + index}
          id={index + "UOS"}
          multiple={index}
          disabled={objects.length === 1}
          error={error.UOS}
          name="UOS"
          label="UOS"
          onChange={handleChange}
          data={objects}
          idField="objectId"
          valueField="objectDetail"
          value={course["UOS"]}
          dependentOn={true}
        />
      );
    }
  };

  // follwing code will render the add (+) or remove (-) buttons for new cource application
  const renderActionButtons = (index) => {
    return (
      <div className="ml-4 mt-2 flex-shrink-0">
        <span className="inline-flex rounded-md shadow-sm mr-2">
          {index === 0 ? (
            <button
              key={index}
              type="button"
              onClick={() => addCourse(index)}
              className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700"
            >
              +
            </button>
          ) : (
            <button
              type="button"
              onClick={() => removeCourse(index)}
              className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700"
            >
              -
            </button>
          )}
        </span>
      </div>
    );
  };

  //Following function is called on onChange of Selections/Dropdowns and
  // populate with data and update this.state.courses
  const handleChange = (e, name) => {
    const { intakes } = props;
    var currentCourse = state.currentCourse; // createda replica of currentCourse for local use

    let controlName = ""; // created a variable which will store name of the control i.e. Campus/Program etc.
    if (name) {
      var index = name.slice(0, 1); // will calculate index of control on onchange
      controlIndex = index;
      name = name.slice(1);
      currentCourse[name] = e;
      controlName = name;
    } else {
      var targetID = e.currentTarget.id;
      var index = targetID.slice(0, 1); // will calculate index of control on onchange
      controlIndex = index;
      currentCourse[e.currentTarget.name] = e.currentTarget.value; // Assigning selected value to control
      controlName = e.currentTarget.name; // Assigning name of control to controlName
    }

    if (controlName === "Campus") {
      currentCourse.Subject = null;
      currentCourse.UOS = null;
      currentCourse.Program = "";
      currentCourse.EndDate = "";
      currentCourse.StartDate = null;
      currentCourse.Intake = "";
      currentCourse.StudyWeeks = "";
      currentCourse.HolidayWeeks = "";
      currentCourse.error = {};
      loadDefaults(currentCourse, controlIndex);
      updateCourseObject(currentCourse, controlIndex);
    } else if (controlName === "Program") {
      currentCourse.EndDate = "";
      currentCourse.StartDate = null;
      currentCourse.Intake = "";
      currentCourse.StudyWeeks = "";
      currentCourse.HolidayWeeks = "";
      currentCourse.error = {};

      loadDefaults(currentCourse, controlIndex);
      updateCourseObject(currentCourse, controlIndex);
    } else if (controlName === "Intake") {
      currentCourse.EndDate = "";
      currentCourse.StartDate = "";
      currentCourse.StudyWeeks = "";
      currentCourse.HolidayWeeks = "";
      currentCourse.error = {};
      let intakeData = [...intakes];

      if (parseInt(currentCourse.Intake) > 0) {
        const currentIntake = intakeData.filter(
          (i) => i.intakeId === currentCourse.Intake
        )[0];

        const rollingIntake = currentIntake && currentIntake.rollingIntake;

        loadDefaults(currentCourse, controlIndex);
        if (!rollingIntake) {
          // currentCourse.StartDate = currentIntake.startDate;
          currentCourse.StartDate = currentIntake?.startDate;
          // currentCourse.StartDate = (new Date(sd.getTime() - (sd.getTimezoneOffset() * 60000)))

          calculateCourseEndDate(currentCourse, controlIndex);
        } else {
          updateCourseObject(currentCourse, controlIndex);
        }
      } else {
        loadDefaults(currentCourse, controlIndex);
        updateCourseObject(currentCourse, controlIndex);
      }
    } else if (controlName === "StartDate") {
      currentCourse.EndDate = "";
      currentCourse.error = {};
      calculateCourseEndDate(currentCourse, controlIndex);
    } else {
      updateCourseObject(currentCourse, controlIndex);
    }
  };

  const updateCourseObject = (currentCourse) => {
    var ccourse = { ...currentCourse };
    const keys = Object.keys(ccourse); // Getting all the keys of currentCourse
    const { courseType } = props; //Getting the value of course type from props
    keys.map(function (key) {
      if (!currentCourse) {
        currentCourse = state;
      }
      if (key !== "error" && key !== "HolidayWeeks") {
        if (courseType === 9 && (key === "UOS" || key === "Subject")) {
          currentCourse.error[key] = false;

          return;
        }
        if (courseType === 22 && key === "UOS") {
          currentCourse.error[key] = false;
          return;
        }

        if (courseType === 23 && key === "Subject") {
          currentCourse.error[key] = false;
          return;
        }

        if (
          (currentCourse[key] === null || currentCourse[key] === "") &&
          currentCourse.error[key] === undefined
        )
          return;
        if (
          (currentCourse[key] === null || currentCourse[key] === "") &&
          currentCourse.error[key]
        ) {
          currentCourse.error[key] = true;
          return;
        }

        if (
          currentCourse[key].constructor === Number().constructor &&
          currentCourse[key] > 0
        ) {
          currentCourse.error[key] = false;
          return;
        }
        if (
          currentCourse[key].constructor === String().constructor &&
          currentCourse[key].trim() !== "" &&
          currentCourse[key].trim() !== "0"
        ) {
          //currentCourse.error[key] = false;
          return;
        }

        if (currentCourse[key].constructor === new Date().constructor) {
          currentCourse.error[key] = false;
          return;
        }
        currentCourse.error[key] = true;
      }
    }, this);

    let courses = [...state.courses];
    courses[controlIndex] = { ...currentCourse };
    setState((prevState) => {
      return { ...prevState, courses: courses };
    });
    props.onChange(courses);
    // this.props.onChange(this.state.courses);
  };

  const calculateCourseEndDate = async (course, index) => {
    Axios.get(constants.BASEURL + "WebForms/getProgramInfo", {
      params: {
        CollegeID: constants.COLLEGEID,
        Program: course.Program,
        Campus: course.Campus,
        Intake: course.Intake,
        StartDate: course.StartDate,
      },
    })
      .then((response) => {
        const result = response.data;
        let sd = new Date(course.StartDate);
        course.StartDate = new Date(
          sd.getTime() - sd.getTimezoneOffset() * 60000
        );
        let ed = new Date(result.endDate);
        course.EndDate = new Date(
          ed.getTime() - ed.getTimezoneOffset() * 60000
        );
        course.StudyWeeks = result.studyWeeks;
        course.HolidayWeeks = result.holidayWeeks;

        // let courses = [...this.state.courses];
        // courses[index] = { ...course };
        // this.setState({ courses: courses },this.props.onChange(courses));
        updateCourseObject(course, index);
        // this.setState({ courses: courses });
        // this.props.onChange(this.state.courses);
        // this.props.onChange(courses);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // following code renders the couse Section of form
  const renderCourse = (course, index) => {
    if (Object.keys(course).length > 0) {
      controlIndex = index;

      const { error } = course;
      const { campuses, intakes, allowMultiplePrograms } = props;
      let intakeData = [];
      let rollingIntake = false;

      if (course.Program && parseInt(course.Program) > 0) {
        intakeData = [...intakes];
        intakeData = intakeData.filter((i) => i.programId === +course.Program);
      }

      if (course.Intake && parseInt(course.Intake) > 0) {
        const currentIntake = intakeData.filter(
          (i) => i.intakeId === course.Intake
        )[0];
        rollingIntake = currentIntake && currentIntake.rollingIntake;

        if (!rollingIntake && currentIntake?.startDate) {
          let sd = new Date(currentIntake?.startDate);
          course.StartDate = new Date(
            sd.getTime() - sd.getTimezoneOffset() * 60000
          );
          // this.calculateCourseEndDate(course)
        }
      }

      return (
        <>
          <div
            className={
              "col-span-6 sm:col-span-6 " +
              (state.hideActionButton ? "hidden" : "")
            }
          >
            <div className="flex justify-between items-center flex-wrap sm:flex-no-wrap">
              {allowMultiplePrograms && (
                <>
                  <h3 className="text-base leading-6 font-medium text-gray-900">
                    {window.webformconfigs &&
                    window.webformconfigs.courseLabelToShow &&
                    window.webformconfigs.courseLabelToShow !== ""
                      ? window.webformconfigs.courseLabelToShow
                      : "Course No." + (index + 1)}
                  </h3>
                  {renderActionButtons(index, course.Program)}
                </>
              )}
            </div>
          </div>
          <SelectBox
            key={"campus" + index}
            id={index + "Campus"}
            name={"Campus"}
            disabled={campuses.length === 1}
            label="Campus*"
            idField={"campusId"}
            valueField={"campusDetail"}
            error={error.Campus}
            onChange={handleChange}
            data={campuses}
            multiple={index}
            value={course["Campus"]}
            dependentOn={
              window.webformconfigs &&
              window.webformconfigs.defaultWebFormCampus > 0
                ? false
                : true
            }
          />
          {renderObject(state.courses[index], index)}
          <SelectBox
            key={"intake" + index}
            id={index + "Intake"}
            multiple={index}
            name="Intake"
            disabled={intakeData.length === 1}
            label="Intake*"
            error={error.Intake}
            onChange={handleChange}
            data={intakeData}
            idField="intakeId"
            valueField="intakeDetail"
            value={course["Intake"]}
            dependentOn={
              window.webformconfigs &&
              window.webformconfigs.defaultRollingIntake > 0
                ? false
                : true
            }
          />
          {/* filterDate={isWeekday} */}
          <DateBox
            key={"StartDate" + index}
            id={index + "StartDate"}
            label="Start Date*"
            disabled={!rollingIntake}
            error={error.StartDate}
            minDate={moment().add(0, "days").toDate()}
            //onClick={() => this.setCurrentCourse(course)}
            multiple={index + 1}
            name={index + "StartDate"}
            value={course["StartDate"]}
            onChange={handleChange}
            dependentOn={
              window.webformconfigs &&
              window.webformconfigs.hideCourseSelectionDatesAndWeeks
                ? false
                : true
            }
          />
          <TextBox
            key={"TB8" + index}
            id={index + "Study Weeks"}
            label="Study Weeks"
            readOnly={true}
            error={""}
            //onClick={() => this.setCurrentCourse(course)}
            value={course["StudyWeeks"]}
            name="StudyWeeks"
            onChange={handleChange}
            dependentOn={
              window.webformconfigs &&
              window.webformconfigs.hideCourseSelectionDatesAndWeeks
                ? false
                : true
            }
          />
          {course["HolidayWeeks"] > 0 && (
            <TextBox
              key={"HolidayWeeks" + index}
              id={index + "HolidayWeeks"}
              label="Holiday Weeks"
              readOnly={true}
              error={error.HolidayWeeks}
              //onClick={() => this.setCurrentCourse(course)}
              value={course["HolidayWeeks"]}
              name="HolidayWeeks"
              onChange={handleChange}
              dependentOn={
                window.webformconfigs &&
                window.webformconfigs.hideCourseSelectionDatesAndWeeks
                  ? false
                  : true
              }
            />
          )}
          <DateBox
            key={"EndDate" + index}
            id={"EndDate" + (index + 1)}
            label="End Date"
            error={error.EndDate}
            disabled={true}
            // onClick={() => this.setCurrentCourse(course)}
            value={course["EndDate"]}
            name={index + "EndDate"}
            onChange={handleChange}
            dependentOn={
              window.webformconfigs &&
              window.webformconfigs.hideCourseSelectionDatesAndWeeks
                ? false
                : true
            }
          />
          {/* Commenting fee section for now */}
          {/* <div className="col-span-6 sm:col-span-6">
                    <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                            Fees
                            </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                            <ul className="border border-gray-200 rounded-md">
                                <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5">
                                    <div className="w-0 flex-1 flex items-center">
                                        <span className="ml-2 flex-1 w-0 truncate">
                                            Tuition Fee
                                            </span>
                                    </div>
                                    <div className="ml-4 flex-shrink-0">
                                        $400.00
                                        </div>
                                </li>

                                <li className="border-t border-gray-200 pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5">
                                    <div className="w-0 flex-1 flex items-center">
                                        <span className="ml-2 flex-1 w-0 truncate">
                                            Material Fee
                                            </span>
                                    </div>
                                    <div className="ml-4 flex-shrink-0">
                                        $1400.00
                                        </div>
                                </li>
                            </ul>
                        </dd>
                    </div>
                </div> */}
        </>
      );
    }
  };

  const { intakes } = props;
  intakes.map((i) => (i.intakeDetail = i.intakeCode + " - " + i.intakeName));

  useEffect(() => {
    if (state.courses.length === 0) {
      addCourse(0);
    }
  }, [state.courses]);
  return (
    <FormSection key={"FS" + props.label} label={props.label}>
      {Array.isArray(state.courses) && state.courses?.length === 0
        ? renderCourse({}, 0)
        : state.courses?.map((course, index) => renderCourse(course, index))}
    </FormSection>
  );
}

export default CourseSection;
