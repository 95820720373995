import React, { useState, useEffect } from 'react';
import SelectBox from '../controls/SelectBox';
import FormSection from '../controls/FormSection';
import TextBox from '../controls/TextBox';

const blankContact = {
    FName: null,
    LName: null,
    MemberShipEducation: null,
    Position: null,
    QualificationExperience: null,
    error: {}
};

const ContactSection = ({ program, onChange, dropDowns, label, contactFields }) => {
    const [hideActionButton, setHideActionButton] = useState(false);
    const [currentContact, setCurrentContact] = useState({});
    const [contacts, setContacts] = useState([]);

    const addContact = () => {
        const cContact = { ...blankContact };
        const updatedContacts = [...contacts, cContact];
        setContacts(updatedContacts);
        onChange(updatedContacts);
    };

    const removeContact = (index) => {
        const updatedContacts = contacts.filter((_, i) => i !== index);
        setContacts(updatedContacts);
        onChange(updatedContacts);
    };

    const renderActionButtons = (index) => {
        return (
            <div className="ml-4 mt-2 flex-shrink-0">
                <span className="inline-flex rounded-md shadow-sm mr-2">
                    {index === 0 ? (
                        <button
                            type="button"
                            onClick={addContact}
                            className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700"
                        >
                            +
                        </button>
                    ) : (
                        <button
                            type="button"
                            onClick={() => removeContact(index)}
                            className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700"
                        >
                            -
                        </button>
                    )}
                </span>
            </div>
        );
    };

    const handleChange = (e, name) => {
        const updatedContact = { ...currentContact };
        let controlName = ""
        if (name) {
            updatedContact[name] = e;
        } else {
            updatedContact[e.currentTarget.name] = e.currentTarget.value;
            controlName = e.currentTarget.name;
        }

        const keys = Object.keys(updatedContact);
        keys.forEach((key) => {
            // if (!updatedContact) {
            //     currentContact
            // }
            if (key !== "error") {
                const value = updatedContact[key];
                if ((value == null || value === "") && !updatedContact.error[key]) return;
                if ((value == null || value === "") && updatedContact.error[key]) {
                    updatedContact.error[key] = true;
                    return;
                }
            }
        });

        setCurrentContact(updatedContact);
        onChange(contacts);
    };

    const renderContactFields = (cfield, index, contact) => {
        const { error } = contact;

        if (cfield.fieldType === 1) {
            return (
                <TextBox
                    name={cfield.fieldName}
                    dependentOn={true}
                    error={error[cfield.fieldName]}
                    label={cfield.fieldLabel}
                    onClick={() => setCurrentContact(contact)}
                    value={contact[cfield.fieldName]}
                    onChange={handleChange}
                />
            );
        } else {
            return (
                <SelectBox
                    dependentOn={true}
                    name={cfield.fieldName}
                    error={error[cfield.fieldName]}
                    onClick={() => setCurrentContact(contact)}
                    label={`${cfield.fieldLabel}${cfield.isMandatory ? "*" : ""}`}
                    value={contact[cfield.fieldName]}
                    onChange={handleChange}
                    data={dropDowns[cfield.fieldName]}
                />
            );
        }
    };

    const renderContact = (contact, index) => {
        return (
            <>
                <div className={"col-span-6 sm:col-span-6 " + (hideActionButton ? "hidden" : "")}>
                    <div className="flex justify-between items-center flex-wrap sm:flex-no-wrap">
                        <h3 className="text-base leading-6 font-medium text-gray-900">Person {index + 1}</h3>
                        {renderActionButtons(index)}
                    </div>
                </div>
                {contactFields.map((cfield, index) => renderContactFields(cfield, index, contact))}
            </>
        );
    };

    useEffect(() => {
        if (contacts.length === 0) {
            addContact();
        }
    }, []);

    return (
        <FormSection label={label}>
            {contacts.length === 0 ? renderContact({ FName: null, LName: null }, 0) : contacts.map((contact, index) => renderContact(contact, index))}
        </FormSection>
    );
};

export default ContactSection;