import React, { useState } from "react";
import logo from "../assets/images/clientlogo.png";
import { PostData } from "../services/PostData";
import { useNavigate } from "react-router-dom";
import * as constants from "../constants";
import { Cookies } from "react-cookie";
import Alert from "../components/common/Alert";
import ErrorSound from "../assets/sounds/error.mp3";
import { ERRORICON } from "../IconConstants";

// In the above code we have done standard import and then imported
// a logo, PostDatat method from services folder, Redirect from
// react constants (contains URL or any other global constants) and
// react Cookies component

const cookies = new Cookies();

// following is the class Component with states and props
function Login(props) {
  const [state, setState] = useState({
    username: "",
    password: "",
    CollegeID: constants.COLLEGEID,

    passType: "password",
    passColor: "text-gray-400",
    alertState: false,
    alertMessage: "",
    alertIcon: "",
    iconColor: "",
    errorAudio: new Audio(ErrorSound),
    avatarName: "Agent",
  });
  const navigate = useNavigate();

  // following fuction will be called to for alert sound
  const playSound = (audioFile) => {
    audioFile.play();
  };
  // folloing function will close alert on pressing X button
  const closeAlert = () => {
    setState((pre) => ({ ...pre, alertState: false }));
  };
  // following is the login function which will be called on the press
  // of sign in button on login screen
  const login = () => {
    if (state.username && state.password) {
      // Here we are calling PostData method and sending Login request and
      // this.state data i.e. username, password etc. as parameters.On success
      // it will send studentId and token back, which will be stored in local
      // storage and cookies respectively and then set redirect true. On failure
      // it will console and error.
      const body = {
        username: state.username,
        password: state.password,
        CollegeID: constants.COLLEGEID,
      };
      PostData("AgentLogin", body).then((result) => {
        let responseJSON = result;

        if (responseJSON) {
          debugger;
          //sessionStorage.setItem("userData", responseJSON);
          localStorage.setItem("agentId", responseJSON.user.agentId);
          localStorage.setItem(
            "defaultWebForm",
            responseJSON.user.defaultAgentForm
          );
          localStorage.setItem("agentName", responseJSON.user.agentName);
          // localStorage.setItem("dateRangeLabel1", "Custom Range");
          // localStorage.setItem("dateRangeLabel2", "Custom Range");
          // localStorage.setItem("dateRangeLabel3", "Custom Range");
          // localStorage.setItem("dateRangeLabel4", "Custom Range");
          // localStorage.setItem("dateRangeLabel5", "Custom Range");
          // localStorage.setItem("dateRangeLabel6", "Custom Range");
          // localStorage.setItem("dateRangeLabel7", "Custom Range");
          // localStorage.setItem("dateRangeLabel8", "Custom Range");
          //console.log(responseJSON);
          cookies.set("token", responseJSON.token);
          let aName = responseJSON.user.agentName;
          setState((pre) => ({ ...pre, avatarName: aName }));
          navigate("/dashboard");
        } else {
          playSound(state.errorAudio);
          setState((pre) => ({
            ...pre,
            alertMessage: "Either Username or Password is incorrect",
            alertState: true,
            alertIcon: ERRORICON,
            iconColor: "red",
          }));
        }
      });
    }
  };
  //Following code will be called on onChange event
  const onChange = (e) => {
    setState((pre) => ({ ...pre, [e.target.name]: e.target.value }));
  };
  //Following code will change password data on press of eye icon on
  //password field and will toggle between plain text and password
  //type on changing.

  const changePassType = () => {
    var passType = state.passType == "password" ? "text" : "password";
    var passColor =
      state.passType == "password" ? "text-green-400" : "text-gray-400";
    setState((pre) => ({ ...pre, passType: passType, passColor: passColor }));
  };

  // <component name={this.state.appName}>
  //this.props.name in the component

  // Following Html tags are for generating Login GUI
  return (
    <div className="min-h-screen bg-white flex">
      <div className="t-20 r-20 relative">
        <Alert
          alertStatus={state.alertState}
          closeFunction={() => closeAlert()}
          alertMessage={state.alertMessage}
          alertIcon={state.alertIcon}
          iconColor={state.iconColor}
        />
      </div>
      <div className="flex-1 flex flex-col justify-center py-12 px-6 sm:px-6 lg:block lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm">
          <div>
            <img className="h-18 w-auto" src={logo} alt="Workflow" />
            <h2 className="mt-6 text-3xl leading-9 font-extrabold">
              Agent Login
            </h2>
          </div>

          <div className="mt-8">
            <div className="mt-6">
              <form action="#" method="POST" className="space-y-6">
                <div>
                  <label
                    htmlFor="username"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    User name
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      name="username"
                      id="username"
                      // following code will call onChange function
                      onChange={onChange}
                      className="block w-full rounded-md border-0 py-1.5 px-3 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"

                      // placeholder="you@example.com"
                    />
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <svg
                        className="h-5 w-5 text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884zM18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                </div>

                <div className="mt-4">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Password
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      name="password"
                      id="password"
                      // following code will call onChange function
                      onChange={onChange}
                      type={state.passType}
                      required
                      className="block w-full rounded-md border-0 py-1.5 px-3 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <div
                      // following code will call changePassType function on on click of eye icon
                      className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-all"
                      onClick={changePassType}
                    >
                      <svg
                        className={"h-5 w-5 " + state.passColor}
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M11.885 14.988l3.104-3.098.011.11c0 1.654-1.346 3-3 3l-.115-.012zm8.048-8.032l-3.274 3.268c.212.554.341 1.149.341 1.776 0 2.757-2.243 5-5 5-.631 0-1.229-.13-1.785-.344l-2.377 2.372c1.276.588 2.671.972 4.177.972 7.733 0 11.985-8.449 11.985-8.449s-1.415-2.478-4.067-4.595zm1.431-3.536l-18.619 18.58-1.382-1.422 3.455-3.447c-3.022-2.45-4.818-5.58-4.818-5.58s4.446-7.551 12.015-7.551c1.825 0 3.456.426 4.886 1.075l3.081-3.075 1.382 1.42zm-13.751 10.922l1.519-1.515c-.077-.264-.132-.538-.132-.827 0-1.654 1.346-3 3-3 .291 0 .567.055.833.134l1.518-1.515c-.704-.382-1.496-.619-2.351-.619-2.757 0-5 2.243-5 5 0 .852.235 1.641.613 2.342z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                </div>

                {/* <div className="mt-6 flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="remember_me"
                        type="checkbox"
                        className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                      />
                      <label
                        htmlFor="remember_me"
                        className="ml-2 block text-sm leading-5 text-gray-900"
                      >
                        Remember me
                      </label>
                    </div>

                    <div className="text-sm leading-5">
                      <a
                        href="#"
                        className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                      >
                        Forgot your password?
                      </a>
                    </div>
                  </div> */}

                <div className="mt-6">
                  <span className="block w-full rounded-md shadow-sm">
                    <button
                      type="button"
                      // following code will call login function
                      onClick={login}
                      className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-indigo active:bg-blue-600 transition duration-150 ease-in-out"
                    >
                      Sign in
                    </button>
                  </span>
                </div>
              </form>
            </div>            
          </div>
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
          alt=""
        />
      </div>
    </div>
  );
}

export default Login;
