// useCustomEffect.js

import { useEffect } from "react";

const useEffectWithCondition = (callback, dependencies) => {
  useEffect(() => {
    if (dependencies.every((dep) => !!dep)) {
      callback();
    }
  }, dependencies);
};

export default useEffectWithCondition;
