import React, { Component, useEffect, useState } from "react";

import TableComponent from "./common/TableComponent";
import moment from "moment";
import DateBox from "./controls/DateBox";
import Transition from "./common/Transition";
import * as constants from "../constants";
import { DateFormatter } from "./common/DateFormatter";
import { currencyFormat, multiselectFilter } from "./common/commonFns";

import { AgentsRequests } from "../apiRequests/AgentsRequests";
import SearchBox from "./controls/SearchBox";
import MultiSelection from "./controls/MultiSelection";
import Loader from "./common/Loader";

const dateFormatter = new DateFormatter();
const agentsRequests = new AgentsRequests();

function PaymentRecordView(props) {
  const [state, setState] = useState({
    hidden: "hidden",
    isLoaded: false,
    agentId: localStorage.getItem("agentId"),
    tableRows: [],
    tableEmpty: "hidden",
    pCommissionsData: [],
    allPrograms: [],
    allEnrolmentNo: [],
    allTransactionNo: [],
    transactionNoSelectedList: [],
    programsSelectedList: [],
    enrolmentNoSelectedList: [],
    firstRowIndex: 0,
    lastRowIndex: constants.NUMBEROFROWS - 1,
    filterOn: false,
    dataLength: 0,
    searchFieldValue: "",
    isOpen: false,
    expanded: "",
    background: "hidden",
    fromDate: "",
    toDate: "",
  });

  useEffect(() => {
    let startDate = localStorage.getItem("t8StartDate");
    let endDate = localStorage.getItem("t8EndDate");
    setState((prevState) => ({
      ...prevState,
      hidden: "",
      isLoaded: true,
      fromDate: startDate,
      toDate: endDate,
    }));
  }, []);

  useEffect(() => {
    if (state.fromDate && state.toDate) {
      paidCommissionsFn(state.fromDate, state.toDate);
    }
  }, [state.toDate, state.fromDate]);
  useEffect(() => {
    loadMultiSelectData();
  }, [state.pCommissionsData]);

  useEffect(() => {
    loadData();
  }, [
    state.allPrograms,
    state.allTransactionNo,
    state.allEnrolmentNo,
    state.firstRowIndex,
    state.lastRowIndex,
    state.searchFieldValue,
    state.isOpen,
    state.expanded,
    state.background,
  ]);

  const paidCommissionsFn = (startDate, endDate) => {
    agentsRequests.paymentRecord(startDate, endDate).then((result) => {
      if (result !== "error") {
        setState((prevState) => ({
          ...prevState,
          pCommissionsData: result,
        }));
      }
    });
  };

  const loadMultiSelectData = () => {
    let allPrograms = [];
    let allTransactionNo = [];
    let allEnrolmentNo = [];

    let data = state.pCommissionsData;
    if (Array.isArray(data)) {
      data?.map((object) => {
        if (allPrograms.some((item) => object.programName === item)) {
        } else {
          allPrograms.push(object.programName);
        }
      });

      data?.map((object) => {
        if (allTransactionNo.some((item) => object.paymentNo === item)) {
        } else {
          allTransactionNo.push(object.paymentNo);
        }
      });

      data?.map((object) => {
        if (allEnrolmentNo.some((item) => object.enrolmentNo === item)) {
        } else {
          allEnrolmentNo.push(object.enrolmentNo);
        }
      });
    }
    setState((prevState) => ({
      ...prevState,
      allPrograms: allPrograms,
      allTransactionNo: allTransactionNo,
      allEnrolmentNo: allEnrolmentNo,
    }));
  };

  const loadData = () => {
    let tableRows = [];
    let formatedRowData = {};
    let data = state.pCommissionsData;
    let firstRowIndex = state.firstRowIndex;
    let lastRowIndex = state.lastRowIndex;

    data = searchItems(data);

    data = dataProcessor(data);

    let dataLength = data?.length;
    if (dataLength < parseInt(firstRowIndex) + 1) {
      firstRowIndex = 0;
      lastRowIndex = constants.NUMBEROFROWS - 1;
    }

    if (data != "" && data != null && data.length > 0) {
      data?.map((object, index) => {
        if (index >= firstRowIndex && index <= lastRowIndex) {
          formatedRowData.ImgSrc =
            "https://ui-avatars.com/api/name=" +
            object.studentName +
            "/?background=random";
          formatedRowData.Label1 = object.studentName;
          formatedRowData.DescriptionClm1 =
            " Student Number : " + object.studentNo;
          formatedRowData.Label2 = object.programName;
          formatedRowData.DescriptionClm2 =
            "Enrolment Number: " + object.enrolmentNo;
          formatedRowData["Transaction Number"] = object.paymentNo;
          let transactionDate = new Date(object.transactionDate);

          formatedRowData["Transaction Date"] =
            moment(transactionDate).format("DD-MM-YYYY");
          formatedRowData.Value = "$" + currencyFormat(object.paidAmount);
          tableRows.push({ ...formatedRowData });
        }
      });
    } else {
      //following line will unhide Nodata View
      setState((prevState) => ({
        ...prevState,
        tableEmpty: "",
      }));
    }
    setState((prevState) => ({
      ...prevState,
      tableRows: tableRows,
      hidden: "hidden",
      isLoaded: false,
    }));
  };

  const dataProcessor = (data) => {
    let programsSelectedList = state.programsSelectedList;
    let transactionNoSelectedList = state.transactionNoSelectedList;
    let enrolmentNoSelectedList = state.enrolmentNoSelectedList;

    if (programsSelectedList != "") {
      data = multiselectFilter(data, programsSelectedList, "programName");
    }
    if (transactionNoSelectedList != "") {
      data = multiselectFilter(data, transactionNoSelectedList, "paymentNo");
    }
    if (enrolmentNoSelectedList != "") {
      data = multiselectFilter(data, enrolmentNoSelectedList, "enrolmentNo");
    }

    // following line of code will set dataLength
    setState((prevState) => ({
      ...prevState,
      dataLength: data.length,
    }));
    return data;
  };

  const searchItems = (data) => {
    let originalData = data;
    let searchValue = state.searchFieldValue;
    if (searchValue != "") {
      data = data.filter((object) =>
        object.studentName.toUpperCase().includes(searchValue.toUpperCase())
      );
      setState((prevState) => ({
        ...prevState,
        filterOn: true,
      }));

      if (data.length === originalData.length) {
        setState((prevState) => ({
          ...prevState,
          filterOn: false,
        }));
      }
    }
    setState((prevState) => ({
      ...prevState,
      dataLength: data.length,
    }));

    return data;
  };

  const renderTable = () => {
    let tableHeadings = [
      "Student Details",
      "Enrolment Details",
      "Transaction Number",
      "Transaction Date",
      "Paid Amount",
      "",
    ];
    let tableRows = state.tableRows;

    if (tableRows.length >= 1) {
      return (
        <TableComponent
          tableHeadings={tableHeadings}
          tableRows={tableRows}
          filterOn={state.filterOn}
          numberOfElementsPerPage={constants.NUMBEROFROWS}
          dataLength={state.dataLength}
          setPageIndexFn={setPageIndexFn}
          firstRowIndex={state.firstRowIndex}
          lastRowIndex={state.lastRowIndex}
          clmTwoWidth={"w-1/4"}
        />
      );
    } else {
      return (
        <div
          className={
            state.tableEmpty + " text-gray-600 w-full flex justify-center mt-20"
          }
        >
          <h1>
            There is No Data Available to Display, please reset filters if any
            applied.
          </h1>
        </div>
      );
    }
  };

  const setPageIndexFn = (firstRowIndex, lastRowIndex) => {
    setState((prevState) => ({
      ...prevState,
      firstRowIndex: firstRowIndex,
      lastRowIndex: lastRowIndex,
    }));
  };

  const searchHandeler = () => {
    var searchValue = document.getElementById("search").value;
    setState((prevState) => ({
      ...prevState,
      searchFieldValue: searchValue,
    }));
  };

  const filtersDropdown = () => {
    let expanded = state.expanded;
    if (expanded) {
      setState((prevState) => ({
        ...prevState,
        isOpen: false,
        expanded: "",
        background: "hidden",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        isOpen: true,
        expanded: "yes",
        background: "",
      }));

      document.getElementById("search").value = state.searchFieldValue;
    }
  };

  const handleDateBox = (e, name) => {
    localStorage.setItem("dateRangeLabel8", "Custom Range");
    let selectedDate = e;

    let fromDate = new Date(dateFormatter.formatterThree(state.fromDate));
    let toDate = new Date(dateFormatter.formatterThree(state.toDate));
    let sDate = new Date(dateFormatter.formatterThree(selectedDate));

    if (name === "FromDate") {
      let startDate = dateFormatter.formatterTwo(sDate);
      let endDate = dateFormatter.formatterTwo(toDate);
      localStorage.setItem("t8StartDate", startDate);

      setState((prevState) => ({
        ...prevState,
        fromDate: startDate,

        isOpen: false,
        expanded: "",
        background: "hidden",
      }));

      // window.location.reload();
    } else if (name === "ToDate") {
      let startDate = dateFormatter.formatterTwo(fromDate);
      let endDate = dateFormatter.formatterTwo(sDate);
      localStorage.setItem("t8EndDate", endDate);

      setState((prevState) => ({
        ...prevState,
        toDate: endDate,
        isOpen: false,
        expanded: "",
        background: "hidden",
      }));
    }
  };

  const onMultiSelectChange = (selectedList, item, id) => {
    if (id === "program") {
      setState((prevState) => ({
        ...prevState,
        programsSelectedList: selectedList,
      }));
    } else if (id === "transactionNo") {
      setState((prevState) => ({
        ...prevState,
        transactionNoSelectedList: selectedList,
      }));
    } else if (id === "enrolmentNo") {
      setState((prevState) => ({
        ...prevState,
        enrolmentNoSelectedList: selectedList,
      }));
    }
    setState((prevState) => ({
      ...prevState,
      isOpen: false,
      expanded: "",
      background: "hidden",
    }));
  };

  const renderAdvancedSearch = () => {
    return (
      <div>
        <div className="md:mt-5 mt-1 flex xl:justify-end">
          <span
            onClick={() => filtersDropdown()}
            className="  p-2 h-11 rounded-md bg-white border border-gray-300 text-gray-700 hover:bg-gray-200 cursor-pointer"
          >
            Advanced Search
          </span>
        </div>
        <Transition
          show={state.isOpen}
          enter="transition ease-in duration-300 transform"
          enterFrom="opacity-25 scale-0"
          enterTo="opacity-100 scale-100"
          leave="transition ease-out duration-500 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-25 scale-0"
        >
          <div className="origin-top right-0 absolute z-10 mb-8 w-full bg-gray-200">
            <div className="px-2 pt-4 lg:mt-0 flex-shrink-0 bg-gray-100 sm:rounded-lg border-b border-gray-200">
              <div className={"mx-4 mb-4 bg-gray-100"}>
                <div className="pb-4">
                  <div
                    className={" text-gray-600 w-full flex justify-center mb-4"}
                  >
                    <h1 className="font-semibold">Filters</h1>
                  </div>
                  <div className=" grid grid-cols-6 gap-6">
                    <div className="md:py-3 col-span-6 md:col-span-3 ">
                      <MultiSelection
                        id="program"
                        label="Select Programs"
                        selectedValues={state.programsSelectedList}
                        data={state.allPrograms}
                        onSelect={(selectedList, selectedItem) =>
                          onMultiSelectChange(
                            selectedList,
                            selectedItem,
                            "program"
                          )
                        }
                        onRemove={(selectedList, removedItem) =>
                          onMultiSelectChange(
                            selectedList,
                            removedItem,
                            "program"
                          )
                        }
                      />
                    </div>
                    <div className="md:py-3 col-span-6 md:col-span-3 ">
                      <MultiSelection
                        id="transactionNo"
                        label="Select Transaction Number"
                        selectedValues={state.transactionNoSelectedList}
                        onSelect={(selectedList, selectedItem) =>
                          onMultiSelectChange(
                            selectedList,
                            selectedItem,
                            "transactionNo"
                          )
                        }
                        onRemove={(selectedList, removedItem) =>
                          onMultiSelectChange(
                            selectedList,
                            removedItem,
                            "transactionNo"
                          )
                        }
                        data={state.allTransactionNo}
                        placeholder="Select"
                      />
                    </div>
                    <div className="md:py-3 col-span-6 md:col-span-3 ">
                      <MultiSelection
                        id="enrolmentNo"
                        label="Select Enrolment Number"
                        selectedValues={state.enrolmentNoSelectedList}
                        onSelect={(selectedList, selectedItem) =>
                          onMultiSelectChange(
                            selectedList,
                            selectedItem,
                            "enrolmentNo"
                          )
                        }
                        onRemove={(selectedList, removedItem) =>
                          onMultiSelectChange(
                            selectedList,
                            removedItem,
                            "enrolmentNo"
                          )
                        }
                        data={state.allEnrolmentNo}
                        placeholder="Select"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition>
        <div className="">
          <span
            onClick={() => filtersDropdown()}
            className={
              state.background +
              " z-6 fixed top-10 left-0 right-0 bottom-0 h-full w-full bg-gray-400 opacity-75"
            }
          />
        </div>
      </div>
    );
  };

  return (
    <div className="relative mt-6 align-middle min-h-full">
      {/* <span
          className={
           state.hidden +
            " fixed top-10 left-0 right-0 bottom-0 h-full w-full bg-white opacity-25"
          }
        /> */}
      <Loader loaded={state.isLoaded} hidden={state.hidden}></Loader>

      <div className="flex rounded-md p-4 mb-4 md:mb-8 w-full grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-6 bg-gray-100">
        <div className="xl:col-span-2">
          <SearchBox
            id={"search"}
            label={"Search For Student Name"}
            svgType={"student"}
            placeholder={"Name"}
            value={state.searchFieldValue}
            searchHandler={() => {
              searchHandeler();
            }}
          />
        </div>

        <div className="xl:col-span-1">
          <DateBox
            dependentOn={true}
            id={"Td1"}
            label="Transaction Date From"
            //minDate={moment().add(0, "days").toDate()}
            onChange={handleDateBox}
            name={"FromDate"}
            value={state.fromDate ? new Date(state.fromDate) : ""}
          />
        </div>
        <div className="xl:col-span-1">
          <DateBox
            dependentOn={true}
            id={"Td1"}
            label="Transaction Date Till"
            //minDate={moment().add(0, "days").toDate()}
            onChange={handleDateBox}
            name={"ToDate"}
            value={state.toDate ? new Date(state.toDate) : ""}
            //onChange={handleChange}
          />
        </div>
        <div className="xl:col-span-2">{renderAdvancedSearch()}</div>
      </div>

      <div>{renderTable()}</div>
    </div>
  );
}

export default PaymentRecordView;
