import React, { useEffect, useState } from "react";
import SelectBox from "../controls/SelectBox";
import FormSection from "../controls/FormSection";
const blankPreference = {
  Campus: null,
  Program: null,
  error: {},
};

const PreferencesSection = ({
  campuses,
  programs,
  program,
  onChange,
  intakes,
  label,
}) => {
  const [hideActionButton] = useState(false);
  const [preferences, setpreferences] = useState([]);
  const [currentPreference, setcurrentPreference] = useState([]);

  useEffect(() => {
    if (preferences?.length == 0) {
      addPreference();
    }
  });

  const loadDefaults = (preference) => {
    if (campuses?.length == 1) preference["Campus"] = campuses[0].campusId;
    let objects = [...programs];
    if (parseInt(preference.Campus) > 0) {
      objects = objects.filter((p) => {
        let campusIds = "," + p.campusIds + ",";
        if (
          campusIds.indexOf(",-1,") >= 0 ||
          campusIds.indexOf("," + preference.Campus + ",") >= 0
        )
          return p;
      });
    } else {
      objects = [];
    }
    if (objects?.length == 1) preference.Program = objects[0].objectId;
  };

  const addPreference = () => {
    const cPreference = { ...blankPreference };
    cPreference.error = {};
    cPreference.Program = program;
    const updatedpreferences = [...preferences, cPreference];
    loadDefaults(cPreference);
    setpreferences(updatedpreferences);
    onChange(updatedpreferences);
  };

  const removePreference = (index) => {
    let updatedpreferences = [...preferences];
    updatedpreferences.splice(index, 1);
    setpreferences(updatedpreferences, onChange(preferences));
  };

  const renderActionButtons = (index, program) => {
    return (
      <div className="ml-4 mt-2 flex-shrink-0">
        <span className="inline-flex rounded-md shadow-sm mr-2">
          {index === 0 ? (
            <button
              type="button"
              onClick={() => addPreference()}
              className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700"
            >
              +
            </button>
          ) : (
            <button
              type="button"
              onClick={() => removePreference(index)}
              className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700"
            >
              -
            </button>
          )}
        </span>
      </div>
    );
  };

  const handleChange = (e, name) => {
    var updatedcurrentPreference = currentPreference;

    let controlName = "";
    if (name) {
      updatedcurrentPreference[name] = e;
      controlName = name;
    } else {
      updatedcurrentPreference[e.currentTarget.name] = e.currentTarget.value;
      controlName = e.currentTarget.name;
    }

    if (controlName == "Campus") {
      updatedcurrentPreference.Program = "";
      updatedcurrentPreference.error = {};
    }

    var cPreference = { ...updatedcurrentPreference };
    const keys = Object.keys(cPreference);
    keys.map((key) => {
      // if (!updatedcurrentPreference) {
      //     const { currentPreference } = updatedcurrentPreference;
      // }
      if (key !== "error") {
        if (
          (updatedcurrentPreference[key] === null ||
            updatedcurrentPreference[key] === "") &&
          updatedcurrentPreference.error[key] === undefined
        )
          return;
        if (
          (updatedcurrentPreference[key] === null ||
            updatedcurrentPreference[key] === "") &&
          updatedcurrentPreference.error[key]
        ) {
          updatedcurrentPreference.error[key] = true;
          return;
        }
      }
    });
    setcurrentPreference(updatedcurrentPreference);
    onChange(currentPreference);
  };

  const renderPreference = (preference, index) => {
    const { error } = preference;
    let objects = [...programs];
    if (parseInt(preference.Campus) > 0) {
      objects = objects.filter((p) => {
        let campusIds = "," + p.campusIds + ",";
        if (
          campusIds.indexOf(",-1,") >= 0 ||
          campusIds.indexOf("," + preference.Campus + ",") >= 0
        )
          return p;
      });
    } else {
      objects = [];
    }
    objects?.map(
      (o) => (o.objectDetail = o.objectCode.trim() + " - " + o.objectName)
    );
    return (
      <>
        <div
          className={
            "col-span-6 sm:col-span-6 " + (hideActionButton ? "hidden" : "")
          }
        >
          <div className="flex justify-between items-center flex-wrap sm:flex-no-wrap">
            {
              <>
                <h3 className="text-base leading-6 font-medium text-gray-900">
                  Preference #{index + 1}
                </h3>
                {renderActionButtons(index, preference.Program)}
              </>
            }
          </div>
        </div>
        <SelectBox
          name="Campus"
          disabled={campuses?.length == 1}
          label="Campus"
          idField={"campusId"}
          valueField={"campusDetail"}
          error={error?.Campus}
          onClick={() => setcurrentPreference(preference)}
          value={preference["Campus"]}
          onChange={handleChange}
          data={campuses}
        />
        <SelectBox
          disabled={objects?.length === 1}
          error={error?.Program}
          name="Program"
          label="Program"
          onClick={() => setcurrentPreference(preference)}
          value={preference["Program"]}
          onChange={handleChange}
          data={objects}
          idField="objectId"
          valueField="objectDetail"
        />
      </>
    );
  };

  return (
    <FormSection label={label}>
      {preferences?.length === 0
        ? renderPreference({}, 0)
        : preferences?.map((preference, index) =>
            renderPreference(preference, index)
          )}
    </FormSection>
  );
};

export default PreferencesSection;
