import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { RoutesPath } from "./routesPath";

function App() {
  return <RoutesPath />;
}

export default App;
