import React, { useEffect, useState } from "react";

import * as constants from "../constants";
import ReactHtmlParser from "html-react-parser";
import Axios from "axios";

const AnswerQuiz = ({
  quizId,
  objectId,
  objectType,
  programId,
  afterUpdate,
}) => {
  const [quizData, setQuizData] = useState(null);
  const [quizResponse, setQuizResponse] = useState(null);

  useEffect(() => {
    Axios.get(constants.BASEURL + "Quiz/" + quizId)
      .then((response) => {
        const result = response.data;
        const quizResponse = {
          quizId: parseInt(quizId),
          objectType,
          objectId,
          programId,
          answers: result.questions.map(
            ({ quizResponseId, quizQuestionId }) => ({
              quizResponseId,
              quizQuestionId,
            })
          ),
        };

        setQuizData(result);
        setQuizResponse(quizResponse);
      })
      .catch((ex) => {
        console.error(ex);
      });
  }, [quizId, objectId, objectType, programId]);

  const submitQuiz = () => {
    const { questions } = quizData;
    const { answers } = quizResponse;
    let validated = true;

    questions.forEach((question) => {
      const { answerText } = answers.filter(
        (answer) => answer.quizQuestionId === question.quizQuestionId
      )[0];

      switch (question.questionType) {
        case 2:
        case 4:
        case 10:
          if (answerText && answerText !== "") {
            question.questionColor = "black";
          } else {
            question.questionColor = "red";
            validated = false;
          }
          break;
        case 11:
          if (
            question.questionOptions.filter(
              (qOption) =>
                qOption.sequence === 0 || qOption.sequence === undefined
            ).length > 0
          ) {
            question.questionColor = "red";
            validated = false;
          } else {
            question.questionColor = "black";
          }
          break;
      }
    });

    if (validated) {
      Axios.post(constants.BASEURL + "Quiz", quizResponse)
        .then((result) => {
          var message = "Success!";

          if (!result.data.success) {
            message = result.data.message;
            return;
          }

          if (afterUpdate) afterUpdate(quizId);
        })
        .catch((ex) => {
          console.error(ex);
        });
    }
  };

  const handleChange = (e, quizQuestionId, questionType, multipleAnswers) => {
    const currentAnswer = quizResponse.answers.filter(
      (answer) => answer.quizQuestionId === quizQuestionId
    )[0];
    if (currentAnswer.length > 0) {
      switch (questionType) {
        case 2:
        case 10:
          currentAnswer[0].answerText = e.currentTarget.value;
          break;
        case 4:
          const { questionOptions } = quizData?.questions.filter(
            (question) => question.quizQuestionId == quizQuestionId
          )[0];
          const tempOptions = [...questionOptions];
          var currentObj = questionOptions.filter(
            (coption) => coption.questionOptionsId == e.currentTarget.value
          )[0];
          currentObj.checked = e.currentTarget.checked;
          let answerText = e.currentTarget.value;
          if (multipleAnswers) {
            answerText = tempOptions
              .filter((opt) => opt.checked == true)
              .map((opt) => opt.questionOptionsId)
              .toString();
          }
          quizResponse.answers.filter(
            (qr) => qr.quizQuestionId == quizQuestionId
          )[0].answerText = answerText;
          break;
      }
    } else return;
    setQuizResponse(quizResponse);
    setQuizResponse({ ...quizResponse });
  };

  const handleSequence = (e, currentOptionId, options) => {
    const quizQuestionId = options[0].quizQuestionId;
    const { questionOptions } = quizData.questions.filter(
      (question) => question.quizQuestionId === quizQuestionId
    )[0];

    const currentObj = questionOptions.filter(
      (coption) => coption.questionOptionsId === currentOptionId
    );

    if (currentObj.length > 0 && currentObj[0].sequence > 0) {
      questionOptions
        .filter((qOption) => qOption.sequence > currentObj[0].sequence)
        .forEach((opt) => opt.sequence--);
      currentObj[0].sequence = 0;
    } else {
      let max = questionOptions.reduce((prev, current) => {
        prev.sequence = prev.sequence || 0;
        current.sequence = current.sequence || 0;
        return prev.sequence > current.sequence ? prev : current;
      });

      currentObj[0].sequence = max.sequence ? max.sequence + 1 : 1;
    }

    const tempOptions = [...questionOptions];
    const answerText = tempOptions
      .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
      .map((opt) => opt.questionOptionsId)
      .toString();

    quizResponse.answers.filter(
      (qr) => qr.quizQuestionId === quizQuestionId
    )[0].answerText = answerText;

    setQuizData({ ...quizResponse });
  };

  const renderQuestion = (
    {
      quizDetailId,
      quizQuestionId,
      questionType,
      questiontext,
      questionOptions,
      multipleAnswers,
      questionColor,
    },
    index
  ) => {
    const currentAnswer = quizResponse.answers.filter(
      (ans) => ans.quizQuestionId === quizQuestionId
    )[0];

    switch (questionType) {
      case 1:
        return (
          <div>
            <p className="mt-1 text-sm leading-5 text-gray-500">
              {ReactHtmlParser(questiontext)}
            </p>
          </div>
        );
      case 2:
        return (
          <>
            <div className="flex mb-4 mt-6">
              <div
                className={`w-5 text-base font-bold text-${questionColor}-600`}
              >
                {++index + ". "}
              </div>
              <div
                className={`w-auto text-base font-bold text-${questionColor}-600`}
              >
                {ReactHtmlParser(questiontext)}
              </div>
            </div>
            <div className="flex mb-4">
              <div className="w-full mt-1 rounded-md shadow-sm">
                <textarea
                  onChange={(event) =>
                    handleChange(event, quizQuestionId, questionType)
                  }
                  id={quizQuestionId}
                  rows="3"
                  className={`form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 border border-${questionColor}-500`}
                >
                  {currentAnswer.answerText}
                </textarea>
              </div>
            </div>
          </>
        );
      case 4:
        return (
          <>
            <div className="flex mb-4 mt-6">
              <div
                className={`w-5 text-base font-bold text-${questionColor}-600`}
              >
                {++index + ". "}
              </div>
              <div
                className={`w-auto text-base font-bold text-${questionColor}-600`}
              >
                {ReactHtmlParser(questiontext)}
              </div>
            </div>
            <div className="sm:col-span-6 mt-6">
              {questionOptions.map(
                ({ questionOptionsId, quizQuestionId, answerText, grade }) => (
                  <div className="mt-4">
                    <div className="relative flex items-start">
                      <div className="flex items-center h-5">
                        {multipleAnswers ? (
                          <input
                            id={quizQuestionId}
                            type="checkbox"
                            onChange={(event) =>
                              handleChange(
                                event,
                                quizQuestionId,
                                questionType,
                                multipleAnswers
                              )
                            }
                            name={quizQuestionId}
                            value={questionOptionsId}
                            className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                          />
                        ) : (
                          <input
                            id={quizQuestionId}
                            name={quizQuestionId}
                            type="radio"
                            onChange={(event) =>
                              handleChange(
                                event,
                                quizQuestionId,
                                questionType,
                                multipleAnswers
                              )
                            }
                            value={questionOptionsId}
                            className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                          />
                        )}
                      </div>
                      <div className="ml-3 text-sm leading-5">
                        <label
                          className={`font-medium text-${questionColor}-700`}
                        >
                          {answerText}
                        </label>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </>
        );
      case 10:
        return (
          <>
            <div className="flex mb-4 mt-6">
              <div
                className={`w-5 text-base font-bold text-${questionColor}-600`}
              >
                {++index + ". "}
              </div>
              <div
                className={`w-auto text-base font-bold text-${questionColor}-600`}
              >
                {ReactHtmlParser(questiontext)}
              </div>
            </div>
            <div className="sm:col-span-6">
              <div className="mt-1 rounded-md shadow-sm sm:col-span-2">
                <input
                  id={quizQuestionId}
                  name={quizQuestionId}
                  onChange={(event) =>
                    handleChange(event, quizQuestionId, questionType)
                  }
                  value={currentAnswer.answerText}
                  className={`form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 border border-${questionColor}-500`}
                />
              </div>
            </div>
          </>
        );
      case 11:
        return (
          <>
            <div className="flex mb-4 mt-6">
              <div
                className={`w-5 text-base font-bold text-${questionColor}-600`}
              >
                {++index + ". "}
              </div>
              <div
                className={`w-auto text-base font-bold text-${questionColor}-600`}
              >
                {ReactHtmlParser(questiontext)}
              </div>
            </div>
            <div className="sm:col-span-6 mt-6">
              <fieldset>
                {questionOptions.map(
                  ({
                    questionOptionsId,
                    quizQuestionId,
                    answerText,
                    grade,
                    sequence,
                  }) => (
                    <div className="mt-4">
                      <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:pt-5">
                        <div className="ml-3 text-sm leading-5">
                          <label
                            className={`font-medium text-${questionColor}-700`}
                          >
                            {answerText}
                          </label>
                        </div>
                        <div className="ml-3 text-sm leading-5">
                          <span
                            id={quizQuestionId}
                            onClick={(event) =>
                              handleSequence(
                                event,
                                questionOptionsId,
                                questionOptions
                              )
                            }
                            className="rounded-full h-10 w-10 flex items-center justify-center text-lg font-bold bg-blue-100 text-blue-800 cursor-pointer"
                          >
                            {sequence || "0"}
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </fieldset>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const renderQuiz = () => {
    if (!quizData.questions) return null;

    return quizData?.questions.map((question, index) => (
      <div key={quizData?.question.quizQuestionId}>
        {renderQuestion(question, index)}
      </div>
    ));
  };

  return (
    <>
      {quizData && (
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="md:flex md:items-center md:justify-between">
            <div className="flex-1 min-w-0">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
                {ReactHtmlParser(quizData.quizIntro)}
              </h2>
            </div>
            <div className="mt-4 flex md:mt-0 md:ml-4">
              <span className="shadow-sm rounded-md"></span>
              <span className="ml-3 shadow-sm rounded-md"></span>
            </div>
          </div>
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="mb-4">
              <form name="webform">
                {renderQuiz()}
                <div className="text-right sm:col-span-2 mt-6">
                  <span className="inline-flex rounded-md shadow-sm">
                    <button
                      type="button"
                      onClick={submitQuiz}
                      className="inline-flex justify-center py-3 px-6 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                    >
                      Submit
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AnswerQuiz;
